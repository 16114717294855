import { AghanimTypesWebsiteConfig, WebsiteTemplateRead } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { FieldGroup, ImageUploader, ToastSeverity, useToast } from '@/ui'
import { Button, InputColor, MOTION_ANIMATION_SPEED_MD, Select, useModal } from '@dashboard/ui'
import { EditWebsiteTemplateModal } from '@/hub-editor/dialogs/EditWebsiteTemplateModal'
import { useWebsiteTemplates } from '@/hub-editor/api/useWebsiteTemplates'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { dashboardClient } from '@/api'
import { useForm } from 'react-hook-form'
import { CollapseView } from '@/hub-editor/widgets/CollapseView'

const colorFields = [
  {
    i18: 'dark_bg_color',
    property: 'dark_bg_color',
    type: 'general',
  },
  {
    i18: 'website.accent_color',
    property: 'accent_color',
    type: 'general',
  },
  {
    i18: 'website.text_color',
    property: 'text_color',
    type: 'general',
  },
  {
    i18: 'website.base_bg_color',
    property: 'base_bg_color',
    type: 'general',
  },
  {
    i18: 'featured_bg_color',
    property: 'featured_bg_color',
    type: 'general',
  },
  {
    i18: 'card_bg_color',
    property: 'card_bg_color',
    type: 'card',
  },
  {
    i18: 'card_image_bg_color',
    property: 'card_image_bg_color',
    type: 'card',
  },
  {
    i18: 'ui_elements_color',
    property: 'ui_elements_color',
    type: 'general',
  },
  {
    i18: 'text_muted',
    property: 'text_muted',
    type: 'general',
  },
  {
    i18: 'left_menu_bg_color',
    property: 'left_menu_bg_color',
    type: 'general',
  },
  {
    i18: 'right_menu_bg_color',
    property: 'right_menu_bg_color',
    type: 'general',
  },
  {
    i18: 'offer_label_bg',
    property: 'offer_label_bg_color',
    type: 'card',
  },
  {
    i18: 'offer_label_color',
    property: 'offer_label_color',
    type: 'card',
  },
  {
    i18: 'offer_price_label_bg',
    property: 'offer_price_label_bg',
    type: 'card',
  },
  {
    i18: 'offer_price_label_color',
    property: 'offer_price_label_color',
    type: 'card',
  },
  {
    i18: 'discount_label_bg_color',
    property: 'discount_label_bg_color',
    type: 'card',
  },
  {
    i18: 'discount_label_text_color',
    property: 'discount_label_text_color',
    type: 'card',
  },
  {
    i18: 'bonus_label_bg_color',
    property: 'bonus_label_bg_color',
    type: 'card',
  },
  {
    i18: 'bonus_label_text_color',
    property: 'bonus_label_text_color',
    type: 'card',
  },
  {
    i18: 'buy_button_bg_color',
    property: 'buy_button_bg_color',
    type: 'card',
  },
  {
    i18: 'buy_button_color',
    property: 'buy_button_color',
    type: 'card',
  },
  {
    i18: 'reward_button_bg',
    property: 'reward_button_bg',
    type: 'reward',
  },
  {
    i18: 'reward_button_color',
    property: 'reward_button_color',
    type: 'reward',
  },
  {
    i18: 'daily_reward_button_bg',
    property: 'daily_reward_button_bg',
    type: 'reward',
  },
  {
    i18: 'daily_reward_button_color',
    property: 'daily_reward_button_color',
    type: 'reward',
  },
  {
    i18: 'daily_reward_inactive_button_bg',
    property: 'daily_reward_inactive_button_bg',
    type: 'reward',
  },
  {
    i18: 'daily_reward_inactive_button_color',
    property: 'daily_reward_inactive_button_color',
    type: 'reward',
  },
  {
    i18: 'daily_reward_card_bg_color',
    property: 'daily_reward_card_bg_color',
    type: 'reward',
  },
  {
    i18: 'free_item_button_bg',
    property: 'free_item_button_bg',
    type: 'reward',
  },
  {
    i18: 'free_item_button_color',
    property: 'free_item_button_color',
    type: 'reward',
  },
  {
    i18: 'outline_button_color',
    property: 'outline_button_color',
    type: 'general',
  },
  {
    i18: 'custom_badge_bg_color',
    property: 'custom_badge_bg_color',
    type: 'text',
  },
  {
    i18: 'custom_badge_color',
    property: 'custom_badge_color',
    type: 'card',
  },
  {
    i18: 'free_item_custom_badge_bg_color',
    property: 'free_item_custom_badge_bg_color',
    type: 'card',
  },
  {
    i18: 'free_item_custom_badge_color',
    property: 'free_item_custom_badge_color',
    type: 'card',
  },
  {
    i18: 'item_timer_bg_color',
    property: 'item_timer_bg_color',
    type: 'card',
  },
  {
    i18: 'item_timer_color',
    property: 'item_timer_color',
    type: 'card',
  },
  {
    i18: 'item_name_color',
    property: 'item_name_color',
    type: 'card',
  },
  {
    i18: 'bundle_list_bg_color',
    property: 'bundle_list_bg_color',
    type: 'card',
  },
  {
    i18: 'page_title_color',
    property: 'page_title_color',
    type: 'news',
  },
  {
    i18: 'news_active_tab_button_bg_color',
    property: 'news_active_tab_button_bg_color',
    type: 'news',
  },
  {
    i18: 'tab_button_bg',
    property: 'tab_button_bg',
    type: 'news',
  },
  {
    i18: 'tab_button_color',
    property: 'tab_button_color',
    type: 'news',
  },
]

export const ColorSchemeEditor = ({
  onChange,
  config,
}: {
  onChange: (value: AghanimTypesWebsiteConfig) => void
  config: AghanimTypesWebsiteConfig
}) => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const { data: templates = [], refetch } = useWebsiteTemplates(companyId, gameId)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null)
  const showToast = useToast()

  const { setValue, reset, handleSubmit, watch } = useForm<WebsiteTemplateRead>()

  const openEditModal = useModal<{ item: WebsiteTemplateRead }>(props => (
    <EditWebsiteTemplateModal
      {...props}
      template={props.item}
      onUpdated={async template => {
        await refetch()
        setSelectedTemplateId(template.id)
      }}
      onRemoved={() => {
        refetch()
        setSelectedTemplateId(null)
        reset({})
        onChange({})
      }}
    />
  ))

  const onSaveClick = async (data: WebsiteTemplateRead) => {
    await dashboardClient.v1.updateWebsiteTemplate(companyId, gameId, data.id, {
      config: data.config,
    })
    showToast({ message: t('saved'), severity: ToastSeverity.success })
    refetch()
  }

  const onColorChanged = (key: string, value: string) => {
    // @ts-ignore
    setValue(`config.${key}`, value)
    onChange({
      ...config,
      [key]: value,
    })
  }

  useEffect(() => {
    const currentTemplate = templates.find(it => it.id === selectedTemplateId)
    if (!currentTemplate) {
      return
    }

    reset(currentTemplate)

    onChange({
      ...currentTemplate.config,
    })
  }, [selectedTemplateId])

  const renderColorFields = (fields: { i18: string; property: string }[]) => {
    const config = watch('config') as Record<string, string>
    if (!config || !selectedTemplateId) {
      return
    }
    return fields.map(it => (
      <FieldGroup label={t(it.i18)} key={it.property}>
        <InputColor
          placeholder={t('website-template.override-color')}
          name={it.property}
          value={config[it.property] || ''}
          onChange={e => {
            onColorChanged(it.property, e)
          }}
        />
      </FieldGroup>
    ))
  }

  const generalColors = colorFields.filter(it => it.type === 'general')
  const cardColors = colorFields.filter(it => it.type === 'card')
  const rewardColors = colorFields.filter(it => it.type === 'reward')
  const newsColors = colorFields.filter(it => it.type === 'news')

  return (
    <div className="grow">
      <div className="border-b border-border-secondary p-2 text-caption-xs font-medium uppercase text-text-tertiary">
        {t('website-template.store-color')}
      </div>

      <div className="flex flex-col gap-1.5 p-2">
        <FieldGroup label={t('website-template.color-theme')} style={{ marginBottom: 0 }}>
          <Select
            items={templates.map(item => ({
              children: item.name,
              value: item.id,
            }))}
            placeholder={t('website-template.choose-theme')}
            value={selectedTemplateId}
            onChange={v => setSelectedTemplateId(v as string)}
            renderMenuFooter={state => (
              <Button
                className="w-full"
                variant="outline"
                size="sm"
                onClick={() => {
                  state.setIsOpen(false)
                  setTimeout(() => {
                    openEditModal({
                      item: {
                        config: {},
                      } as WebsiteTemplateRead,
                    })
                  }, MOTION_ANIMATION_SPEED_MD * 500)
                }}
              >
                {t('website-template.add')}
              </Button>
            )}
          />
        </FieldGroup>

        {selectedTemplateId && (
          <>
            <Button
              variant="outline"
              color="secondary"
              className="w-full text-text-brand-primary"
              onClick={() => openEditModal({ item: templates.find(it => it.id == selectedTemplateId)! })}
            >
              {t('website-template.rules')}
            </Button>
            <Button onClick={handleSubmit(onSaveClick)} className="w-full" variant="outline">
              {t('apply')}
            </Button>
          </>
        )}
      </div>
      {selectedTemplateId && (
        <div className="px-2">
          <CollapseView label={t('website-template.colors.general')}>{renderColorFields(generalColors)}</CollapseView>

          <CollapseView label={t('website-template.colors.card')}>{renderColorFields(cardColors)}</CollapseView>

          <CollapseView label={t('website-template.colors.reward')}>{renderColorFields(rewardColors)}</CollapseView>
          <CollapseView label={t('website-template.colors.news')}>{renderColorFields(newsColors)}</CollapseView>

          <CollapseView label={t('hub-settings.base_bg_image')}>
            <ImageUploader
              showName={false}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              value={config.base_bg_image}
              onChange={value => {
                onColorChanged('base_bg_image', value || '')
              }}
            />
          </CollapseView>
        </div>
      )}
    </div>
  )
}

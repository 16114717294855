import { ButtonHTMLAttributes, MouseEvent, ReactNode, forwardRef, useCallback, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { cva } from 'class-variance-authority';
import { MOTION_ANIMATION_SPEED_SM, cn, getMotionProps } from '../../libs';
import { Spinner } from '../Spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Content of the button.
   */
  children: ReactNode;
  /**
   * Color of the button.
   */
  color?: 'primary' | 'secondary' | 'danger';
  /**
   * Variant of the button.
   */
  variant?: 'default' | 'outline' | 'tertiary' | 'link';
  /**
   * Size of the button.
   */
  size?: 'xs' | 'sm' | 'md';
  /**
   * Loading state of the button.
   */
  loading?: boolean;
  /**
   * Disables the button.
   */
  disabled?: boolean;
}

const variants = cva(
  [
    'relative inline-flex items-center justify-center text-nowrap border text-center shadow-xs transition-colors',
    'focus-visible:ring-2 focus-visible:ring-brand focus-visible:ring-offset-1',
    'disabled:pointer-events-none disabled:shadow-none',
  ],
  {
    variants: {
      color: {
        primary: [],
        secondary: [],
        danger: [],
      },
      variant: {
        default: ['bg-[--bg]', 'border-[--border]', 'text-[--text]'],
        outline: ['bg-[--bg]', 'border-[--border]', 'text-[--text]'],
        tertiary: ['bg-[--bg]', 'border-[--border]', 'text-[--text]', 'shadow-none'],
        link: ['bg-transparent', 'border-none', 'text-[--text]', 'shadow-none', '!p-0', '!h-auto'],
      },
      size: {
        xs: ['h-6 min-w-6 rounded-md px-2 text-caption-sm leading-4'],
        sm: ['h-7 min-w-7 rounded-md px-3 text-caption-md leading-4'],
        md: ['h-8 min-w-8 rounded-md px-4 text-caption-md leading-4'],
      },
      defaultVariants: {
        variant: 'default',
        color: 'primary',
        size: 'md',
      },
    },
    compoundVariants: [
      /**
       * Primary
       */
      {
        color: 'primary',
        variant: 'default',
        class: [
          '[--bg:theme(colors.fg-brand-primary)]',
          '[--border:theme(colors.fg-brand-primary)]',
          '[--text:theme(colors.text-inverse)]',
          'hover:[--bg:theme(colors.fg-brand-primary-hover)]',
          'hover:[--border:theme(colors.fg-brand-primary-hover)]',
          'active:[--bg:theme(colors.fg-brand-primary-pressed)]',
          'active:[--border:theme(colors.fg-brand-primary-pressed)]',
          'data-[open=true]:[--bg:theme(colors.fg-brand-primary-pressed)]',
          'data-[open=true]:[--border:theme(colors.fg-brand-primary-pressed)]',
          'disabled:[--bg:theme(colors.fg-disabled)]',
          'disabled:[--border:theme(colors.fg-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-brand-primary-pressed)]',
          'data-[loading=true]:[--border:theme(colors.fg-brand-primary-pressed)]',
        ],
      },
      {
        color: 'primary',
        variant: 'outline',
        class: [
          '[--bg:theme(colors.fg-primary)]',
          '[--border:theme(colors.fg-brand-primary)]',
          '[--text:theme(colors.text-primary)]',
          'hover:[--bg:theme(colors.fg-brand-primary-hover)]',
          'hover:[--border:theme(colors.fg-brand-primary-hover)]',
          'hover:[--text:theme(colors.text-inverse)]',
          'active:[--bg:theme(colors.fg-brand-primary-pressed)]',
          'active:[--border:theme(colors.fg-brand-primary-pressed)]',
          'active:[--text:theme(colors.text-inverse)]',
          'data-[open=true]:[--bg:theme(colors.fg-brand-primary-pressed)]',
          'data-[open=true]:[--border:theme(colors.fg-brand-primary-pressed)]',
          'data-[open=true]:[--text:theme(colors.text-inverse)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.border-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--text:theme(colors.text-brand-primary)]',
        ],
      },
      {
        color: 'primary',
        variant: 'tertiary',
        class: [
          '[--bg:transparent]',
          '[--border:transparent]',
          '[--text:theme(colors.text-brand-primary)]',
          'hover:[--bg:theme(colors.fg-brand-tertiary)]',
          'hover:[--border:theme(colors.fg-brand-tertiary)]',
          'active:[--bg:theme(colors.fg-brand-tertiary-hover)]',
          'active:[--border:theme(colors.fg-brand-tertiary-hover)]',
          'data-[open=true]:[--bg:theme(colors.fg-brand-tertiary-hover)]',
          'data-[open=true]:[--border:theme(colors.fg-brand-tertiary-hover)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.fg-primary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-brand-tertiary-pressed)]',
          'data-[loading=true]:[--border:theme(colors.fg-brand-tertiary-pressed)]',
          'data-[loading=true]:[--text:theme(colors.text-inverse)]',
        ],
      },
      {
        color: 'primary',
        variant: 'link',
        class: [
          '[--text:theme(colors.text-brand-primary)]',
          'hover:[--text:theme(colors.text-brand-secondary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--text:theme(colors.text-brand-primary)]',
        ],
      },
      /**
       * Secondary
       */
      {
        color: 'secondary',
        variant: 'default',
        class: [
          'shadow-none',
          '[--bg:theme(colors.fg-secondary-alt)]',
          '[--border:theme(colors.fg-secondary-alt)]',
          '[--text:theme(colors.text-primary)]',
          'hover:[--bg:theme(colors.fg-gray-secondary)]',
          'hover:[--border:theme(colors.fg-gray-secondary)]',
          'active:[--bg:theme(colors.fg-secondary-alt)]',
          'active:[--border:theme(colors.fg-secondary-alt)]',
          'data-[open=true]:[--bg:theme(colors.fg-secondary-alt)]',
          'data-[open=true]:[--border:theme(colors.fg-secondary-alt)]',
          'disabled:[--bg:theme(colors.fg-disabled)]',
          'disabled:[--border:theme(colors.fg-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--text:theme(colors.text-disabled)]',
        ],
      },
      {
        color: 'secondary',
        variant: 'outline',
        class: [
          '[--bg:theme(colors.fg-primary)]',
          '[--border:theme(colors.border-primary)]',
          '[--text:theme(colors.text-primary)]',
          'hover:[--bg:theme(colors.fg-primary-hover)]',
          'active:[--bg:theme(colors.fg-primary-alt)]',
          'data-[open=true]:[--bg:theme(colors.fg-primary-alt)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.border-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-primary-alt)]',
          'data-[loading=true]:[--border:theme(colors.border-primary)]',
        ],
      },
      {
        color: 'secondary',
        variant: 'tertiary',
        class: [
          '[--bg:transparent]',
          '[--border:transparent]',
          '[--text:theme(colors.text-primary)]',
          'hover:[--bg:theme(colors.fg-primary)]',
          'hover:[--border:theme(colors.border-primary)]',
          'active:[--bg:theme(colors.fg-secondary-hover)]',
          'active:[--border:theme(colors.border-primary)]',
          'data-[open=true]:[--bg:theme(colors.fg-secondary-hover)]',
          'data-[open=true]:[--border:theme(colors.border-primary)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.fg-primary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-secondary-hover)]',
          'data-[loading=true]:[--border:theme(colors.fg-secondary-hover)]',
          'data-[loading=true]:[--text:theme(colors.text-disabled)]',
        ],
      },
      {
        color: 'secondary',
        variant: 'link',
        class: [
          '[--text:theme(colors.text-primary)]',
          'hover:[--text:theme(colors.text-secondary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--text:theme(colors.text-disabled)]',
        ],
      },
      /**
       * Danger
       */
      {
        color: 'danger',
        variant: 'default',
        class: [
          'shadow-none',
          '[--bg:theme(colors.fg-error-solid)]',
          '[--border:theme(colors.fg-error-solid)]',
          '[--text:theme(colors.text-inverse)]',
          'hover:[--bg:theme(colors.fg-error-primary-hover)]',
          'hover:[--border:theme(colors.fg-error-primary-hover)]',
          'active:[--bg:theme(colors.fg-error-solid)]',
          'active:[--border:theme(colors.fg-error-solid)]',
          'data-[open=true]:[--bg:theme(colors.fg-error-solid)]',
          'data-[open=true]:[--border:theme(colors.fg-error-solid)]',
          'disabled:[--bg:theme(colors.fg-disabled)]',
          'disabled:[--border:theme(colors.fg-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
        ],
      },
      {
        color: 'danger',
        variant: 'outline',
        class: [
          '[--bg:theme(colors.fg-primary)]',
          '[--border:theme(colors.border-error-solid)]',
          '[--text:theme(colors.text-primary)]',
          'hover:[--bg:theme(colors.fg-error-solid)]',
          'hover:[--text:theme(colors.text-inverse)]',
          'active:[--bg:theme(colors.fg-error-solid)]',
          'active:[--text:theme(colors.text-inverse)]',
          'data-[open=true]:[--bg:theme(colors.fg-error-solid)]',
          'data-[open=true]:[--text:theme(colors.text-inverse)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.border-disabled)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-error-solid)]',
          'data-[loading=true]:[--text:theme(colors.text-inverse)]',
        ],
      },
      {
        color: 'danger',
        variant: 'tertiary',
        class: [
          '[--bg:transparent]',
          '[--border:transparent]',
          '[--text:theme(colors.text-error-primary)]',
          'hover:[--bg:theme(colors.fg-primary)]',
          'hover:[--border:theme(colors.border-error)]',
          'hover:[--text:theme(colors.text-error-secondary)]',
          'active:[--bg:theme(colors.fg-error-tertiary-hover)]',
          'active:[--border:theme(colors.border-error)]',
          'active:[--text:theme(colors.text-error-secondary)]',
          'data-[open=true]:[--bg:theme(colors.fg-error-tertiary-hover)]',
          'data-[open=true]:[--border:theme(colors.border-error)]',
          'data-[open=true]:[--text:theme(colors.text-error-secondary)]',
          'disabled:[--bg:theme(colors.fg-primary)]',
          'disabled:[--border:theme(colors.fg-primary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--bg:theme(colors.fg-error-tertiary-hover)]',
          'data-[loading=true]:[--border:theme(colors.fg-error-tertiary-hover)]',
          'data-[loading=true]:[--text:theme(colors.text-error-primary)]',
        ],
      },
      {
        color: 'danger',
        variant: 'link',
        class: [
          '[--text:theme(colors.text-error-primary)]',
          'hover:[--text:theme(colors.text-error-secondary)]',
          'disabled:[--text:theme(colors.text-disabled)]',
          'data-[loading=true]:[--text:theme(colors.text-error-primary)]',
        ],
      },
    ],
  },
);

const spinnerMotionProps = getMotionProps(
  {
    initial: { y: -4, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.05 } },
    exit: { y: -4, opacity: 0 },
  },
  { duration: MOTION_ANIMATION_SPEED_SM },
);

/**
 * ## Icons
 *
 * Pass the icon to children, but the text must be wrapped in `span`:
 *
 * ```tsx
 * <Button>
 *   <AiTranslateOutline size={18} />
 *   <span>Button</span>
 * </Button>
 * ```
 *
 * Icon sizes based on the size of the button:
 * - `md` = `18px`
 * - `sm` = `16px`
 * - `xs` = `14px`
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, color = 'primary', variant = 'default', size = 'sm', type = 'button', loading, onClick, ...rest },
    ref,
  ) => {
    const [computedLoading, setComputedLoading] = useState(loading);

    useEffect(() => {
      setComputedLoading(loading);
    }, [loading]);

    const onPromisedClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        if (!onClick) return;
        if (onClick.constructor.name === 'AsyncFunction') {
          const promisedOnClick = onClick as (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
          setComputedLoading(true);
          void promisedOnClick(e).finally(() => setComputedLoading(false));
        } else {
          onClick(e);
        }
      },
      [onClick],
    );

    return (
      <button
        {...rest}
        ref={ref}
        className={cn(variants({ variant, color, size }), computedLoading && 'pointer-events-none', rest.className)}
        type={type}
        data-loading={computedLoading}
        onClick={onPromisedClick}
      >
        <AnimatePresence>
          {computedLoading && (
            <motion.div
              {...spinnerMotionProps}
              className="absolute left-0 top-0 flex size-full items-center justify-center"
            >
              <Spinner size={16} />
            </motion.div>
          )}
        </AnimatePresence>
        <div
          className={cn(
            'flex items-center gap-1 transition-[opacity,transform]',
            computedLoading && 'translate-y-[4px] opacity-0',
          )}
        >
          {children}
        </div>
      </button>
    );
  },
);

import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import CenteredProgress from '../../components/CenteredProgress'
import PageBlock from '../game-items/components/PageBlock'
import UserTransactionsTable from '../transaction/UserTransactionsTable'
import TableProperty, { PropColumn } from './components/TableProperty'
import { AttrType, CustomAttribute, PersistentPlayerAttributes } from '@/api/dashboard'
import PlayerCard from './PlayerCard'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../Settings'
import { formatMoney } from '../../util'
import UserCouponsTable from './UserCouponsTable'
import { useFullPlayerDetailQuery, usePlayerDetailQuery } from './api'
import EmptyTableMessage from '../../components/EmptyTableMessage'
import AnimatedDots from '../../components/animated-dots/AnimatedDots'
import { BackButton, Badge, Input, PageHeader, Tab, Tabs, ToastSeverity, useToast } from '@/ui'
import { useContext, useEffect, useState } from 'react'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { dashboardClient, getErrorText } from '@/api'
import { EMPTY_VALUE, TokenValue } from '@/layouts/campaigns/expr/types'
import { KeyValue } from '@/types'
import { AuthContext } from '@/Context'
import { canEdit } from '@/security'
import { Edit03, Save } from '@/icons'
import UserClaimedItemsTable from '@/layouts/player/UserClaimedItemsTable'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import CampaignVirtualSKUTable from '@/layouts/player/widgets/UserVirtualSKUTable'
import UserBannerTable from '@/layouts/player/widgets/UserBannerTable'
import { UserWebhookLog } from '@/layouts/player/widgets/UserWebhookLog'
import { InputDate, Select } from '@dashboard/ui'
import UserStoreSettingsTable from '@/layouts/player/widgets/UserStoreSettingsTable'
import { useTranslation } from 'react-i18next'
import { GAME_PLAYERS_PATH, useNavigateBack } from '@/libs'
import { InputList } from '@/layouts/components/InputList'

enum TabAttrType {
  embedded,
  custom,
  calculated,
}

enum LiveopsTabs {
  offers,
  virtualsku,
  userbanners,
  claimed,
  storesettings,
}

export default function PlayerDetails() {
  const theme = useTheme()
  const { companyId, gameId, userId } = useParams() as {
    companyId: string
    gameId: string
    userId: string
  }
  const back = useNavigateBack({ fallback: GAME_PLAYERS_PATH })
  const { t } = useTranslation()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const context = useContext(AuthContext)
  const canUserEdit = canEdit(context?.customer)
  const showToast = useToast()

  const { data: user, isLoading, isError } = usePlayerDetailQuery(companyId, gameId, userId)
  const { data: fullUser, isLoading: isFullLoading } = useFullPlayerDetailQuery(companyId, gameId, userId)
  const [attrType, setAttrType] = useState<TabAttrType>(TabAttrType.embedded)
  const [editMode, setEditMode] = useState(false)
  const [customAttr, setCustomAttr] = useState<Record<string, TokenValue>>({})

  const [liveopsContent, setLiveopsContent] = useState<LiveopsTabs>(LiveopsTabs.virtualsku)

  useEffect(() => {
    const cattr = (fullUser?.custom_attributes || {}) as Record<string, string | number | undefined | boolean>
    setCustomAttr(cattr)
  }, [fullUser])

  const renderPrevPurchases = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock title={t('players.other-transactions')} className="w-full overflow-x-auto">
        <UserTransactionsTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderCoupons = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock className="ph-no-capture" title={t('players.coupons')}>
        <UserCouponsTable userId={user.id} />
      </PageBlock>
    )
  }

  const renderEditor = (type: AttrType, value: TokenValue, onChanged: (value: TokenValue) => void) => {
    switch (type) {
      case AttrType.Boolean:
        return (
          <Select
            value={value == undefined ? EMPTY_VALUE : value == true ? 'True' : 'False'}
            onChange={v => onChanged(v == EMPTY_VALUE ? undefined : v == 'True')}
            items={[
              { children: 'None', value: EMPTY_VALUE },
              { children: 'True', value: 'True' },
              { children: 'False', value: 'False' },
            ]}
          />
        )
      case AttrType.Date:
        return (
          <InputDate
            value={value ? new Date((value as number) * 1000) : null}
            onChange={value => {
              onChanged(value ? value.getTime() / 1000 : (null as unknown as undefined))
            }}
          />
        )
      case AttrType.List:
        return <InputList value={value as string[]} onChange={v => onChanged(v)} />
      case AttrType.String:
      case AttrType.Number:
      default:
        return (
          <Input
            size="sm"
            type={type == AttrType.Number ? 'number' : 'text'}
            value={value as string}
            onChange={e => {
              if (type == AttrType.Number) {
                if (e.target.value) {
                  let v = parseInt(e.target.value)
                  if (e.target.value && isNaN(v)) {
                    return false
                  }
                  onChanged(v)
                } else {
                  onChanged(undefined)
                }
              } else {
                onChanged(e.target.value)
              }
            }}
          />
        )
    }
  }

  const renderValue = (attr: CustomAttribute) => {
    if (!fullUser) {
      return <AnimatedDots />
    }

    let attrValue = (customAttr as KeyValue)[attr.name] as TokenValue
    if (!attr) {
      return 'Attribute not found'
    }

    if (!editMode) {
      if (attr.type == AttrType.Date && attrValue) {
        return dayjs((attrValue as number) * 1000).format(DATE_FORMAT)
      }

      if (attr.type == AttrType.Boolean) {
        return attrValue == undefined ? '' : attrValue ? 'True' : 'False'
      }

      if (attr.type == AttrType.List && attrValue) {
        return (attrValue as string[]).join(', ')
      }

      if (attrValue == undefined) {
        return ''
      }

      return attrValue
    }

    return renderEditor(attr.type, attrValue, value => {
      setCustomAttr({
        ...customAttr,
        [attr.name]: value,
      } as Record<string, string>)
    })
  }

  const onDiscardCustomAttrClick = () => {
    const cattr = (fullUser?.custom_attributes || {}) as Record<string, string | number | undefined | boolean>
    setCustomAttr(cattr)
    setEditMode(false)
  }

  useEffect(() => {
    onDiscardCustomAttrClick()
  }, [attrType])

  const onSaveCustomAttrClick = async () => {
    try {
      await dashboardClient.v1.updateUser(companyId, gameId, userId, {
        custom_attributes: customAttr,
      })
      if (fullUser) {
        fullUser.custom_attributes = customAttr
      }
      setEditMode(false)
      showToast({ message: t('saved'), severity: ToastSeverity.success })
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  const renderEmbeddedAttributes = () => {
    let attr = user?.attributes || ({} as PersistentPlayerAttributes)

    let tableProps = [
      // {
      //   label: t('players.attr.saved_ach'),
      //   value: attr.saved_ach == undefined ? '' : attr.saved_ach + '',
      // },
      // {
      //   label: t('players.attr.saved_card'),
      //   value: attr.saved_card == undefined ? '' : attr.saved_card + '',
      // },

      {
        label: t('players.attr.soft_currency_amount'),
        value: attr.soft_currency_amount || 'N/A',
      },
      {
        label: t('players.attr.hard_currency_amount'),
        value: attr.hard_currency_amount || 'N/A',
      },

      { label: t('players.attr.level'), value: attr.level },
      { label: t('players.attr.appsflyer_id'), value: attr.appsflyer_id },

      { label: t('players.attr.appsflyer_app_id'), value: attr.appsflyer_app_id },
      { label: t(''), value: '' },
    ] as PropColumn[]

    return <TableProperty rows={tableProps} />
  }

  const renderDateValue = (value: number | undefined, isValueLoading: boolean) => {
    if (isValueLoading) {
      return <AnimatedDots />
    }

    if (value) {
      return (
        <div className="flex gap-1">
          <DateTimeValue value={value} timeClassName="text-text-secondary" />
        </div>
      )
    }
  }

  const renderCalcAttributes = () => {
    let attr = fullUser?.calc_attributes

    let tableProps = [
      {
        label: t('players.calc-attr.purchases_count'),
        value: attr ? attr.purchases_count : <AnimatedDots />,
      },
      {
        label: t('players.calc-attr.active_offers_count'),
        value: attr ? attr.active_offers_count : <AnimatedDots />,
      },

      {
        label: t('players.calc-attr.last_email_date'),
        value: renderDateValue(attr?.last_email_date, isFullLoading),
      },
      {
        label: t('players.calc-attr.last_app_popup_date'),
        value: renderDateValue(attr?.last_app_popup_date, isFullLoading),
      },
      {
        label: t('players.calc-attr.last_app_push_date'),
        value: renderDateValue(attr?.last_app_push_date, isFullLoading),
      },
      {
        label: t('players.calc-attr.last_hub_popup_date'),
        value: renderDateValue(attr?.last_hub_popup_date, isFullLoading),
      },
      {
        label: t('players.calc-attr.last_communication_date'),
        value: renderDateValue(attr?.last_communication_date, isFullLoading),
      },
      {
        label: t('players.calc-attr.last_hub_visit'),
        value: renderDateValue(user?.last_active_at, isFullLoading),
      },
      {
        label: t('players.calc-attr.game_hub_first_login'),
        value: renderDateValue(user?.created_at, isFullLoading),
      },
      {
        label: t('players.calc-attr.ltv'),
        value: user ? formatMoney(user.ltv) : <AnimatedDots />,
      },
      {
        label: t('players.calc-attr.game_hub_login_count'),
        value: user?.game_hub_login_count,
      },
    ] as PropColumn[]

    return <TableProperty rows={tableProps} />
  }

  const renderCustomAttributes = () => {
    let allAttr = settings?.player_attributes || []

    let tableProps = allAttr.map(attr => ({
      label: attr.name,
      value: renderValue(attr),
    }))

    return <TableProperty rows={tableProps} />
  }

  const renderAttrTabs = () => {
    return (
      <PageBlock
        className="ph-no-capture"
        title={t('players.attributes')}
        btnIcon={editMode ? <Save /> : <Edit03 />}
        style={{ border: `1px solid ${theme.palette.grey.borderPrimary}` }}
        btnText={editMode ? t('Save') : t('Edit')}
        cancelClick={editMode ? onDiscardCustomAttrClick : undefined}
        cancelBtnText={t('Discard')}
        editClick={
          canUserEdit && attrType == TabAttrType.custom
            ? () => {
                if (editMode) {
                  onSaveCustomAttrClick()
                } else {
                  setEditMode(true)
                }
              }
            : undefined
        }
      >
        <Tabs>
          <Tab
            data-testid="tab-item-base"
            isActive={attrType == TabAttrType.embedded}
            onClick={() => setAttrType(TabAttrType.embedded)}
          >
            {t('player-details.attr-tab-type.embedded')}
          </Tab>
          {settings?.player_attributes && settings.player_attributes.length > 0 && (
            <Tab
              data-testid="tab-item-store"
              isActive={attrType == TabAttrType.custom}
              onClick={() => setAttrType(TabAttrType.custom)}
            >
              {t('player-details.attr-tab-type.custom')}
            </Tab>
          )}
          <Tab
            data-testid="tab-item-store"
            isActive={attrType == TabAttrType.calculated}
            onClick={() => setAttrType(TabAttrType.calculated)}
          >
            {t('player-details.attr-tab-type.calculated')}
          </Tab>
        </Tabs>
        {attrType == TabAttrType.embedded && renderEmbeddedAttributes()}
        {attrType == TabAttrType.custom && renderCustomAttributes()}
        {attrType == TabAttrType.calculated && renderCalcAttributes()}
      </PageBlock>
    )
  }

  const renderLiveopsContent = () => {
    if (!user) {
      return <></>
    }

    return (
      <PageBlock
        title={t('players.liveops')}
        style={{ border: `1px solid ${theme.palette.grey.borderPrimary}` }}
        className="min-h-[308px]"
      >
        <Tabs>
          <Tab
            data-testid="player/tab/virtualsku"
            isActive={liveopsContent == LiveopsTabs.virtualsku}
            onClick={() => setLiveopsContent(LiveopsTabs.virtualsku)}
          >
            {t('players.active-virtual-sku')}
          </Tab>
          <Tab
            data-testid="player/tab/claimed"
            isActive={liveopsContent == LiveopsTabs.claimed}
            onClick={() => setLiveopsContent(LiveopsTabs.claimed)}
          >
            {t('players.claimed-items')}
          </Tab>
          <Tab
            data-testid="player/tab/userbanners"
            isActive={liveopsContent == LiveopsTabs.userbanners}
            onClick={() => setLiveopsContent(LiveopsTabs.userbanners)}
          >
            {t('players.featured-banners')}
          </Tab>
          <Tab
            data-testid="player/tab/user-store-settings"
            isActive={liveopsContent == LiveopsTabs.storesettings}
            onClick={() => setLiveopsContent(LiveopsTabs.storesettings)}
          >
            {t('players.user-store-settings')}
          </Tab>
        </Tabs>
        {liveopsContent == LiveopsTabs.virtualsku && <CampaignVirtualSKUTable userId={user.id} />}
        {liveopsContent == LiveopsTabs.claimed && <UserClaimedItemsTable userId={user.id} />}
        {liveopsContent == LiveopsTabs.userbanners && <UserBannerTable userId={user.id} />}
        {liveopsContent == LiveopsTabs.storesettings && <UserStoreSettingsTable userId={user.id} />}
      </PageBlock>
    )
  }

  if (isError) {
    return (
      <EmptyTableMessage
        title={t('service-unavailable')}
        subtitle={t('SomethingWentWrong')}
        sx={{
          height: '100%',
          border: 'none',
          backgroundColor: theme.palette.background.content,
        }}
      />
    )
  }

  return (
    <div>
      <PageHeader>
        <BackButton
          text={t('player.title')}
          onClick={back.onClick}
          extraRight={user && <Badge variant="gray-tertiary">{user.player_id}</Badge>}
        />
      </PageHeader>

      {isLoading || !user ? (
        <CenteredProgress />
      ) : (
        <div className="flex flex-col gap-5">
          <>
            <PlayerCard
              user={user}
              fullUser={fullUser}
              calc_attributes={fullUser?.calc_attributes}
              checkoutUsers={fullUser?.checkout_users}
              showMeasures={true}
            />
            {renderAttrTabs()}
            {renderPrevPurchases()}
            {renderCoupons()}
            {renderLiveopsContent()}
            <UserWebhookLog userId={user.id} />
          </>

          <div className="h-[100px]" />
        </div>
      )}
    </div>
  )
}

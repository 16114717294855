import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { SidebarBadge } from './SidebarBadge'
import { SidebarLinkBadge } from './types'

export interface SidebarSubLinkProps extends NavLinkProps {
  children: ReactNode
  badge?: SidebarLinkBadge
}

export const SidebarSubLink = ({ children, badge, ...rest }: SidebarSubLinkProps) => {
  const { t } = useTranslation()
  return (
    <NavLink {...rest} className="group/sub-link flex items-center py-0.5">
      <div className="relative z-10 ml-3.5 mr-5 h-4 w-0.5 shrink-0 rounded-lg transition-colors group-[.active]/sub-link:bg-border-brand" />
      <div className="flex h-8 w-full items-center gap-2 rounded-lg px-2 font-medium transition-colors group-hover/sub-link:bg-white group-[.active]/sub-link:bg-white">
        {children}
        {badge === 'new' && (
          <div className="ml-auto">
            <SidebarBadge>{t('sidebar.badge.new')}</SidebarBadge>
          </div>
        )}
      </div>
    </NavLink>
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import { Archive, Button, Edit03, InputSearch, ModalPresetConfirm, cn, useModal, useSearchFilter } from '@dashboard/ui'
import {
  Badge,
  PageHeader,
  Pagination,
  Tab,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowEditButton,
  TableRowSkeleton,
  TableZeroState,
  Tabs,
  usePagination,
} from '@/ui'
import { Trash, Unarchive } from '@/icons'
import { useStorePromotionsQuery } from './api'
import { ResourceState, StorePromotionRead } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useBulkSelectGeneric } from '@/libs/hooks/useBulkSelectGeneric'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import StorePromotionsSettings from '@/layouts/store-promotion/StorePromotionsSettings'
import { dashboardClient } from '@/api'
import { TimeLimitInfo } from '@/components/TimeLimitInfo'
import { formatNumber } from '@/util'
import { STORE_PROMOTIONS_PATH, STORE_PROMOTIONS_PATH_DETAILS, useGenerateCompanyPath } from '@/libs'
import { URL_NEW } from '@/types'
import { TableZeroStateFilter } from '@/layouts/components/TableZeroStateFilter'

type PromotionFilter = 'state' | 'search_string'

export default function StorePromotionPage() {
  const { companyId, gameId, promotionId } = useParams() as {
    companyId: string
    gameId: string
    promotionId: string
  }
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { generatePath } = useGenerateCompanyPath()
  const filter = useSearchFilter<PromotionFilter>()
  const hasFilter = !!filter.values.search_string
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const {
    data: items = [],
    isLoading,
    refetch,
  } = useStorePromotionsQuery(companyId, gameId, {
    ...query,
    ...filter.values,
    resource_state: (filter.values.state == 'all'
      ? undefined
      : (filter.values.state ?? ResourceState.Active)) as ResourceState,
  })

  const { selected, onSelect, onReset } = useBulkSelectGeneric()

  const openBulkArchiveModal = useModal(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        subtitle: t(
          filter.values.state == ResourceState.Archived
            ? 'store-promotions.unarchive-n-items'
            : 'store-promotions.archive-n-items',
          { N: selected.length },
        ),
        children: t('confirm.title'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onArchivedChange(items.filter(it => selected.includes(it.id)))
        },
        label: t('remove'),
      }}
    />
  ))

  const onArchivedChange = async (item: StorePromotionRead[]) => {
    const data = item.map(it => {
      const res = {
        archived_at: (it.archived_at ? null : new Date().getTime() / 1000) as number,
        active: undefined as boolean | undefined,
        id: it.id,
      }

      if (res.archived_at) {
        res.active = false
      }

      return res
    })

    await dashboardClient.v1.bulkUpdateStorePromotions(companyId, gameId, data)
    await refetch()
    onReset([])
  }

  const openConfirmArchive = useModal<{ item: StorePromotionRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t(props.item.archived_at ? 'store-promotions.unarchive' : 'store-promotions.archive', {
          name: props.item.name,
        }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: props.item.archived_at ? 'primary' : 'danger',
        callback: () => {
          props.onClose()
          onArchivedChange([props.item])
        },
        label: t(props.item.archived_at ? 'unarchive' : 'archive'),
      }}
    />
  ))

  const openPromotionSettings = (id: string) => {
    navigate(generatePath(STORE_PROMOTIONS_PATH_DETAILS, { promotionId: id }, true))
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        {items.length > 0 && canEdit && filter.values.state != 'all' && (
          <TableCellBulkCheckbox
            selected={selected.length > 0 && selected.length == items.length}
            disabled={items.length === 0}
            onChange={() => onReset(selected.length == items.length ? [] : items.map(i => i.id))}
          />
        )}
        <TableCell>{t('store-promotions.name')}</TableCell>
        <TableCell width="25%">{t('store-promotions.status')}</TableCell>
        <TableCell width="25%">{t('store-promotions.purchase-count')}</TableCell>
        <TableCell width="30%">{t('store-promotions.time-limitations')}</TableCell>

        <TableCell width="20%" />
      </TableRow>
    )
  }

  const renderBulkSelectionHeader = () => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.length > 0 && selected.length == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.length == items.length ? [] : items.map(i => i.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button variant="tertiary" color="danger" size="sm" onClick={openBulkArchiveModal}>
          <Trash size={16} />
          <span>{filter.values.state == ResourceState.Archived ? t('unarchive') : t('archive')}</span>
        </Button>
      </TableCellBulkToolbar>
    </TableRow>
  )

  const renderTimeLimit = (storeItem: StorePromotionRead) => {
    if (storeItem.start_at || storeItem.end_at) {
      return (
        <div className="flex flex-col">
          <TimeLimitInfo value={storeItem.start_at} />
          <TimeLimitInfo value={storeItem.end_at} />
        </div>
      )
    }
    return ''
  }

  const renderZeroState = () => {
    if (filter.values.state == ResourceState.Archived && !filter.values.search_string) {
      return <TableZeroState title={t('store-promotions.archived')} message={t('store-promotions.archived.message')} />
    }

    return (
      <TableZeroStateFilter
        page={page}
        items={items}
        hasFilter={hasFilter}
        createClick={() => {
          openPromotionSettings(URL_NEW)
        }}
        zeroStateMessage={t(
          canEdit ? 'store-promotions.empty-table.title' : 'store-promotions.empty-table.title.read-only',
        )}
        zeroStateSubMessage={t(canEdit ? 'store-promotions.empty-table' : 'store-promotions.empty-table.read-only')}
        addButtonText={t('store-promotions.zero-state.new-button')}
        notFoundMessage={t('store-promotions.zero.text')}
        clearFilterClick={() => {
          filter.onChange({
            state: filter.values.state,
          })
        }}
      />
    )
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit && (
            <>
              {!!items.length && (
                <Button size="sm" onClick={() => openPromotionSettings(URL_NEW)}>
                  {t('store-promotions.create-store')}
                </Button>
              )}
            </>
          )
        }
      >
        {t('sidebar.store-promotions')}
      </PageHeader>

      <Tabs className="mb-4">
        <Tab
          isActive={filter.values.state == 'all'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'all' }, { force: true })
          }}
        >
          {t('sku.items.tabs.all')}
        </Tab>
        <Tab
          isActive={!filter.values.state}
          onClick={() => {
            filter.onChange({ ...filter.values, state: undefined }, { force: true })
          }}
        >
          {t('sku.items.tabs.active')}
        </Tab>
        <Tab
          isActive={filter.values.state == ResourceState.Archived}
          onClick={() => {
            filter.onChange({ ...filter.values, state: ResourceState.Archived }, { force: true })
          }}
        >
          {t('sku.items.tabs.archived')}
        </Tab>
      </Tabs>

      {(filter.values.search_string || items.length > 0) && (
        <div className={cn('mb-4 flex w-full items-center gap-2')}>
          <InputSearch {...filter.registerInput('search_string')} placeholder={t('search')} />
        </div>
      )}

      {canEdit && (
        <DrawerSidebar open={!!promotionId} width="720px" position="right">
          {promotionId && (
            <StorePromotionsSettings
              onClose={() => {
                navigate(generatePath(STORE_PROMOTIONS_PATH, undefined, true))
              }}
            />
          )}
        </DrawerSidebar>
      )}

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 && page == 1 ? (
        renderZeroState()
      ) : (
        <>
          <Table>
            {selected.length == 0 ? renderDefaultHeader() : renderBulkSelectionHeader()}
            {items.map(it => (
              <TableRow key={it.id} onClick={() => openPromotionSettings(it.id)} className="cursor-pointer">
                {canEdit && filter.values.state != 'all' && (
                  <TableCellBulkCheckbox selected={selected.includes(it.id)} onChange={() => onSelect(it.id)} />
                )}
                <TableCell>{it.name}</TableCell>
                <TableCell width="25%">
                  <Badge variant={it.active ? 'green-tertiary' : 'gray-tertiary'}>
                    {t(it.active ? 'store.status.published' : 'store.status.unpublished')}
                  </Badge>
                </TableCell>
                <TableCell width="25%">{formatNumber(it.current_purchase_count)}</TableCell>
                <TableCell width="30%">{renderTimeLimit(it)}</TableCell>

                <TableCell width="20%">
                  <div className="text-right">
                    {canEdit && filter.values.state != 'all' && (
                      <TableRowEditButton
                        onChange={v => {
                          switch (v) {
                            case 'edit':
                              openPromotionSettings(it.id)
                              break
                            case 'unarchive':
                            case 'archive':
                              openConfirmArchive({
                                item: it,
                              })
                              break
                          }
                        }}
                        options={[
                          {
                            icon: <Edit03 />,
                            children: t('grid.edit'),
                            value: 'edit',
                          },
                          !it.archived_at && {
                            icon: <Archive />,
                            children: t('archive'),
                            value: 'archive',
                          },
                          !!it.archived_at && {
                            icon: <Unarchive />,
                            children: <span>{t('unarchive')}</span>,
                            value: 'unarchive',
                          },
                        ]}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}

import { useTranslation } from 'react-i18next'
import { Checkbox, FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import Divider from '../../../components/Divider'
import { FieldGroup, FieldSection, InputExtraMark } from '@/ui'
import { useContext, useEffect, useState } from 'react'
import { CouponType, CreateCouponNode, Node } from '@/api/dashboard'
import { SelectPaymentMethod } from '../components/SelectPaymentMethod/SelectPaymentMethod'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import { CampaignContext, ICampaignContext } from '../Context'
import { BlockError, ErrorInfo } from '../types'
import SettingsHeader from '../SettingsHeader'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { CONTEXT_ITEM, getContextItemText } from './common'
import { Controller, useForm } from 'react-hook-form'
import { KeyValue } from '@/types'
import { SelectSkuTable } from '@/layouts/components/SelectSkuTable'
import { ErrorMessage } from '@hookform/error-message'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { NODE_VALIDATORS } from '@/layouts/campaigns/validation'

interface CouponSettingsProps {
  onClose: () => void
  onSave: (node: Node) => void
  node: CreateCouponNode
  error?: BlockError | null
}

enum MaskType {
  PreDetermined = 'Pre-determined',
  Generated = 'Generated',
}

export const clearFieldValues = (data: CreateCouponNode, fields: string[]) => {
  let obj = data as unknown as KeyValue

  for (let field of fields) {
    obj[field] = null
  }
}

export default function CouponSettings(props: CouponSettingsProps) {
  const { t } = useTranslation()
  const context = useContext(CampaignContext) as ICampaignContext
  const [maskType, setMaskType] = useState<MaskType>(props.node.code_mask ? MaskType.PreDetermined : MaskType.Generated)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CreateCouponNode>({
    mode: 'onChange',
    values: { ...props.node },
  })

  const [max_redemptions, type, applicable_item_ids, payment_method_ids, min_amount, duration, use_event_item] = watch([
    'max_redemptions',
    'type',
    'applicable_item_ids',
    'payment_method_ids',
    'min_amount',
    'duration',
    'use_event_item',
  ])

  const onSubmit = (data: CreateCouponNode) => {
    switch (data.type) {
      case CouponType.FreeItem:
        clearFieldValues(data, [
          'bonus_percent',
          'discount_percent',
          'min_amount',
          'payment_method_ids',
          'applicable_item_ids',
          'is_stackable_with_bonus_coupons',
          'stackable_bonus_limit_percent',
          'is_stackable_with_discount_coupons',
          'stackable_discount_limit_percent',
        ])
        break

      case CouponType.Bonus:
        clearFieldValues(data, [
          'item_id',
          'discount_percent',
          'is_stackable_with_discount_coupons',
          'stackable_discount_limit_percent',
        ])
        break
      case CouponType.Discount:
        clearFieldValues(data, [
          'item_id',
          'bonus_percent',
          'is_stackable_with_bonus_coupons',
          'stackable_bonus_limit_percent',
        ])
        break
    }

    let errors = NODE_VALIDATORS[props.node.model_type as string](data)
    updateErrors(errors)
    if (!errors.length) {
      props.onSave(data as Node)
    }
  }

  const updateErrors = (errors: ErrorInfo[]) => {
    errors.forEach(e => {
      setError(e.field as 'title', { message: e.message })
    })
  }

  useEffect(() => {
    setMaskType(props.node.code_mask ? MaskType.PreDetermined : MaskType.Generated)
    reset({ ...props.node })
  }, [props.node])

  useEffect(() => {
    updateErrors(props.error?.errors || [])
  }, [props.error])

  const renderGeneral = () => {
    return (
      <FieldSection label={t('coupon.general')}>
        <FieldGroup label={t('coupon.type')}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={v => {
                  field.onChange(v)
                  if (v == CouponType.Bonus) {
                    if (applicable_item_ids) {
                      setValue('applicable_item_ids', [])
                    }
                  }
                }}
                items={[
                  {
                    value: CouponType.Bonus,
                    children: t('coupon.type.bonus'),
                  },
                  {
                    value: CouponType.Discount,
                    children: t('coupon.type.discount'),
                  },
                  {
                    value: CouponType.FreeItem,
                    children: t('coupon.type.free_item'),
                  },
                ]}
              />
            )}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderCodeMask = () => {
    return (
      <FieldSection label={t('coupon.code-mask')}>
        <FieldGroup label={t('coupon.code-mask-type')}>
          <div style={{ gap: '40px', display: 'flex' }}>
            <Checkbox
              type="radio"
              checked={maskType == MaskType.Generated}
              onChange={v => {
                if (v) {
                  setMaskType(MaskType.Generated)
                  setValue('code_mask', '')
                }
              }}
            >
              {t('coupon.code-mask-type.generated')}
            </Checkbox>
            <Checkbox
              type="radio"
              checked={maskType == MaskType.PreDetermined}
              onChange={v => {
                if (v) {
                  setMaskType(MaskType.PreDetermined)
                  setValue('code_mask', 'EXAMPLE*')
                }
              }}
            >
              {t('coupon.code-mask-type.pre-determined')}
            </Checkbox>
          </div>
        </FieldGroup>

        {maskType == 'Pre-determined' && (
          <div className="mt-4">
            <FieldGroup caption={t('coupon.code-mask.desc')} label={t('coupon.code-mask')}>
              <Input
                {...register('code_mask', {
                  required: t('validation.required'),
                  setValueAs: v => {
                    return v.toUpperCase().replace(/[^*A-Z0-9]/gi, '')
                  },
                })}
                onChange={event => {
                  event.target.value = event.target.value.toUpperCase()
                }}
                {...formatHookFormErrors(errors, 'code_mask')}
              />
            </FieldGroup>
          </div>
        )}
      </FieldSection>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <FieldSection label={t('coupon.general-limitations')}>
        <Controller
          name="max_redemptions"
          control={control}
          rules={{ required: max_redemptions != undefined ? t('validation.required') : false }}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              label={t('coupon.reusable')}
              checked={value != undefined}
              onChange={v => {
                onChange(v.target.checked ? 1 : null)
              }}
            >
              <Input
                extraRight={{ icon: <InputExtraMark>{t('times')}</InputExtraMark> }}
                type="number"
                {...register('max_redemptions', {
                  min: { value: 1, message: t('coupon.max_redemptions.validation') },
                  max: { value: 100_000_000, message: t('coupon.max_redemptions.validation') },
                })}
                {...formatHookFormErrors(errors, 'max_redemptions')}
              />
            </CheckBoxContainer>
          )}
        />

        <Divider />

        <Checkbox caption={t('coupon.player-bond-desc')} {...register('player_bond')}>
          {t('coupon.player-bond')}
        </Checkbox>

        {type != CouponType.FreeItem && (
          <>
            <Divider />
            <CheckBoxContainer
              checked={!!applicable_item_ids}
              onChange={v => {
                setValue('applicable_item_ids', v.target.checked ? [] : undefined)
              }}
              label={t('coupon.sku-bound')}
              caption={t('coupon.sku-bound-desc')}
            >
              <Controller
                name="applicable_item_ids"
                control={control}
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <SelectSkuTable
                    isStackable={type == CouponType.Bonus}
                    onChange={v => {
                      field.onChange(v)
                    }}
                    value={field.value || []}
                  />
                )}
              />
              <ErrorMessage
                name="applicable_item_ids"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </CheckBoxContainer>

            <Divider />

            <CheckBoxContainer
              checked={!!payment_method_ids}
              onChange={v => {
                setValue('payment_method_ids', v.target.checked ? [] : undefined)
              }}
              label={t('coupon.payment-bound')}
              caption={t('coupon.payment-bound-desc')}
            >
              <Controller
                name="payment_method_ids"
                control={control}
                render={({ field }) => (
                  <SelectPaymentMethod
                    value={field.value}
                    onChange={v => {
                      field.onChange(v)
                    }}
                  />
                )}
              />
              <ErrorMessage
                name="payment_method_ids"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </CheckBoxContainer>

            <Divider />

            <Controller
              name="min_amount"
              rules={{ required: min_amount != undefined ? t('validation.required') : false }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBoxContainer
                  checked={min_amount != undefined}
                  onChange={v => {
                    onChange(v.target.checked ? 100 : null)
                  }}
                  label={t('coupon.min_amount')}
                >
                  <Input
                    type="number"
                    value={!value || isNaN(value!) ? '' : value! / 100}
                    extraLeft={{ icon: <InputExtraMark>$</InputExtraMark> }}
                    onChange={e => {
                      if (e.target.value) {
                        let v = parseFloat(e.target.value)
                        onChange(Math.max(1, v * 100))
                      } else {
                        onChange('')
                      }
                    }}
                    {...formatHookFormErrors(errors, 'min_amount')}
                  />
                </CheckBoxContainer>
              )}
            />
          </>
        )}
      </FieldSection>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <FieldSection label={t('coupon.limit-limitations')}>
        <CheckBoxContainer
          checked={duration != undefined}
          onChange={v => {
            setValue('duration', v.target.checked ? 3600 : undefined)
          }}
          label={t('campaign.block.ItemDiscountOfferActionNode.limit-timer')}
        >
          <Controller
            rules={{
              required: duration != undefined ? t('validation.required') : false,
              min: { value: 1, message: t('validation.min-no-field', { value: 1 }) },
            }}
            name="duration"
            control={control}
            render={({ field }) => (
              <DurationSelectorDHMS
                duration={field.value || 0}
                size="small"
                onChange={v => {
                  field.onChange(v)
                }}
              />
            )}
          />
          <ErrorMessage
            name="duration"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <FieldSection label={t('coupon.benefits-compatibility')}>
        {type == CouponType.Bonus && (
          <Controller
            name="is_stackable_with_bonus_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={t('coupon.benefits.stackable')}
              >
                <FieldGroup caption={t('coupon.stackable-limit-desc')}>
                  <Input
                    extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
                    type="number"
                    {...register('stackable_bonus_limit_percent', {
                      required: value ? t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                    {...formatHookFormErrors(errors, 'stackable_bonus_limit_percent')}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}

        {type == CouponType.Discount && (
          <Controller
            name="is_stackable_with_discount_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={t('coupon.stackable-discount-code')}
              >
                <FieldGroup caption={t('coupon.stackable-discount-limit-desc')}>
                  <Input
                    extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
                    {...register('stackable_discount_limit_percent', {
                      required: value ? t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                    {...formatHookFormErrors(errors, 'stackable_discount_limit_percent')}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}
      </FieldSection>
    )
  }

  const renderBenefits = () => {
    if (type == CouponType.FreeItem) {
      return (
        <FieldSection label={t('coupon.benefits')}>
          <FieldGroup label={t('coupon.select-free-item')}>
            <Controller
              control={control}
              name="item_id"
              rules={{ required: use_event_item ? false : t('validation.required') }}
              render={({ field }) => (
                <SelectSkuItemV2
                  contextItemText={getContextItemText(context.campaign) || undefined}
                  value={use_event_item ? CONTEXT_ITEM : field.value}
                  onChange={v => {
                    field.onChange(v == CONTEXT_ITEM ? undefined : v)
                    setValue('use_event_item', v == CONTEXT_ITEM, { shouldDirty: true })
                    clearErrors('item_id')
                  }}
                />
              )}
            />
            {!use_event_item && (
              <ErrorMessage
                name="item_id"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            )}
          </FieldGroup>
        </FieldSection>
      )
    }

    return (
      <FieldSection label={t('coupon.benefits')}>
        {type == CouponType.Discount ? (
          <FieldGroup label={t('coupon.discount')}>
            <Input
              type="number"
              {...register('discount_percent', {
                required: t('validation.required'),
                min: { value: 1, message: t('coupon.discount_percent.validation') },
                max: { value: 99, message: t('coupon.discount_percent.validation') },
              })}
              {...formatHookFormErrors(errors, 'discount_percent')}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
          </FieldGroup>
        ) : (
          <FieldGroup caption={t('coupon.value-desc')} label={t('coupon.bonus')}>
            <Input
              type="number"
              {...register('bonus_percent', {
                required: t('validation.required'),
                min: {
                  value: 1,
                  message: t('validation.min-no-field', { value: 1 }),
                },
              })}
              {...formatHookFormErrors(errors, 'bonus_percent')}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
          </FieldGroup>
        )}
      </FieldSection>
    )
  }

  return (
    <div>
      <SettingsHeader onSave={handleSubmit(onSubmit)} onClose={props.onClose} text={t('coupon.settings')} />

      <div className="p-6">
        {renderGeneral()}
        <Divider />
        {renderCodeMask()}
        <Divider />
        {renderBenefits()}

        {type != CouponType.FreeItem && (
          <>
            <Divider />
            {renderBenefitsCompatibility()}
          </>
        )}

        <Divider />
        {renderGeneralLimitations()}
        <Divider />
        {renderTimeLimitations()}
      </div>
    </div>
  )
}

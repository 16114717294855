import { AttrType, CampaignEventType } from '@/api/dashboard'

export const EMPTY_VALUE = '__select__'

export type TokenValue = string | number | undefined | boolean | string[] | number[]

export enum DateFilterType {
  DATE = 'DATE',
  AGO = 'AGO',
}

export enum LastDateFilter {
  '30.day.ago' = '30.day.ago',
  '7.day.ago' = '7.day.ago',
  '3.day.ago' = '3.day.ago',
  '2.day.ago' = '2.day.ago',
  '1.day.ago' = '1.day.ago',
  '12.hour.ago' = '12.hour.ago',
  '1.hour.ago' = '1.hour.ago',
  '30.minute.ago' = '30.minute.ago',
  '15.minute.ago' = '15.minute.ago',
  //15-01-2024.date  for date
}

export enum OperatorType {
  'or' = 'or',
  'and' = 'and',
  '==' = '==',
  '!=' = '!=',
  '<' = '<',
  '>' = '>',
  '<=' = '<=',
  '>=' = '>=',
  'is_none' = 'is_none',
  'is_not_none' = 'is_not_none',
  'in_range' = 'in_range',
  'not_in_range' = 'not_in_range',
  'in_list' = 'in_list',
  'not_in_list' = 'not_in_list',
}

export enum OperatorGeneralType {
  all,
  contains_or_not,
}

export enum CalcPlayerAttributes {
  ltv = 'ltv',
  purchases_count = 'purchases_count',
  last_purchase_date = 'last_purchase_date',
  last_purchase_item_id = 'last_purchase_item_id',
  last_purchase_sku_id = 'last_purchase_sku_id',
  last_purchase_amount = 'last_purchase_amount',
  last_email_date = 'last_email_date',
  last_app_popup_date = 'last_app_popup_date',
  last_app_push_date = 'last_app_push_date',
  last_hub_push_date = 'last_hub_push_date',
  last_hub_popup_date = 'last_hub_popup_date',
  last_communication_date = 'last_communication_date',
  last_app_popup_coupon_sent = 'last_app_popup_coupon_sent',
  active_offers_count = 'active_offers_count',
  has_active_offer = 'has_active_offer',
  last_store_promotion_date = 'last_store_promotion_date',
  last_offer_date = 'last_offer_date',
  average_check = 'average_check',
  last_hub_visit = 'last_hub_visit',
  user_segments = 'user_segments',
  email = 'email',
  country = 'country',
  communication_count = 'communication_count',
  has_purchase = 'has_purchase',
  game_hub_login_count = 'game_hub_login_count',
  player_is_idle = 'player_is_idle',
  game_hub_first_login = 'game_hub_first_login',
  has_claim_item = 'has_claim_item',
  marketplace = 'marketplace',
  reward_points_balance = 'reward_points_balance',
  loyalty_points_balance = 'loyalty_points_balance',
}

export enum PersistentPlayerAttributes {
  level = 'level',
  saved_ach = 'saved_ach',
  saved_card = 'saved_card',
  soft_currency_amount = 'soft_currency_amount',
  hard_currency_amount = 'hard_currency_amount',
  custom_attributes = 'custom_attributes',
}

export enum AttrDataType {
  built_in,
  may_complement,
  webhook_complement,
}

export enum AttrGroup {
  base,
  payment_info,
  communication,
}

export enum SelectorValueType {
  sku,
  segment,
  skus,
  country,
  sku_no_price,
}

export interface Attribute {
  id?: string
  type: AttrType
  name: string
  isMoney?: boolean
  isReady?: boolean
  dataType?: AttrDataType
  generalType?: OperatorGeneralType
  selectorValueType?: SelectorValueType
  group?: AttrGroup
}

export const DATE_TIME_ATTR = {
  id: 'datetime',
  isReady: true,
  type: AttrType.Date,
  name: 'CurrentDateTime',
}

export const DETECTED_COUNTRY_ATTR = {
  id: 'detected_country',
  type: AttrType.String,
  name: 'DetectedCountry',
  dataType: AttrDataType.built_in,
  isReady: true,
  selectorValueType: SelectorValueType.country,
}

export const CAMPAIGN_ATTRIBUTES: { [key: string]: Attribute[] } = {
  '': [
    DATE_TIME_ATTR,
    {
      id: 'is_action_taken',
      type: AttrType.Boolean,
      name: 'IsActionTaken',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
  ],
  [CampaignEventType.AbandonedCart]: [
    {
      id: 'abandoned_sku',
      type: AttrType.String,
      name: 'Abandoned SKU',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
    {
      id: 'abandoned_price',
      type: AttrType.Number,
      name: 'Abandoned Price',
      isMoney: true,
      dataType: AttrDataType.built_in,
      isReady: true,
    },
    {
      id: 'abandoned_is_stackable',
      type: AttrType.Boolean,
      name: 'Abandoned Item Is Stackable',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
  ],
  [CampaignEventType.PurchasedInWeb]: [
    {
      id: 'purchase_sku',
      type: AttrType.String,
      name: 'Purchase SKU',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
    {
      id: 'purchase_item',
      type: AttrType.String,
      name: 'Purchased Item',
      dataType: AttrDataType.built_in,
      isReady: true,
      selectorValueType: SelectorValueType.sku,
      generalType: OperatorGeneralType.contains_or_not,
    },
    {
      id: 'purchase_price',
      type: AttrType.Number,
      name: 'Purchase Price',
      isMoney: true,
      dataType: AttrDataType.built_in,
      isReady: true,
    },
    {
      id: 'purchase_is_stackable',
      type: AttrType.Boolean,
      name: 'Purchased Item Is Stackable',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
  ],
  [CampaignEventType.CouponRedeemed]: [
    {
      id: 'coupon_redeemed_code',
      type: AttrType.String,
      name: 'Coupon Redeemed Code',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
    {
      id: 'coupon_redeemed_item_sku',
      type: AttrType.String,
      name: 'Coupon Redeemed Item SKU',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
  ],
  [CampaignEventType.GameHubLogin]: [
    {
      id: 'is_first_login',
      type: AttrType.Boolean,
      name: 'IsFirstLogin',
      dataType: AttrDataType.built_in,
      isReady: true,
    },
  ],
  [CampaignEventType.DailyRewardClaimed]: [
    {
      id: 'claimed_item_ids',
      type: AttrType.List,
      name: 'Claimed Items',
      dataType: AttrDataType.built_in,
      generalType: OperatorGeneralType.contains_or_not,
      isReady: true,
      selectorValueType: SelectorValueType.sku,
    },
  ],
  ['Other']: [DETECTED_COUNTRY_ATTR],
}

export const GameHubVisitStoreAttributes = [
  {
    id: 'items',
    type: AttrType.List,
    name: 'Store Items',
    selectorValueType: SelectorValueType.skus,
    generalType: OperatorGeneralType.contains_or_not,
    dataType: AttrDataType.built_in,
    isReady: true,
  },
] as Attribute[]

export const PLAYER_ATTRIBUTES: Attribute[] = [
  {
    id: CalcPlayerAttributes.ltv,
    type: AttrType.Number,
    name: 'LTV',
    isMoney: true,
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.purchases_count,
    type: AttrType.Number,
    name: 'PurchasesCount',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.player_is_idle,
    type: AttrType.Boolean,
    name: 'Player Is Idle',
    dataType: AttrDataType.webhook_complement,
    isReady: false,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.last_purchase_date,
    type: AttrType.Date,
    name: 'LastPurchase.Date',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.last_purchase_item_id,
    type: AttrType.String,
    name: 'LastPurchase',
    dataType: AttrDataType.built_in,
    isReady: true,
    selectorValueType: SelectorValueType.sku,
    generalType: OperatorGeneralType.contains_or_not,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.last_purchase_amount,
    type: AttrType.Number,
    isMoney: true,
    isReady: true,
    name: 'LastPurchase.Amount',
    dataType: AttrDataType.built_in,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.last_email_date,
    type: AttrType.Date,
    name: 'LastEmailDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_app_popup_date,
    type: AttrType.Date,
    name: 'LastAppPopupDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_app_push_date,
    type: AttrType.Date,
    name: 'LastAppPushDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_hub_push_date,
    type: AttrType.Date,
    name: 'LastHubPushDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_hub_popup_date,
    type: AttrType.Date,
    name: 'LastHubPopupDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_app_popup_coupon_sent,
    type: AttrType.Date,
    name: 'LastCouponAppPopupDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.last_communication_date,
    type: AttrType.Date,
    name: 'LastCommunicationDate',
    isReady: true,
    dataType: AttrDataType.built_in,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.active_offers_count,
    type: AttrType.Number,
    name: 'ActiveOffersCount',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.last_store_promotion_date,
    type: AttrType.Date,
    name: 'LastStorePromotionDate',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.last_offer_date,
    type: AttrType.Date,
    name: 'LastOfferDate',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.has_active_offer,
    type: AttrType.String,
    name: 'Offers',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
    selectorValueType: SelectorValueType.sku,
    generalType: OperatorGeneralType.contains_or_not,
  },
  {
    id: CalcPlayerAttributes.average_check,
    type: AttrType.Number,
    name: 'AverageCheck',
    isMoney: true,
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.country,
    type: AttrType.String,
    name: 'Country',
    dataType: AttrDataType.may_complement,
    isReady: true,
    selectorValueType: SelectorValueType.country,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.email,
    type: AttrType.String,
    name: 'Email',
    dataType: AttrDataType.may_complement,
    isReady: true,
    group: AttrGroup.base,
  },
  {
    id: PersistentPlayerAttributes.level,
    type: AttrType.Number,
    name: 'Level',
    dataType: AttrDataType.may_complement,
    isReady: true,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.marketplace,
    type: AttrType.String,
    name: 'Marketplace',
    dataType: AttrDataType.may_complement,
    isReady: true,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.reward_points_balance,
    type: AttrType.Number,
    name: 'RewardPointsBalance',
    dataType: AttrDataType.may_complement,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.loyalty_points_balance,
    type: AttrType.Number,
    name: 'LoyaltyPointsBalance',
    dataType: AttrDataType.may_complement,
    isReady: true,
    group: AttrGroup.payment_info,
  },
  // [PersistentPlayerAttributes.saved_ach]: {
  //   type: AttrType.Boolean,
  //   name: 'Saved Bank Account',
  //   dataType: AttrDataType.built_in,
  //   isReady: false,
  //   description: 'Selecting this option will allow you to choose all players who have saved their ACH details..',
  // },
  // [PersistentPlayerAttributes.saved_card]: {
  //   type: AttrType.Boolean,
  //   name: 'SavedCard',
  //   dataType: AttrDataType.built_in,
  //   isReady: false,
  //   description: 'Selecting this option will allow you to choose all players who have saved their bank card details.',
  // },
  // [PersistentPlayerAttributes.soft_currency_amount]: {
  //   type: AttrType.Number,
  //   name: 'SoftCurrencyCount',
  //   isReady: false,
  //   dataType: AttrDataType.built_in,
  //   description: 'The volume of soft currency acquired by each player in the game over time.',
  // },
  // [PersistentPlayerAttributes.hard_currency_amount]: {
  //   type: AttrType.Number,
  //   name: 'HardCurrencyCount',
  //   isReady: false,
  //   dataType: AttrDataType.built_in,
  //   description: 'The volume of hard currency acquired by each player in the game over time.',
  // },
  {
    id: CalcPlayerAttributes.game_hub_first_login,
    type: AttrType.Date,
    name: 'HubFirstLoginDate',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.last_hub_visit,
    type: AttrType.Date,
    name: 'LastHubVisit',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.user_segments,
    type: AttrType.String,
    name: 'UserSegment',
    dataType: AttrDataType.built_in,
    isReady: true,
    generalType: OperatorGeneralType.contains_or_not,
    selectorValueType: SelectorValueType.segment,
    group: AttrGroup.base,
  },
  {
    id: CalcPlayerAttributes.communication_count,
    type: AttrType.Number,
    name: 'CommunicationCount',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.communication,
  },
  {
    id: CalcPlayerAttributes.has_purchase,
    type: AttrType.String,
    name: 'Purchases',
    dataType: AttrDataType.built_in,
    isReady: true,
    generalType: OperatorGeneralType.contains_or_not,
    selectorValueType: SelectorValueType.sku,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.has_claim_item,
    type: AttrType.String,
    name: 'ClaimedItems',
    dataType: AttrDataType.built_in,
    isReady: true,
    generalType: OperatorGeneralType.contains_or_not,
    selectorValueType: SelectorValueType.sku_no_price,
    group: AttrGroup.payment_info,
  },
  {
    id: CalcPlayerAttributes.game_hub_login_count,
    type: AttrType.Number,
    name: 'GameHubLoginCount',
    dataType: AttrDataType.built_in,
    isReady: true,
    group: AttrGroup.base,
  },
]

import i18n from 'i18next'
import { UIProvider } from '@dashboard/ui'
import { ModalProvider } from '@/ui'

export default function ModalUiOutlet(props: { children: React.ReactNode }) {
  return (
    <UIProvider i18n={i18n} staticImagesPath={import.meta.env.VITE_STATIC_IMAGES_URL}>
      <ModalProvider>{props.children}</ModalProvider>
    </UIProvider>
  )
}

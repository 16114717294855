import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { AccountDetails, AuthorizedSignatoryDetails } from '@/api/dashboard'
import { Alert, Button, Callout, DrawerContent, DrawerTopbar, FieldGroup, Input, SelectCountry } from '@/ui'
import { InfoCircle } from '@/icons'
import { isEmail } from '@/util'
import { ErrorMessage } from '@hookform/error-message'
import { FormErrorMessage } from '@dashboard/ui'

interface VerifyAuthorizedSignatoryFormProps {
  data?: AuthorizedSignatoryDetails
  onSubmit: (data: AccountDetails) => Promise<void>
  onCancel: () => void
}

export const VerifyAuthorizedSignatoryForm = ({ data, onSubmit, onCancel }: VerifyAuthorizedSignatoryFormProps) => {
  const { t } = useTranslation()
  const {
    formState: { errors, isSubmitting },
    register,
    control,
    handleSubmit,
  } = useForm<AuthorizedSignatoryDetails>({
    defaultValues: {
      first_name: '',
      last_name: '',
      country: undefined,
      job_title: '',
      email: '',
      ...data,
    },
  })

  const onHandleSubmit = handleSubmit(data => {
    return onSubmit({ authorized_signatory: data })
  })

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={onHandleSubmit}>
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{t('get-started.verify.steps.authorized-signatory.form.title')}</h2>
        <div className="flex gap-3">
          <Button variant="outline" type="button" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" type="submit" isLoading={isSubmitting}>
            {t('Save2')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <div className="mb-6">
          <p className="text-paragraph-md text-text-secondary">
            {t('get-started.verify.steps.authorized-signatory.form.desc')}
          </p>
        </div>

        <div className="mb-6">
          <Callout icon={InfoCircle}>
            <p>{t('get-started.verify.steps.authorized-signatory.form.callout')}</p>
          </Callout>
        </div>

        <div className="mb-4">
          <FieldGroup
            label={t('get-started.verify.steps.authorized-signatory.form.name.label')}
            size="sm"
            required={true}
          >
            <div className="mb-3">
              <Input
                {...register('first_name', {
                  required: t('validation.required'),
                  maxLength: {
                    message: t('validation.maxLength', {
                      field: t('get-started.verify.steps.authorized-signatory.form.name.first-name-placeholder'),
                      value: 50,
                    }),
                    value: 50,
                  },
                })}
                autoFocus={true}
                errors={errors}
                placeholder={t('get-started.verify.steps.authorized-signatory.form.name.first-name-placeholder')}
              />
            </div>
            <Input
              {...register('last_name', {
                required: t('validation.required'),
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.authorized-signatory.form.name.last-name-placeholder'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
              placeholder={t('get-started.verify.steps.authorized-signatory.form.name.last-name-placeholder')}
            />
          </FieldGroup>
          <Alert>{t('get-started.verify.steps.authorized-signatory.form.name.caption')}</Alert>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.authorized-signatory.form.country')} size="sm" required={true}>
            <Controller
              name="country"
              control={control}
              rules={{ required: t('validation.required') }}
              render={({ field }) => <SelectCountry {...field} />}
            />
            <ErrorMessage
              name="country"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup
            label={t('get-started.verify.steps.authorized-signatory.form.job.label')}
            size="sm"
            required={true}
          >
            <Input
              {...register('job_title', {
                required: t('validation.required'),
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.authorized-signatory.form.job.label'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
              placeholder={t('get-started.verify.steps.authorized-signatory.form.job.placeholder')}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup
            label={t('get-started.verify.steps.authorized-signatory.form.email.label')}
            size="sm"
            required={true}
          >
            <Input
              {...register('email', {
                required: t('validation.required'),
                validate: v => {
                  if (!isEmail(v)) {
                    return t('validation.invalid_email')
                  }
                  return true
                },
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.authorized-signatory.form.email.label'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
              placeholder={t('get-started.verify.steps.authorized-signatory.form.email.placeholder')}
            />
          </FieldGroup>
          <p className="px-2 py-1.5 text-paragraph-sm text-text-secondary">
            {t('get-started.verify.steps.authorized-signatory.form.email.caption')}
          </p>
        </div>
      </DrawerContent>
    </form>
  )
}

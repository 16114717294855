import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { LatestNewsBlock } from '@/api/dashboard'
import { FieldGroup } from '@/ui'
import { Checkbox } from '@dashboard/ui'

const LatestNewsEditor = () => {
  const { t } = useTranslation()

  const { register } = useFormContext<LatestNewsBlock>()

  return (
    <FieldGroup>
      <Checkbox className="mt-2" {...register('hide_controls')}>
        {t('hub-editor.block-latest-news.hide_controls')}
      </Checkbox>
    </FieldGroup>
  )
}

export default LatestNewsEditor

import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountries } from '@/translates'
import { FLAGS } from '@/util'
import { Select, SelectProps } from '@dashboard/ui'

export const SelectCountry = forwardRef<HTMLDivElement, Omit<SelectProps, 'items'>>(function SelectCountry(props) {
  const countries = getCountries()
  const { t } = useTranslation()
  return (
    <Select
      {...props}
      placeholder={t('select-country')}
      search={{
        placeholder: t('SearchInputPlaceHolder'),
        fn: (search, option) => {
          const s = search.toLowerCase().trim()
          const text = option.children as string
          const value = option.value + ''
          return text.toLowerCase().trim().includes(s) || value.toLowerCase().includes(s)
        },
      }}
      items={Object.entries(countries).map(([value, label]) => ({
        value,
        children: label as string,
        icon: () => <img className="w-6" src={`${FLAGS}/${value}.svg`} loading="lazy" alt={value} />,
        extra: () => <span className="text-text-disabled">{value}</span>,
      }))}
    />
  )
})

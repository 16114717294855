import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { FormSection } from '../../components'
import { SkuFormData } from './SkuForm'
import { FieldGroup, Toggle } from '@/ui'
import { useState } from 'react'
import { FormErrorMessage, Input, formatHookFormErrors, getMotionProps } from '@dashboard/ui'
import { ErrorMessage } from '@hookform/error-message'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { AnimatePresence, motion } from 'framer-motion'
import { MAX_QUANTITY } from '@/Settings'
import { gameItemsQueryOptions } from '@/api/useGameItems'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ItemType } from '@/api/dashboard'

const entryMotionProps = getMotionProps({
  initial: { paddingTop: 0, height: 0, opacity: 0 },
  animate: { paddingTop: 8, height: 'auto', opacity: 1 },
  exit: { paddingTop: 0, height: 0, opacity: 0 },
})

export const SkuFormFallbackItem = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const {
    formState: { errors },
    control,
    setValue,
    watch,
    register,
    clearErrors,
  } = useFormContext<SkuFormData>()

  const [fallback_item_id, id] = watch(['fallback_item_id', 'id'])

  const [checked, setChecked] = useState(!!fallback_item_id)
  const { data: items = [] } = useQuery({
    ...gameItemsQueryOptions(companyId, gameId, {
      ids: fallback_item_id,
    }),
    enabled: !!fallback_item_id,
  })

  return (
    <FormSection
      label={t('game-item-edit-dialog.fallback-item')}
      action={
        <Toggle
          sizev="md"
          checked={checked}
          onChange={e => {
            setChecked(e.target.checked)
            if (!e.target.checked) {
              setValue('fallback_item_id', null as unknown as string, { shouldDirty: true })
              clearErrors(['fallback_item_id', 'fallback_item_quantity'])
            }
          }}
        />
      }
    >
      <div className="text-text-secondary">{t('game-item-edit-dialog.fallback-item.desc')}</div>
      <AnimatePresence>
        {checked && (
          <motion.div {...entryMotionProps} className="mt-6 flex w-full gap-[18px]">
            <FieldGroup label={t('sku.item.sku')} className="w-2/3">
              <Controller
                control={control}
                name="fallback_item_id"
                rules={{
                  required: t('validation.required'),
                }}
                render={({ field }) => (
                  <SelectSkuItemV2
                    types={[ItemType.Item, ItemType.Currency]}
                    hideIds={[id]}
                    value={field.value}
                    onChange={(v, selectedItem) => {
                      field.onChange(v)
                      setValue(
                        'fallback_item_quantity',
                        (selectedItem?.is_stackable ? selectedItem.quantity : null) as number,
                      )
                    }}
                  />
                )}
              />
              <ErrorMessage
                name="fallback_item_id"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            {items[0]?.is_stackable ? (
              <FieldGroup label={t('game-item-edit-dialog.quantity')} className="w-1/3">
                <Input
                  type="number"
                  min={0}
                  {...register('fallback_item_quantity', {
                    required: t('validation.required'),
                    min: { value: 0, message: t('game-item-page.price-point-min', { min: 0 }) },
                    max: {
                      value: MAX_QUANTITY,
                      message: t('validation.max', { field: 'Quantity', value: MAX_QUANTITY }),
                    },
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseFloat(value)
                    },
                  })}
                  {...formatHookFormErrors(errors, 'fallback_item_quantity')}
                />
              </FieldGroup>
            ) : (
              <div className="w-1/3" />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </FormSection>
  )
}

import DashboardCard from '../../../components/shared/DashboardCard'
import { TableZeroState } from '@/ui'
import { LoaderContainer } from '../../dashboard/components/Loader/LoaderContainer'
import Loader from '../../dashboard/components/Loader/Loader'
import { usePayoutsByMonthQuery } from '@/layouts/payouts/api'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getFormatYearMonthParams } from '@/libs/dates'
import dayjs from 'dayjs'
import { formatMoney } from '@/util'

export const PayoutsChart = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const { data = [], isLoading } = usePayoutsByMonthQuery(companyId, gameId)

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  if (data.length == 0) {
    return (
      <TableZeroState
        className="w-full"
        title={t('payouts.empty-chart.title')}
        message={t('payouts.empty-chart.desc')}
      />
    )
  }

  const hasData = data.some(it => it.total > 0)

  // @ts-ignore
  const options = {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: '#fff',
      type: 'column',
      height: '229px',
    },
    tooltip: {
      enabled: !isLoading,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderColor: 'transparent',
      borderRadius: 6,
      padding: 10,
      shared: true,
      style: {
        color: '#fff',
      },
      useHTML: true,
      formatter: function () {
        // @ts-ignore
        const categoryName = this.key
        // @ts-ignore
        const total = formatMoney(this.y * 100)
        return `<div>
            <div class="text-caption-md border-b pb-2 min-w-[100px]" style="border-color: rgba(255, 255, 255, 0.2)">${categoryName}</div><br/>
            <div class="text-caption-sm mt-[-6px] flex flex-col gap-2">
              <div class="flex items-center gap-1.5"</div>${t('payout.total')}<div class="ml-auto">${total}</div></div>
            </div>
           </div>
         `
      } as unknown,
    },
    legend: {
      enabled: false,
    },
    xAxis: [
      {
        categories: data.map(it => {
          const d = new Date()
          const end = dayjs(new Date(it.ending_date / 1000)).add(d.getTimezoneOffset(), 'minute')
          return t('intl.DateTime', getFormatYearMonthParams(end.toDate().getTime() / 1000))
        }),
        labels: {
          rotation: 0,
          style: {
            fontSize: '10px',
            color: '#64748B',
            fontWeight: '400',
            fontFamily: 'InterAg',
          },
        },
        lineColor: '#fff',
        gridLineWidth: 1,
        gridLineColor: '#F1F5F9',
        gridLineDashStyle: 'Dash',
      },
    ],
    yAxis: {
      visible: false,
      min: 0,
      max: hasData ? undefined : 100,
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
        borderRadius: 6,
        dataLabels: {
          enabled: true,
          formatter: function (): string {
            // @ts-ignore
            return formatMoney(this.y * 100)
          },
          style: {
            fontSize: '10px',
            color: '#000',
            fontWeight: '500',
            fontFamily: 'InterAg',
          },
        },
      },
    },
    series: [
      {
        name: 'main',
        data: data.map(it => ({
          y: it.total / 100,
          color: it.status == 'paid' ? '#22C55E' : '#d4dde9',
        })),
      },
    ],
  }

  return (
    <DashboardCard title={t('sidebar.payouts')} style={{ height: '289px', flexGrow: 1, gap: '8px' }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </DashboardCard>
  )
}

import { CampaignEventType } from '../../api/dashboard'
import { EventActionNodeType } from './types'

export const ROOT_NODE_ID = 'root'

export const CONDITION_NODE_SWITCH_SIZE = 100

export const DEFAULT_HEIGHT_BEETWEEN_NODES = 215

export const ADD_NEXT_PREFIX_ID = 'add_next_'
export const ADD_NEXT_FALSE_PREFIX_ID = 'cond_false'
export const ADD_NEXT_TRUE_PREFIX_ID = 'cond_true'

export function isManualLaunchCampaign(type: CampaignEventType | string): boolean {
  switch (type) {
    case CampaignEventType.GameHubVisit:
    case CampaignEventType.GameHubVisitStore:
    case CampaignEventType.GameHubFirstLogin:
    case CampaignEventType.GameHubLogin:
    case CampaignEventType.AddSegment:
    case CampaignEventType.AbandonedCart:
    case CampaignEventType.CouponRedeemed:
    case CampaignEventType.PurchasedInWeb:
    case CampaignEventType.DailyRewardClaimed:
      return false
  }

  return true
}

export function isCustomEvent(type?: CampaignEventType | string): boolean {
  if (!type) {
    return false
  }

  if (type == CampaignEventType.CustomEvent) {
    return true
  }

  return !Object.values(CampaignEventType).includes(type as CampaignEventType)
}

export function hasMoreOneChildren(type: EventActionNodeType): boolean {
  switch (type) {
    case EventActionNodeType.ConditionNode:
    case EventActionNodeType.SplitNode:
      return true
  }
  return false
}

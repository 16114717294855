import { forwardRef } from 'react';
import { MenuItem, MenuItemProps } from './MenuItem';
import { Checkbox } from '../Checkbox';
import { renderItemIcon } from './renderItemIcon';

interface MenuItemCheckboxProps extends MenuItemProps {
  /**
   * Checkbox checked state.
   */
  checked: boolean;
}

export const MenuItemCheckbox = forwardRef<HTMLLIElement, MenuItemCheckboxProps>(({ checked, icon, ...rest }, ref) => {
  return (
    <MenuItem
      {...rest}
      ref={ref}
      className="group/checkbox-inner"
      icon={() => (
        <div className="pointer-events-none mr-4 flex items-center gap-2">
          <Checkbox tabIndex={-1} checked={checked} onChange={() => void 0} />
          {icon && renderItemIcon(icon, 18)}
        </div>
      )}
    />
  );
});

import { useNavigate, useParams } from 'react-router-dom'
import { Button, Spinner, useSearchFilter } from '@dashboard/ui'
import {
  Badge,
  Page,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TooltipAlertCircle,
  generatePathWithFilter,
  usePagination,
} from '@/ui'
import { Query, useCouponsQuery } from './api'
import { PlayerTableRowSkeleton } from '../player/components'
import { CouponRead, CouponType } from '@/api/dashboard'
import CouponBaseSettings from './components/CouponBaseSettings'
import { SKU_CODES_DETAILS_PATH, SKU_CODES_PATH } from '@/libs'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { CouponToolbar } from '@/layouts/coupon/widgets'
import { CouponFilter } from '@/layouts/coupon/types'
import { useCurrentUser } from '@/api/useCurrentUser'
import { TableZeroStateFilter, hasZeroState } from '@/layouts/components/TableZeroStateFilter'
import { useTranslation } from 'react-i18next'
import { couponTotalStatQuery } from '@/layouts/coupon/api/couponStatQuery'
import { useQuery } from '@tanstack/react-query'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { formatNumber } from '@/util'

export default function CouponTable() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { companyId, gameId, codeId } = useParams() as { companyId: string; gameId: string; codeId: string }
  const { page, query, onPageChange, needShowPagination } = usePagination(15)

  const filter = useSearchFilter<CouponFilter>()
  const hasFilter = !!(filter.values.q || filter.values.type)

  const { data: coupons = [], isLoading } = useCouponsQuery(companyId, gameId, {
    ...query,
    ...filter.values,
  } as Query)

  const { canEdit } = useCurrentUser()

  const { data: totalStatData, isLoading: isStatLoading } = useQuery(
    couponTotalStatQuery(
      companyId,
      gameId,
      coupons.map(it => it.id),
    ),
  )

  const getBenefits = (it: CouponRead) => {
    switch (it.type) {
      case CouponType.FreeItem:
        return it.item?.name
      case CouponType.Discount:
        return it.discount_percent + '%'
      case CouponType.Bonus:
        return it.bonus_percent + '%'
    }
    return 'N/A'
  }

  const onCloseSettings = () => {
    navigate(generatePathWithFilter(SKU_CODES_PATH, { companyId, gameId }, filter.values as Record<string, string>))
  }

  const renderZeroState = () => {
    if (isLoading || !hasZeroState(coupons, page, hasFilter)) {
      return null
    }

    return (
      <TableZeroStateFilter
        page={page}
        items={coupons}
        hasFilter={hasFilter}
        createClick={() => {
          navigate(
            generatePathWithFilter(
              SKU_CODES_DETAILS_PATH,
              { companyId, gameId, codeId: 'new' },
              filter.values as Record<string, string>,
            ),
          )
        }}
        zeroStateMessage={t('coupon.zero.title')}
        zeroStateSubMessage={t('coupon.zero.message')}
        addButtonText={t('coupon.zero.button-add')}
        notFoundMessage={t('coupon.search.zero.text')}
        clearFilterClick={filter.onReset}
      />
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          canEdit &&
          !!coupons.length && (
            <Button
              size="sm"
              onClick={() => {
                navigate(
                  generatePathWithFilter(
                    SKU_CODES_DETAILS_PATH,
                    { companyId, gameId, codeId: 'new' },
                    filter.values as Record<string, string>,
                  ),
                )
              }}
            >
              {t('coupon.add')}
            </Button>
          )
        }
      >
        {t('coupon.codes')}
      </PageHeader>

      <DrawerSidebar open={!!codeId} width="864px" position="right">
        {codeId ? (
          <CouponBaseSettings onClose={onCloseSettings} />
        ) : (
          <div className="flex size-full items-center justify-center text-text-quarterary">
            <Spinner />
          </div>
        )}
      </DrawerSidebar>

      {(coupons.length > 0 || hasFilter) && <CouponToolbar filter={filter} />}

      {isLoading
        ? new Array(query.limit).fill('').map((_, index) => <PlayerTableRowSkeleton key={`skeleton-${index}`} />)
        : renderZeroState() || (
            <Table>
              <TableRow variant="header">
                <TableCell width="300%">{t('coupon.name')}</TableCell>
                <TableCell>{t('coupon.code')}</TableCell>
                <TableCell>{t('coupon.type')}</TableCell>
                <TableCell>{t('coupon.benefits')}</TableCell>
                <TableCell className="flex items-center gap-1">
                  {t('coupon.redeem-count')}
                  <TooltipAlertCircle message={t('coupon.update-info')} />
                </TableCell>
              </TableRow>
              {coupons.map(it => (
                <TableRow
                  key={it.id}
                  to={
                    canEdit && !codeId
                      ? generatePathWithFilter(
                          SKU_CODES_DETAILS_PATH,
                          { companyId, gameId, codeId: it.id },
                          filter.values as Record<string, string>,
                        )
                      : undefined
                  }
                >
                  <TableCell width="300%">{it.name}</TableCell>
                  <TableCell>
                    <Badge variant="gray-tertiary">{it.code}</Badge>
                  </TableCell>
                  <TableCell>{t(`coupon.type.${it.type}`)}</TableCell>
                  <TableCell>{getBenefits(it)}</TableCell>
                  <TableCell>
                    {isStatLoading ? (
                      <AnimatedDots />
                    ) : (
                      formatNumber(totalStatData?.find(point => point.category == it.id)?.value || 0)
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}

      {needShowPagination(isLoading, coupons) && (
        <Pagination
          hasNext={coupons.length >= query.limit}
          page={page}
          onPageChange={onPageChange}
          pageItems={coupons.length}
        />
      )}
    </Page>
  )
}

import { ComponentProps, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Control, Controller, FieldPath, useFormContext } from 'react-hook-form'

import { FeaturedPageBlock, PageLayoutType } from '@/api/dashboard'
import { FieldGroup, Input, InputExtraSection, Select, Tab, Tabs } from '@/ui'
import { usePagesQuery } from '@/layouts/pages/api/usePagesQuery'
import { Checkbox } from '@dashboard/ui'

const PERIOD_MIN = 1
const PERIOD_MAX = 30
const DAY_SECONDS = 24 * 60 * 60

const ControlledSelect = (
  props: {
    name: FieldPath<Pick<FeaturedPageBlock, 'page_id'>>
    control: Control<FeaturedPageBlock>
    required?: boolean
  } & Omit<ComponentProps<typeof Select>, 'onChange'>,
) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.required && t('validation.required') }}
      render={({ field }) => (
        <Select
          {...field}
          errors={props.errors}
          placeholder={props.placeholder}
          disabled={props.disabled}
          options={props.options}
        />
      )}
    />
  )
}

const SelectPagePanel = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }

  const { data: pages = [] } = usePagesQuery(companyId, gameId, {
    ignore_parent_id: true,
    limit: 100,
    types: [PageLayoutType.Article, PageLayoutType.Content],
  })

  const { data: categories = [] } = usePagesQuery(companyId, gameId, {
    ignore_parent_id: true,
    limit: 100,
    types: [PageLayoutType.Collection],
  })

  const {
    watch,
    register,
    control,
    setValue,
    formState: { isSubmitting, errors },
  } = useFormContext<FeaturedPageBlock>()

  const isCollection = watch('is_collection')
  const period = watch('rotation_period')
  const [dayPeriod, setDayPeriod] = useState(period ? period / DAY_SECONDS : '')

  useEffect(() => {
    if (period) {
      setDayPeriod(period / DAY_SECONDS)
    }
  }, [period])

  const handlePeriodChange = (v: string) => {
    setDayPeriod(v)
    const seconds = Number(v) * DAY_SECONDS
    setValue('rotation_period', seconds, { shouldDirty: true })
  }

  return (
    <FieldGroup label={t('hub-editor.block-page')}>
      <Tabs>
        <Tab
          isActive={!isCollection}
          onClick={() => {
            setValue('is_collection', false)
            setValue('page_id', null as unknown as string)
            setValue('rotation_period', null as unknown as number)
          }}
        >
          {t('hub-editor.block-page.one')}
        </Tab>
        <Tab
          isActive={!!isCollection}
          onClick={() => {
            setValue('is_collection', true)
            setValue('page_id', null as unknown as string)
          }}
        >
          {t('hub-editor.block-page.rotation')}
        </Tab>
      </Tabs>

      {!isCollection ? (
        <ControlledSelect
          name={'page_id'}
          control={control}
          errors={errors}
          required
          disabled={isSubmitting}
          options={pages.map(it => ({ value: it.id, children: it.title || it.description }))}
        />
      ) : (
        <div className="inline-flex w-full gap-1">
          <div className="w-3/5">
            <ControlledSelect
              name={'page_id'}
              control={control}
              errors={errors}
              required
              placeholder={t('hub-editor.block-page.rotation.category')}
              disabled={categories.length === 0 || isSubmitting}
              options={categories.map(it => ({ value: it.id, children: it.title }))}
            />
          </div>
          <div className="w-2/5">
            <Controller
              name="rotation_period"
              control={control}
              rules={{
                required: t('validation.required'),
                min: {
                  value: PERIOD_MIN * DAY_SECONDS,
                  message: t('hub-editor.block-page.rotation.period.validation', {
                    min: PERIOD_MIN,
                    max: PERIOD_MAX,
                  }),
                },
                max: {
                  value: PERIOD_MAX * DAY_SECONDS,
                  message: t('hub-editor.block-page.rotation.period.validation', {
                    min: PERIOD_MIN,
                    max: PERIOD_MAX,
                  }),
                },
              }}
              render={({ field: { name } }) => (
                <>
                  <Input
                    name={name}
                    value={dayPeriod}
                    onChange={e => handlePeriodChange(e.target.value)}
                    errors={errors}
                    type="number"
                    disabled={isSubmitting}
                    extraRight={
                      <InputExtraSection side="right">
                        <span className="text-paragraph-md">{t('hub-editor.block-page.rotation.period.suffix')}</span>
                      </InputExtraSection>
                    }
                  />
                </>
              )}
            />
          </div>
        </div>
      )}
      <Checkbox className="mt-2" {...register('hide_date')}>
        {t('hub-editor.block-page.hide_date')}
      </Checkbox>
    </FieldGroup>
  )
}

export default SelectPagePanel

import { useContext } from 'react'
import { SURFACE_SURFACE_BG_STROKE } from '../../Settings'
import { BackIcon, TrashIcon } from '../../icons/Icons'
import getCampaignName from './pages/CampaignTable/getCampaignName'
import { Badge, ButtonIcon, ModalConfirm, ToastSeverity, Tooltip, TooltipAlertCircle, useModal, useToast } from '@/ui'
import { CampaignContext, ICampaignContext } from './Context'
import { BarChart, Code02, Settings } from '@/icons'
import { dashboardClient, getErrorText } from '@/api'
import { CampaignEventType } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { campaignTemplatesQuery } from '@/layouts/campaigns/api/campaignTemplatesQuery'
import { DataFlow } from '@/components/icons/DataFlow'
import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'

const CampaignEditorHeader = (props: {
  disabledSave: boolean
  saveClick: () => void
  onOpenSettings: () => void
  onOpenHistory: () => void
  onOpenAnalytics: () => void
  onDeleteClick: () => void | null
  onBackClick: () => void
  isLive: boolean
  publishChangeClick: () => void
}) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const context = useContext(CampaignContext) as ICampaignContext
  const { isSuperAdmin } = useCurrentUser()
  const showToast = useToast()
  const { data: templates = [], refetch } = useQuery(campaignTemplatesQuery(companyId, gameId))

  const openConfirmSaveAsTemplateModal = useModal(props => (
    <ModalConfirm
      color="primary"
      subMessage={t('campaign.confirm.save-as-template')}
      confirmButtonText={t('yes')}
      cancelButtonText={t('no')}
      onConfirm={saveAsTemplate}
      showClose={true}
      {...props}
    />
  ))

  const openConfirmPublish = useModal(p => (
    <ModalConfirm
      color="primary"
      message={t(props.isLive ? 'campaign.confirm.unpublish' : 'campaign.confirm.publish')}
      subMessage={t(props.isLive ? 'campaign.confirm.unpublish.message' : 'campaign.confirm.publish.message')}
      confirmButtonText={t(props.isLive ? 'unpublish' : 'publish')}
      onConfirm={props.publishChangeClick}
      showClose={true}
      {...p}
    />
  ))

  const saveAsTemplate = async () => {
    const { campaign, graph, viewPort } = context
    graph.view_port = viewPort
    try {
      await dashboardClient.v1.createOrUpdateCampaignTemplate(companyId, gameId, {
        graph: graph,
        type: campaign?.type as CampaignEventType,
        source_campaign_id: campaign.id,
      })
      showToast({ message: t('saved'), severity: ToastSeverity.success })
      refetch()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  return (
    <div
      className="flex h-[62px] w-full items-center justify-between border border-b-border-primary bg-fg-primary"
      style={{
        minWidth: '800px',
      }}
    >
      <div className="flex items-center gap-4 p-4">
        <Button onClick={props.onBackClick} variant="outline" color="secondary">
          <BackIcon />
          {t('Back')}
        </Button>

        <div
          style={{
            backgroundColor: SURFACE_SURFACE_BG_STROKE,
            height: '16px',
            width: '1px',
          }}
        />

        {context.campaign && (
          <span className="text-sm font-medium text-text-primary">{getCampaignName(context.campaign.type)}</span>
        )}

        {isSuperAdmin && templates.find(it => it.source_campaign_id == context.campaign?.id) && (
          <Badge variant="gray-primary">
            Template <TooltipAlertCircle message={t('campaign.template-source')} />
          </Badge>
        )}

        {props.isLive && <Badge variant="green-primary">Live</Badge>}
      </div>

      <div className="flex h-full items-center justify-end gap-2 pr-3">
        {isSuperAdmin && context.campaign?.id && (
          <Tooltip message={t('campaign.header.save-template')}>
            <ButtonIcon onClick={() => openConfirmSaveAsTemplateModal()} variant="secondary-gray" size="sm">
              <DataFlow size={18} />
            </ButtonIcon>
          </Tooltip>
        )}
        {!context.readOnly && (
          <>
            {props.onDeleteClick && (
              <Tooltip message={t('campaign.header.delete-edge')}>
                <ButtonIcon onClick={props.onDeleteClick} variant="secondary-gray" size="sm">
                  <TrashIcon />
                </ButtonIcon>
              </Tooltip>
            )}

            <Tooltip message={t('campaign.header.analytics')}>
              <ButtonIcon onClick={props.onOpenAnalytics} variant="secondary-gray" size="sm">
                <BarChart />
              </ButtonIcon>
            </Tooltip>

            <Tooltip message={t('campaign.header.logs')}>
              <ButtonIcon onClick={props.onOpenHistory} variant="secondary-gray" size="sm">
                <Code02 />
              </ButtonIcon>
            </Tooltip>
          </>
        )}

        {!context.readOnly && (
          <Tooltip message={t('campaign.header.settings')}>
            <ButtonIcon onClick={props.onOpenSettings} variant="secondary-gray" size="sm">
              <Settings />
            </ButtonIcon>
          </Tooltip>
        )}
        {!context.readOnly && (
          <>
            {!context.campaign?.archived_at && (
              <Button variant={'outline'} color="primary" onClick={() => openConfirmPublish()}>
                {t(props.isLive ? 'unpublish' : 'publish')}
              </Button>
            )}
            <Button onClick={props.saveClick} disabled={props.disabledSave}>
              {t('Save2')}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default CampaignEditorHeader

import { PaymentRead } from '@/api/dashboard'
import PayMethodView from '../payment-settings/components/PayMethodView'
import { usePaymentMethods } from '@/api/usePaymentMethods'
import { useParams } from 'react-router-dom'
import { REWARD_POINTS_PAYMENT_METHOD_ID } from '@/pay-types'
import { RewardPointPaymentMethod, cn } from '@dashboard/ui'

export default function PayCardView({ payment }: { payment: PaymentRead }) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data } = usePaymentMethods(companyId, gameId)

  const paymentMethod = data?.find(it => it.id === payment.payment_method_id)

  if (payment.payment_method_id == REWARD_POINTS_PAYMENT_METHOD_ID) {
    return (
      <div className={cn('flex items-center gap-3')} data-testid={'pay-method-rp'}>
        <RewardPointPaymentMethod />
        Reward Points
      </div>
    )
  }

  return (
    <PayMethodView
      isTransaction={true}
      paymentMethod={paymentMethod}
      card_scheme={payment.card_scheme}
      card_last_4_digits={payment.card_last_4_digits}
    />
  )
}

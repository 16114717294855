import { ItemRead } from '@/api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { getItemName } from '@/api/getItemName'

interface SkuImageNameProps {
  item: ItemRead
  hideQuantity?: boolean
}

export const SkuImageName = ({ item, hideQuantity }: SkuImageNameProps) => {
  return (
    <div className="mr-auto flex items-center gap-3">
      <div
        className="size-8 shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
        }}
      />
      <div className="overflow-hidden">
        <div className="truncate text-sm font-medium leading-[16px]">
          {hideQuantity ? item.name : getItemName(item)}
        </div>
        <div className="truncate text-xs font-medium leading-[16px] text-text-tertiary-hover">{item.sku}</div>
      </div>
    </div>
  )
}

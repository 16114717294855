import { FunctionComponent, MouseEvent, ReactNode, createElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps } from 'react-router-dom'
import clsx from 'clsx'
import { Tooltip } from '@/ui'
import { Eye } from '@/icons'
import { SidebarBadge } from './SidebarBadge'
import { SidebarLinkBadge, SidebarLinkIconProps } from './types'

export interface SidebarLinkProps extends NavLinkProps {
  children: ReactNode
  icon: FunctionComponent<SidebarLinkIconProps>
  badge?: SidebarLinkBadge
  disabled?: boolean
  externalLink?: {
    tooltip: ReactNode
    href: string | null
    icon?: ReactNode
  }
  secondaryAction?: ReactNode
  hideArrow?: boolean
  className?: string
}

export const SidebarLink = ({
  children,
  icon,
  badge,
  externalLink,
  className,
  secondaryAction,
  hideArrow = false,
  to,
  ...rest
}: SidebarLinkProps) => {
  const { t } = useTranslation()
  const onClickExternalLink = (e: MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <NavLink
      {...rest}
      to={to}
      aria-disabled={rest.disabled ? 'true' : 'false'}
      onClick={to ? rest.onClick : e => e.preventDefault()}
      className="group flex items-center px-3 py-0.5 font-medium text-text-secondary hover:text-text-secondary-hover"
    >
      <div
        className={clsx(
          "flex h-8 w-full items-center gap-2 rounded-lg px-1 pr-2 transition-colors group-[.active]:text-text-primary group-[[data-open='true']]:text-text-primary",
          rest.disabled ? 'opacity-50' : "group-hover:bg-white group-[[data-open='false'].active]:bg-white",
          className,
        )}
      >
        <div className="relative flex w-6 shrink-0 items-center justify-center">
          {createElement(icon, {
            className:
              "absolute visible group-hover:invisible group-[[data-open='true']]:invisible group-[.active]:invisible",
            variant: 'default',
          })}
          {createElement(icon, {
            className:
              "absolute invisible group-hover:visible group-[[data-open='true']]:visible group-[.active]:invisible",
            variant: 'fill',
          })}
          {createElement(icon, {
            className: 'absolute invisible group-[.active]:visible',
            variant: 'duotone',
          })}
        </div>

        <div>{children}</div>

        <div className="ml-auto flex shrink-0 items-center gap-1">
          {badge === 'new' && <SidebarBadge>{t('sidebar.badge.new')}</SidebarBadge>}

          {externalLink && (
            <Tooltip message={externalLink.tooltip} options={{ placement: 'top' }}>
              {externalLink.href ? (
                <a
                  data-testid="side-bar-external-link"
                  className="flex size-6 items-center justify-center rounded-md opacity-0 transition-all hover:bg-fg-primary-hover group-hover:text-text-tertiary group-hover:opacity-100 group-[[data-open='true']]:opacity-100 [&[data-open-tooltip='true']]:opacity-100"
                  href={externalLink.href}
                  target="_blank"
                  onClick={onClickExternalLink}
                >
                  {<Eye className="size-[18px] text-text-tertiary" />}
                </a>
              ) : (
                <div className="flex size-6 items-center justify-center rounded-md transition-all hover:bg-fg-primary-hover group-hover:text-text-tertiary">
                  {externalLink.icon}
                </div>
              )}
            </Tooltip>
          )}

          {secondaryAction && <div className="ml-auto flex size-6 items-center justify-center">{secondaryAction}</div>}

          {rest.onClick && !hideArrow && (
            <svg
              className="text-text-quarterary transition-transform group-hover:text-text-tertiary group-[[data-open='true']]:rotate-180 group-[:not([data-open='true']).active]:text-text-primary group-[[data-open='true']]:text-text-brand-primary"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 6.75L9 11.25L13.5 6.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
    </NavLink>
  )
}

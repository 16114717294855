import { ItemRead, ItemType } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { getItemName } from '@/api/getItemName'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PriceView } from '@/layouts/game-items/components/PriceView'
import { dashboardClient } from '@/api'
import { InputSearch, Select, SelectItem, SelectProps, cn } from '@dashboard/ui'
import { useDebounce } from '@/hooks'

import { useInfiniteGameItems } from '@/api/useInfiniteGameItems'
import { CONTEXT_ITEM } from '@/layouts/campaigns/editors/common'
import { useInView } from 'framer-motion'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'

// @ts-ignore
interface SelectSkuProps extends Omit<SelectProps, 'items'> {
  types?: ItemType[] | undefined
  options?: SelectItem[]
  label?: string
  error?: string
  value: string | undefined | null
  onChange: (value: SelectItem['value'], item?: ItemRead | undefined) => void
  contextItemText?: string
  hideIds?: string[]
  hidePrice?: boolean
  isStackable?: boolean
  hideSelectedQuantity?: boolean
}

const InfinityObserver = ({ loading, fetchNextPage }: { loading: boolean; fetchNextPage: () => void }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isInView = useInView(ref)

  useEffect(() => {
    if (isInView && !loading) {
      fetchNextPage()
    }
  }, [isInView, loading, fetchNextPage])

  return (
    <div ref={ref}>
      <AnimatedDots />
    </div>
  )
}

export const SelectSkuItemV2 = (props: SelectSkuProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [additionalItems, setAdditionalItems] = useState<ItemRead[]>([])
  const [valueItem, setValueItem] = useState<ItemRead | undefined>(undefined)

  const {
    items: allItems,
    fetchNextPage,
    isFetching,
    isLoading,
    hasNext,
    refetch,
  } = useInfiniteGameItems(debouncedSearch, props.types, props.isStackable)

  const loadValueItems = useCallback(async () => {
    if (!props.value || props.value == CONTEXT_ITEM) {
      return
    }

    if (valueItem?.id == props.value) {
      return
    }

    const requireItem = allItems.find(it => it.id == props.value)

    if (requireItem) {
      setValueItem(requireItem)
      return
    }
    const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
      ids: props.value,
    })

    if (data.length) {
      setAdditionalItems(data)
      setValueItem(data.find(it => it.id == props.value))
    }
  }, [props.value, allItems])

  useEffect(() => {
    loadValueItems()
  }, [props.value])

  useEffect(() => {
    refetch()
  }, [refetch, debouncedSearch])

  const createImage = (item: ItemRead | undefined, cls: string) => {
    return (
      <div
        className={cn(`shrink-0 rounded-md bg-cover bg-center bg-no-repeat`, cls)}
        style={{
          backgroundImage: `url(${item?.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
        }}
      />
    )
  }

  const createItem = (it: ItemRead) => {
    let price: ReactNode = props.hidePrice ? (
      <></>
    ) : (
      <div className="relative top-2.5">
        <PriceView item={it} />
      </div>
    )

    return {
      value: it.id as string,
      icon: () => createImage(it, 'size-[36px] mr-3'),
      caption: <span>{it.sku}</span>,
      extra: () => price,
      children: getItemName(it),
    }
  }

  const createItems = useCallback(() => {
    let visibleItems = allItems

    if (props.hideIds) {
      visibleItems = visibleItems.filter(it => !props.hideIds || !props.hideIds.includes(it.id))
    }

    if (additionalItems.length) {
      for (let additionalItem of additionalItems) {
        if (!visibleItems.find(it => it.id === additionalItem.id)) {
          visibleItems.push(additionalItem)
        }
      }
    }
    let options = visibleItems.map(it => createItem(it)) as SelectItem[]

    if (props.contextItemText) {
      options.unshift({
        value: CONTEXT_ITEM,
        icon: () =>
          createImage(
            {
              image_url: '/icons/item-placeholder.webp',
            } as ItemRead,
            'size-[36px] mr-3',
          ),
        extra: () => <></>,
        children: <div className="relative top-2">{props.contextItemText}</div>,
      })
    }

    if (hasNext) {
      options.push({
        value: '',
        icon: () => <></>,
        extra: () => <></>,
        children: <InfinityObserver loading={isFetching || isLoading} fetchNextPage={() => fetchNextPage()} />,
      })
    }

    if (valueItem && !options.find(it => it.value === valueItem.id)) {
      options.push({
        ...createItem(valueItem),
        hidden: true,
      })
    }

    return options
  }, [allItems, props.hideIds, additionalItems, props.contextItemText, valueItem, isFetching, isLoading, hasNext])

  const header = (
    <div className="-mx-2 mb-2.5 border-b border-border-secondary px-2.5 pb-3">
      <InputSearch
        value={search}
        onFocus={e => {
          e.stopPropagation()
        }}
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        placeholder={t('select-sku.search.place-holder')}
      />
    </div>
  )

  const items = createItems()

  return (
    <Select
      {...(props as SelectProps)}
      items={items}
      header={header}
      placeholder={t('select-skus')}
      onChange={v => {
        const pickedItem = allItems.find(it => it.id === v)
        setValueItem(pickedItem)
        props.onChange(v as string, pickedItem)
      }}
      renderValueFn={({ item }) => {
        let sku = allItems.find(it => item.value == it.id)
        if (!sku && valueItem?.id == item.value) {
          sku = valueItem
        }
        return (
          <div className="-mx-2 flex w-full items-center gap-1.5">
            {createImage(sku, 'size-[28px]')}
            <div className="truncate  text-caption-md font-normal leading-[18px] text-text-primary">
              {item.value == CONTEXT_ITEM ? props.contextItemText : item.children}
            </div>
            {sku && !props.hidePrice && <div className="-mr-4 ml-auto font-normal">{<PriceView item={sku} />}</div>}
          </div>
        )
      }}
    />
  )
}

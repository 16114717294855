import { Button, Modal, ModalBaseProps } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { AcceptIcon } from '@/layouts/payouts/widgets/AcceptIcon'
import { formatMoney } from '@/util'

export interface PayoutResultModalProps extends ModalBaseProps {
  total: number
}

export const PayoutResultModal = ({ total, ...rest }: PayoutResultModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal {...rest} size="md">
      <div
        className="relative bg-cover p-6"
        style={{
          background: 'url(/public/icons/payout_accept_background.svg) repeat-x',
        }}
      >
        <div className="mt-6 flex flex-col items-center">
          <AcceptIcon />
          <div className="mt-[18px] text-[20px] font-semibold">{t('payouts.accepted')}</div>
          <div className="mt-1.5 text-center text-caption-md font-normal text-text-secondary">
            <div>{t('payouts.accepted.text1')}</div>
            <div>{t('payouts.accepted.text2')}</div>
          </div>
        </div>

        <div className="mt-[18px] rounded-xl bg-fg-secondary p-[18px] font-[Menlo] font-normal text-text-tertiary">
          <div className="flex items-center justify-between">
            <span>{t('payout.amount')}</span>
            <span>{formatMoney(total)}</span>
          </div>
          <div className="my-2 border-t border-dashed border-border-secondary-hover" />
          <div className="flex items-center justify-between">
            <span>{t('payouts.table.status')}</span>
            <span className="flex text-caption-sm uppercase text-text-success-secondary">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.1785 5.40085C11.4228 5.09537 11.3733 4.64963 11.0678 4.40524C10.7624 4.16086 10.3166 4.21039 10.0722 4.51587L7.94724 7.17212C7.70285 7.47759 7.75238 7.92334 8.05786 8.16772C8.36334 8.41211 8.80909 8.36258 9.05347 8.0571L11.1785 5.40085Z"
                  fill="#22C55E"
                />
                <path
                  d="M2.67847 8.05753C2.43409 7.75206 1.98834 7.70253 1.68286 7.94691C1.37738 8.19129 1.32785 8.63704 1.57224 8.94252L4.12901 12.1385C4.55436 12.6702 5.36301 12.6702 5.78836 12.1385L7.08368 10.5193L8.37901 12.1385C8.80436 12.6702 9.61301 12.6702 10.0384 12.1385L15.4285 5.40085C15.6728 5.09537 15.6233 4.64963 15.3178 4.40524C15.0124 4.16086 14.5666 4.21039 14.3222 4.51587L9.20868 10.9078L7.6464 8.95495C7.64045 8.94718 7.63432 8.93952 7.62802 8.93197L6.92847 8.05753C6.68408 7.75206 6.23834 7.70253 5.93286 7.94691C5.62738 8.19129 5.57785 8.63704 5.82224 8.94252L6.17658 9.38544L4.95868 10.9078L2.67847 8.05753Z"
                  fill="#22C55E"
                />
              </svg>
              {t('payouts.table.success')}
            </span>
          </div>
        </div>

        <div className="mt-[18px] flex items-center justify-end">
          <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
            {t('payouts.close-message')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

type QueryAllPlugins = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number

  search_string?: string
}

export const useAllPluginQuery = (companyId: string, gameId: string, query: QueryAllPlugins = {}) => {
  return useQuery({
    queryKey: ['plugins', gameId, query],
    queryFn: () => dashboardClient.v1.getAllPlugins(companyId, gameId, query),
    select: response => response.data,
  })
}

export const usePluginQuery = (companyId: string, gameId: string, slug: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['plugin', gameId],
    queryFn: () => dashboardClient.v1.getPluginBySlug(companyId, gameId, slug),
    select: response => response.data,
  })
}

type Query = {
  /** Enabled */
  enabled?: boolean
}

export const usePluginInstancesQuery = (companyId: string, gameId: string, query = {} as Query) => {
  return useQuery({
    queryKey: ['plugins_instance', gameId, query],
    queryFn: () => dashboardClient.v1.getPluginsByState(companyId, gameId, query),
    select: response => response.data,
  })
}

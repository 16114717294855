import { Controller, useFormContext } from 'react-hook-form'
import { CreateUserStoreSettingsNode, CreateVirtualSKUNode, StoreItemRead, StorePromotionRead } from '@/api/dashboard'
import { ErrorMessage } from '@hookform/error-message'
import { FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import { FieldGroup, FieldSection, InputExtraMark } from '@/ui'
import { MAX_QUANTITY } from '@/Settings'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gameItemsQueryOptions } from '@/api/useGameItems'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'

export const BonusItemBenefitEditor = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [bonusItemChecked, setBonusItemChecked] = useState<boolean>()
  const [isFixedBonusItemQuantity, setIsFixedBonusItemQuantity] = useState<boolean>()

  const {
    formState: { errors },
    register,
    control,
    setValue,
    watch,
  } = useFormContext<StoreItemRead | StorePromotionRead | CreateVirtualSKUNode | CreateUserStoreSettingsNode>()

  const [bonus_item_id, bonus_item_quantity, id] = watch(['bonus_item_id', 'bonus_item_quantity', 'id'])

  const { data: items = [] } = useQuery(
    gameItemsQueryOptions(companyId, gameId, {
      ids: bonus_item_id,
    }),
  )

  useEffect(() => {
    setBonusItemChecked(!!bonus_item_id)
    setIsFixedBonusItemQuantity(!!bonus_item_quantity)
  }, [id])

  const selectedBonusItem = items?.find(i => i.id === bonus_item_id)

  const clearQuantityValues = () => {
    // @ts-ignore
    setValue('bonus_item_percent', null as unknown as undefined, { shouldDirty: true })
    setValue('bonus_item_quantity', null as unknown as undefined, { shouldDirty: true })
  }

  const renderBonusQuantity = () => {
    return (
      <>
        <FieldGroup label={t('store-promotions.bonus_quantity_type')}>
          <Select
            items={[
              {
                children: t('store-promotions.bonus_quantity_type.percent'),
                caption: t('store-promotions.bonus_quantity_type.percent.caption'),
                value: 'percent',
              },
              {
                children: t('store-promotions.bonus_quantity_type.fixed'),
                caption: t('store-promotions.bonus_quantity_type.fixed.caption'),
                value: 'fixed',
              },
            ]}
            value={isFixedBonusItemQuantity ? 'fixed' : 'percent'}
            onChange={v => {
              setIsFixedBonusItemQuantity(v === 'fixed')
              if (v === 'percent') {
                // @ts-ignore
                setValue('bonus_item_quantity', null as unknown as undefined, { shouldDirty: true })
              } else {
                setValue('bonus_item_percent', null as unknown as undefined, { shouldDirty: true })
              }
            }}
          />
        </FieldGroup>

        {isFixedBonusItemQuantity ? (
          <FieldGroup label={t('store-promotions.bonus_quantity_type.quantity')}>
            <Input
              type="number"
              {...register('bonus_item_quantity', {
                required: bonusItemChecked ? t('validation.required') : false,
                min: {
                  value: 1,
                  message: t('validation.min', {
                    field: t('store-promotions.bonus_quantity_type.quantity'),
                    value: 1,
                  }),
                },
                max: {
                  value: MAX_QUANTITY,
                  message: t('validation.max', {
                    field: t('game-item-edit-dialog.quantity'),
                    value: MAX_QUANTITY,
                  }),
                },
              })}
              {...formatHookFormErrors(errors, 'bonus_item_quantity')}
            />
          </FieldGroup>
        ) : (
          <FieldGroup
            label={t('store-promotions.bonus-currency-percent')}
            caption={t('store-promotions.bonus-currency-percent.caption')}
          >
            <Input
              type="number"
              {...register('bonus_item_percent', {
                required: bonusItemChecked ? t('validation.required') : false,
                min: {
                  value: 1,
                  message: t('validation.min', { field: t('store-promotions.bonus-currency-percent'), value: 1 }),
                },
              })}
              {...formatHookFormErrors(errors, 'bonus_item_percent')}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
          </FieldGroup>
        )}
      </>
    )
  }

  return (
    <FieldSection label={t('store-promotions.bonus-item.field-section-label')}>
      <CheckBoxContainer
        label={t('store-promotions.add-bonus-item')}
        checked={bonusItemChecked}
        onChange={v => {
          setBonusItemChecked(v.target.checked)
          if (!v.target.checked) {
            // @ts-ignore
            setValue('bonus_item_id', null as unknown as undefined, { shouldDirty: true })
            clearQuantityValues()
          }
        }}
      >
        <FieldGroup label={t('store-promotions.bonus-item')}>
          <Controller
            control={control}
            rules={{ required: bonusItemChecked ? t('validation.required') : false }}
            name="bonus_item_id"
            render={({ field }) => (
              <SelectSkuItemV2
                hideSelectedQuantity={true}
                hidePrice={true}
                onChange={(v, item) => {
                  field.onChange(v as string)
                  if (!item?.is_stackable) {
                    clearQuantityValues()
                  }
                }}
                value={field.value}
              />
            )}
          />
          <ErrorMessage
            name="bonus_item_id"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        {selectedBonusItem?.is_stackable && renderBonusQuantity()}
      </CheckBoxContainer>
    </FieldSection>
  )
}

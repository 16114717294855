import { ReactNode, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { ModalProps, o1MotionProps, o2MotionProps, rushMotionProps } from '@/ui'
import { UseNavigateBackValue, cn } from '@/libs'

interface ModalRushChildrenProps extends ModalProps {
  onClose: () => void
}

interface ModalRushPageProps {
  children: ({ onClose }: ModalRushChildrenProps) => ReactNode
  back: UseNavigateBackValue
}

export const ModalRushPage = ({ children, back }: ModalRushPageProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    const outlet = document.querySelector('[data-id="main-outlet-root"]')
    outlet?.setAttribute('data-modal-rush', 'true')
    return () => {
      const outlet = document.querySelector('[data-id="main-outlet-root"]')
      outlet?.setAttribute('data-modal-rush', 'false')
    }
  }, [])

  const onClose = useCallback(() => {
    navigate(back.to)
  }, [navigate, back])

  return createPortal(
    <div className="fixed left-0 top-0 z-[200] flex size-full items-center justify-center">
      <AnimatePresence mode="wait">
        <motion.div {...o1MotionProps} className="absolute left-0 top-0 z-[-2] size-full bg-[#c1c8d1]" />
        <motion.div {...o2MotionProps} className="absolute z-[-2] h-full bg-[#dfe3e7]" />
        <motion.div
          {...rushMotionProps}
          className={cn('flex size-full items-center justify-center overflow-y-auto', 'pt-6')}
        >
          {children({ onClose })}
        </motion.div>
      </AnimatePresence>
    </div>,
    document.body,
  )
}

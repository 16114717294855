import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const usePluginInstanceQuery = (companyId: string, gameId: string, slug: string) => {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['plugins_instance', gameId, slug],
    queryFn: () =>
      dashboardClient.v1
        .getPluginInstance(companyId, gameId, slug)
        .then(r => r.data)
        .catch(() => null),
    select: data => data,
  })
}

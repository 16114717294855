import { HUB_EDITOR_LEFT_SIDE_WIDTH, SURFACE_SURFACE_BG_STROKE } from '../Settings'
import ListItemSwitch from '../components/ListItemSwitch'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { EditIcon, SectionIcon, SettingsIcon } from '../icons/Icons'
import { useTheme } from '@mui/material/styles'
import NavItem from '../components/NavItem'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { AghanimTypesWebsiteConfig, SectionType, WebsiteRead, WebsiteSection } from '../api/dashboard'
import ChangeSectionDialog from './dialogs/ChangeSectionDialog'
import { SelectOption, TableRowEditButton } from '@/ui'
import { Trash } from '@/icons'

import { ReactSortable } from 'react-sortablejs'
import { Button, Skeleton } from '@dashboard/ui'
import { uuid4 } from '@/util'
import { useTranslation } from 'react-i18next'
import { SettingsEditor } from '@/hub-editor/widgets/SettingsEditor'
import { ColorSchemeEditor } from '@/hub-editor/widgets/ColorSchemeEditor'
import { useFeatureFlags } from '@/libs'
import { Pallete } from '@/hub-editor/Pallete'
import { usePagesByIdsQuery } from '@/layouts/pages/api/usePagesByIdsQuery'

const commonStyles = {
  borderValue: `1px solid ${SURFACE_SURFACE_BG_STROKE}`,
}

type EditTab = 'sections' | 'apps' | 'settings' | 'templates'

function SectionEditor(props: {
  webSite: WebsiteRead
  setWebSite: (webSite: WebsiteRead) => void
  sendIframeMessage: (data: { menuSections?: WebsiteSection[] }) => void
}) {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { webSite, setWebSite } = props
  const usedPagesIds = webSite?.config?.sections?.filter(it => it.object_id).map(it => it.object_id)

  const { data: pages = [], isLoading } = usePagesByIdsQuery(companyId, gameId, {
    ids: usedPagesIds?.join(',') || '',
  })
  const sectionsList = useMemo(
    () =>
      webSite?.config?.sections?.filter(
        it =>
          (Object.values(SectionType).includes(it.name) && it.name !== SectionType.Page) ||
          (it.object_id && pages.find(p => p.id === it.object_id)),
      ),
    [webSite?.config, pages],
  )
  const [isEditing, setIsEditing] = useState(false)
  const [sectionToEdit, setSectionToEdit] = useState<WebsiteSection>()
  const theme = useTheme()

  const getSectionName = (section_id: string) => {
    return pages.find(p => p.id === section_id)?.title as SectionType
  }

  const getPageBySection = (section_id?: string) => {
    if (!section_id) {
      return
    }
    return pages.find(p => p.id === section_id)
  }
  const isSectionVisible = (s: WebsiteSection) => {
    let sections = sectionsList
    if (!sections) {
      return true
    }

    let section = s.object_id
      ? sections.find(it => it.object_id === s.object_id)
      : sections.find(it => it.name == s.name)
    if (!section) {
      return false
    }

    return section.visible
  }
  const onSectionVisibleChange = (s: WebsiteSection, value: boolean) => {
    let sections = sectionsList || []
    let section = s.object_id
      ? sections.find(it => it.object_id == s.object_id)
      : sections.find(it => it.name == s.name)

    if (!section) {
      section = { name: s.name, visible: value }
      sections.push(section)
    } else {
      section.visible = value
    }

    setWebSite({
      ...webSite,
      config: {
        ...webSite.config,
        sections: sections,
      },
    })
  }

  const handleChange = (section?: WebsiteSection) => {
    if (sectionToEdit && section) {
      const sections = sectionsList || []
      const sectionIndex = sectionToEdit.object_id
        ? sections.findIndex(it => it.object_id == sectionToEdit.object_id)
        : sections.findIndex(it => it.name == section.name)

      if (sectionIndex > -1) {
        sections[sectionIndex] = section
        setWebSite({
          ...webSite,
          config: {
            ...webSite.config,
            sections: sections,
          },
        })
      }
    } else if (section) {
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: [...(webSite.config?.sections || []), section],
        },
      })
    }
    setIsEditing(false)
    setSectionToEdit(undefined)
  }

  const handleDelete = (s: WebsiteSection) => {
    const sections = sectionsList || []
    const section = s.object_id
      ? sections.findIndex(it => it.object_id == s.object_id)
      : sections.findIndex(it => it.name == s.name)

    if (section > -1) {
      sections.splice(section, 1)
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: sections,
        },
      })
    }
  }

  const handleMove = (s: WebsiteSection, direction: number) => {
    const sections = sectionsList || []
    const section = s.object_id
      ? sections.findIndex(it => it.object_id == s.object_id)
      : sections.findIndex(it => it.name == s.name)
    if (section > -1) {
      sections.splice(section + direction, 0, sections.splice(section, 1)[0])
      setWebSite({
        ...webSite,
        config: {
          ...webSite.config,
          sections: sections,
        },
      })
    }
  }

  useEffect(() => {
    handleMenuChange()
  }, [sectionsList])

  const handleMenuChange = () => {
    const hubSections = (sectionsList?.map(it => ({
      type: it.name,
      visible: it.visible,
      name: !it.object_id ? t(`section.${it.name}`) : getSectionName(it.object_id) || it.name,
      icon: it.icon,
      slug: getPageBySection(it.object_id)?.slug,
    })) || []) as WebsiteSection[]

    // send new sections to hub
    props.sendIframeMessage({
      menuSections: hubSections,
    })
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-3 p-3">
        {new Array(10).fill(0).map(() => (
          <Skeleton key={uuid4()} className="h-8 w-full" />
        ))}
      </div>
    )
  }
  return (
    <div className="grow border-r border-border-secondary">
      <Typography
        variant={'title1' as never}
        fontWeight="600"
        sx={{
          padding: theme.spacing(1.75),
          borderBottom: commonStyles.borderValue,
        }}
      >
        {t('hub-settings.sections')}
      </Typography>
      <List>
        <ReactSortable
          animation={200}
          delay={2}
          filter=".non-drag"
          list={sectionsList?.map(it => ({ ...it, id: it.name })) || []}
          setList={v => {
            if (webSite.config?.sections?.length !== v.length) {
              // this is not a drag and drop
              return
            }
            setWebSite({
              ...webSite,
              config: {
                ...webSite.config,
                sections: v,
              },
            })
          }}
        >
          {sectionsList?.map(it => {
            const actions = [] as SelectOption[]
            if (it.object_id) {
              actions.push({
                children: t('edit'),
                value: 'edit',
                icon: <EditIcon />,
              })
              actions.push({
                value: 'delete',
                children: <span className="text-text-error-primary"> {t('delete')} </span>,
                icon: <Trash className="text-text-error-primary" />,
              })
            }
            return (
              <ListItem
                className="cursor-grab hover:text-black/50"
                disablePadding
                secondaryAction={
                  actions.length > 0 && (
                    <TableRowEditButton
                      onChange={v => {
                        switch (v) {
                          case 'move-up':
                            handleMove(it, -1)
                            break
                          case 'move-down':
                            handleMove(it, +1)
                            break
                          case 'edit':
                            setSectionToEdit(it)
                            setIsEditing(true)
                            break
                          case 'delete':
                            handleDelete(it)
                            break
                        }
                      }}
                      options={actions}
                    />
                  )
                }
              >
                <ListItemIcon>
                  <ListItemSwitch
                    sx={{ pr: 0 }}
                    label=""
                    checked={isSectionVisible(it)}
                    key={it.name}
                    onChange={(value: boolean) => onSectionVisibleChange(it, value)}
                  />
                </ListItemIcon>
                <ListItemText sx={{ maxWidth: '100px', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {!it.object_id ? t(`section.${it.name}`) : getSectionName(it.object_id) || it.name}
                </ListItemText>
              </ListItem>
            )
          })}
        </ReactSortable>
      </List>

      <div className="m-2">
        <Button className="w-full" onClick={() => setIsEditing(true)}>
          {t('hub-settings.sections.add')}
        </Button>
      </div>
      {isEditing && (
        <ChangeSectionDialog
          section={sectionToEdit}
          getPageBySection={getPageBySection}
          sectionsList={sectionsList}
          onClose={handleChange}
        />
      )}
    </div>
  )
}

export function LeftSideBar(props: {
  webSite: WebsiteRead
  setWebSite: (webSite: WebsiteRead) => void
  sendIframeMessage: (data: { menuSections?: WebsiteSection[] }) => void
  previewConfig: AghanimTypesWebsiteConfig
  setPreviewConfig: (config: AghanimTypesWebsiteConfig) => void
}) {
  const { featureFlags } = useFeatureFlags()
  const { webSite, setWebSite } = props
  const theme = useTheme()

  const [activeTab, setActiveTab] = useState<EditTab>('settings')

  return (
    <div
      className="flex h-full"
      style={{
        width: HUB_EDITOR_LEFT_SIDE_WIDTH,
        minWidth: HUB_EDITOR_LEFT_SIDE_WIDTH,
      }}
    >
      <List
        sx={{
          width: theme.spacing(7),
          borderRight: commonStyles.borderValue,
          padding: theme.spacing(0.5),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
        }}
      >
        {[
          {
            title: '',
            icon: <SettingsIcon />,
            href: '',
            value: 'settings',
          },
          {
            title: '',
            icon: <SectionIcon />,
            href: '',
            value: 'sections',
          },
        ].map((it, idx) => (
          <NavItem
            showText={false}
            href={it.href}
            icon={it.icon}
            key={idx}
            onClick={() => {
              setActiveTab(it.value as EditTab)
              props.setPreviewConfig({})
            }}
            selected={it.value == activeTab}
          />
        ))}

        {featureFlags.override_colors_enabled && (
          <div className="mt-auto">
            <NavItem
              showText={false}
              href={''}
              icon={<Pallete size={24} />}
              onClick={() => {
                setActiveTab('templates')
                props.setPreviewConfig({})
              }}
              selected={activeTab == 'templates'}
            />
          </div>
        )}
      </List>

      <div className="size-full overflow-y-scroll">
        {activeTab == 'sections' && (
          <SectionEditor webSite={webSite} setWebSite={setWebSite} sendIframeMessage={props.sendIframeMessage} />
        )}
        {activeTab == 'settings' && (
          <SettingsEditor
            webSite={webSite}
            setWebSite={ws => {
              setWebSite(ws)
            }}
          />
        )}

        {activeTab == 'templates' && (
          <ColorSchemeEditor
            config={props.previewConfig}
            onChange={v => {
              props.setPreviewConfig(v)
            }}
          />
        )}
      </div>
    </div>
  )
}

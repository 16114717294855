import { DataGranularity } from '@/api/dashboard'
import { DateRange } from 'react-day-picker'

/**
 * Add timezone offset to date.
 * @param date
 */
export const addTimezoneOffset = (date: Date) => {
  const newDate = new Date(date)
  if (newDate.getTimezoneOffset()) {
    newDate.setMinutes(date.getMinutes() + date.getTimezoneOffset())
  }
  return newDate
}

export const getUtcRange = (range: DateRange) => {
  let to = range.to
  let from = range.from
  if (to) {
    to = new Date(to)
    to.setHours(23, 59, 59, 999)
    to.setMinutes(to.getMinutes() - to.getTimezoneOffset())
  }

  if (from) {
    from = new Date(from)
    from.setMinutes(from.getMinutes() - from.getTimezoneOffset())
  }

  return {
    from: from,
    to: to,
  }
}

export function getDateFormatParams(datetime_at: number | string, dateStyle: 'long' | 'short' = 'short') {
  return {
    val: typeof datetime_at == 'string' ? new Date(datetime_at) : new Date(datetime_at * 1000),
    formatParams: { val: { dateStyle: dateStyle } },
  }
}

export function getDateTimeFormatParams(datetime_at: string | number, variant: 'medium' | 'short' = 'medium') {
  return {
    val: typeof datetime_at == 'string' ? new Date(datetime_at) : new Date(datetime_at * 1000),
    formatParams: { val: { timeStyle: variant, dateStyle: variant } },
  }
}

export function getFormatYearMonthParams(datetime_at: number) {
  return {
    val: new Date(datetime_at * 1000),
    formatParams: {
      val: { month: 'short', year: 'numeric' },
    },
  }
}

export function getTimeParams(date: string | Date, granularity: DataGranularity = DataGranularity.Day) {
  if (typeof date == 'string') {
    date = new Date(date)
    if (granularity == DataGranularity.Day) {
      date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
    }
  }
  return {
    val: date,
    formatParams: {
      val: { hour: '2-digit', minute: '2-digit' },
    },
  }
}

export function getDateCategoryParams(date: string | Date, granularity: DataGranularity = DataGranularity.Day) {
  if (typeof date == 'string') {
    date = new Date(date)
    if (granularity == DataGranularity.Day || granularity == DataGranularity.Month) {
      date = addTimezoneOffset(date)
    }
  }

  switch (granularity) {
    case DataGranularity.Hour:
      return {
        val: date,
        formatParams: {
          val: { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' },
        },
      }
    case DataGranularity.Month:
      return {
        val: date,
        formatParams: {
          val: { month: 'short', year: 'numeric' },
        },
      }
    case DataGranularity.Day:
    default:
      return {
        val: date,
        formatParams: {
          val: { month: 'short', day: 'numeric' },
        },
      }
  }
}

import { formatNumber } from '@/util'
import { RewardPoint } from '@dashboard/ui'

export const RewardPointsPrice = ({
  price,
  iconSize,
  showText = true,
}: {
  iconSize?: number
  price: number
  showText?: boolean
}) => {
  return (
    <div className="inline-flex items-center gap-1">
      <RewardPoint size={iconSize} />
      {showText ? formatNumber(price) : formatNumber(price)}
    </div>
  )
}

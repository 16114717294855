import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button } from '@dashboard/ui'
import AnimatedDots from '../../../components/animated-dots/AnimatedDots'
import { KeyValue } from '@/types'
import { Logo } from '@/components/icons/Logo'
import { useParams } from 'react-router-dom'
import { formatMoney } from '@/util'
import { useBalanceQuery } from '@/layouts/dashboard/api/useBalanceQuery'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { Tooltip } from '@/ui'
import { PayoutRequestStatus } from '@/api/dashboard'

const Root = styled.div`
  display: flex;
  width: 460px;
  min-width: 460px;
  height: 289px;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px;
  background-size: cover;
  background-image: url(/icons/balance-card.png);
  background-origin: border-box;
  border-radius: 18px;
  box-shadow: 0 8px 16px 0 #1018281f;
  &:hover {
    transform: scale(1.01, 1.01);
  }
  border: 1px solid rgba(0, 0, 0, 0.05);
`

const ValueText = styled.span`
  position: relative;
  z-index: 0;
  font-size: 32px;
  line-height: 51px;
  font-family: InterAg;
  font-weight: 600;
  color: ${p => p.theme.palette.text.secondary};
  text-shadow:
    0 2px 2px rgba(71, 85, 105, 0.35),
    0 1px 1px rgba(255, 255, 255, 0.5);
  margin-bottom: 12px;
`

interface BalanceCardProps {
  payoutClick?: () => void
  status?: PayoutRequestStatus
}

export const BalanceCard = ({ payoutClick, status }: BalanceCardProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { game } = useCurrentGame()

  const { data: balance, isError } = useBalanceQuery(companyId, gameId, {
    is_sandbox: !!game?.sandbox,
  })

  const handleMove = (e: MouseEvent) => {
    const el = ref.current
    if (!el) {
      return
    }

    const xVal = (e as unknown as KeyValue).layerX as number
    const yVal = (e as unknown as KeyValue).layerY as number
    const width = el.offsetWidth
    const height = el.offsetHeight
    const yRotation = 5 * ((xVal - width / 2) / width)

    const xRotation = -5 * ((yVal - height / 2) / height)

    el.style.transform = 'perspective(700px) scale(1.01) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'
  }

  const onMouseOut = () => {
    const el = ref.current
    if (el) {
      el.style.transform = 'perspective(700px) scale(1) rotateX(0) rotateY(0)'
    }
  }

  useEffect(() => {
    const el = ref.current
    if (!el) {
      return
    }
    el.addEventListener('mousemove', handleMove)
    el.addEventListener('mouseout', onMouseOut)

    return () => {
      el.removeEventListener('mousemove', handleMove)
      el.removeEventListener('mouseout', onMouseOut)
    }
  }, [])

  const strValue = balance ? (formatMoney(Math.max(balance.value + balance.prev_value!, 0)) as string) : ''

  return (
    <Root ref={ref} className="transition-all">
      <div>
        <Logo />
      </div>

      <div className="flex flex-col">
        <span className="text-[12px] font-semibold uppercase text-text-tertiary">{t('dashboard.balance')}</span>
        {isError ? (
          <span className="text-text-tertiary">{t('service-unavailable')}</span>
        ) : (
          <ValueText title={strValue} className="ph-no-capture">
            {balance ? <span data-testid={'balance'}>{strValue}</span> : <AnimatedDots />}
          </ValueText>
        )}
        <div className="flex items-center justify-between gap-3">
          <span className="text-[14px] text-text-primary" style={{ opacity: 0.5 }}>
            {t('dashboard.balance.desc')}
          </span>

          {status == PayoutRequestStatus.Review && (
            <Tooltip message={t('payouts.instant.apply.disabled-tooltip')} options={{ placement: 'top' }}>
              <Button
                className="h-auto p-0 text-text-brand-tertiary hover:underline disabled:pointer-events-auto disabled:text-text-disabled"
                variant="link"
                type="button"
              >
                {t('payouts.instant.apply.button')}
              </Button>
            </Tooltip>
          )}

          {!status && payoutClick && (
            <Button
              className="h-auto p-0 text-text-brand-tertiary hover:underline disabled:pointer-events-auto disabled:text-text-disabled"
              variant="link"
              type="button"
              onClick={payoutClick}
            >
              {t('payouts.instant.apply.button')}
            </Button>
          )}
          {payoutClick && status == PayoutRequestStatus.Approved && (
            <Button color="secondary" type="button" onClick={payoutClick}>
              {t('payouts.instant.create.button')}
            </Button>
          )}
        </div>
      </div>
    </Root>
  )
}

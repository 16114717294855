import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { Spinner, useSearchFilter } from '@dashboard/ui'
import { Page, PaginationInfiniteObserver } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'
import { Locale } from '@/api/dashboard'
import { L10nTableToolbar, localeQueryOptions, localeQueryPlaceholderData } from '@/layouts/localization'
import { l10nGlossariesInfiniteQuery, l10nGlossariesInfiniteQueryParams } from '../api'
import {
  L10nGlossaryFilter,
  L10nGlossaryItemRow,
  L10nGlossaryItemRowHeader,
  L10nGlossaryLocaleEmptyState,
  L10nGlossaryLocaleSkeleton,
  L10nGlossaryLocaleZeroState,
} from '../components'
import { L10nGlossaryListFilter } from '../types'

export const L10nGlossaryLocalePage = () => {
  const ql = useQueryClient()
  const { t } = useTranslation()
  const { companyId, gameId, localeId } = useGenerateCompanyPath<{ localeId: string }>()
  const filter = useSearchFilter<L10nGlossaryListFilter>()

  const { data: locale } = useQuery({
    ...localeQueryOptions(companyId, gameId, localeId),
    placeholderData: localeQueryPlaceholderData(ql, companyId, gameId, localeId),
  })

  const {
    data = { pages: [] },
    isLoading: isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    l10nGlossariesInfiniteQuery(companyId, gameId, {
      ...(filter.values as Partial<l10nGlossariesInfiniteQueryParams>),
      locale_id: localeId,
      limit: 50,
    }),
  )

  const items = useMemo(() => data?.pages.map(p => p.data).flat(), [data])

  if (isLoading) {
    return (
      <Page>
        <L10nTableToolbar>
          <L10nGlossaryFilter filter={filter} />
          <L10nGlossaryItemRowHeader
            locale={t(`locales.${Locale.En}`)}
            targetLocale={locale ? t(`locales.${locale.locale}`) : ''}
          />
        </L10nTableToolbar>
        <L10nGlossaryLocaleSkeleton />
      </Page>
    )
  }

  if (!isLoading && items.length === 0) {
    if (Object.values(filter.values).some(Boolean)) {
      return (
        <Page>
          <L10nTableToolbar>
            <L10nGlossaryFilter filter={filter} />
            <L10nGlossaryItemRowHeader
              locale={t(`locales.${Locale.En}`)}
              targetLocale={locale ? t(`locales.${locale.locale}`) : ''}
            />
          </L10nTableToolbar>
          <L10nGlossaryLocaleEmptyState onClear={filter.onReset} />
        </Page>
      )
    }

    return (
      <Page>
        <L10nGlossaryLocaleZeroState />
      </Page>
    )
  }

  return (
    <Page>
      <L10nTableToolbar>
        <L10nGlossaryFilter filter={filter} />
        <L10nGlossaryItemRowHeader
          locale={t(`locales.${Locale.En}`)}
          targetLocale={locale ? t(`locales.${locale.locale}`) : ''}
        />
      </L10nTableToolbar>

      <ul role="listbox">
        {items.map(item => (
          <L10nGlossaryItemRow key={`glossary-item-${item.id}`} item={item} />
        ))}
      </ul>

      {!isLoading && (
        <>
          {isFetchingNextPage && (
            <div className="flex shrink-0 justify-center py-4 text-text-disabled">
              <Spinner size={16} />
            </div>
          )}
          <PaginationInfiniteObserver onNextPage={fetchNextPage} />
        </>
      )}
    </Page>
  )
}

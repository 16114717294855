import { Link, generatePath, useParams } from 'react-router-dom'
import {
  PageHeader,
  Pagination,
  Tab,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  Tabs,
  usePagination,
} from '@/ui'
import { useAllPluginQuery, usePluginInstancesQuery } from '@/layouts/app-connect/api'
import { PluginRead } from '@/api/dashboard'
import { Button, InputSearch, useSearchFilter } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { APP_CONNECT_PLUGIN_DETAIL_PATH, useFeatureFlags } from '@/libs'
import ComingSoon from '@/components/ComingSoon'
import { PluginStatus } from '@/layouts/app-connect/components/PluginStatus'
import CenteredProgress from '@/components/CenteredProgress'
import { TableZeroStateFilter } from '@/layouts/components/TableZeroStateFilter'

export type PluginTableFilterState = 'state' | 'q'

export const PluginTable = () => {
  const { t } = useTranslation()
  const { featureFlags, isLoading: isSettingsLoading } = useFeatureFlags()
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const filter = useSearchFilter<PluginTableFilterState>()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const filterEnabled = filter.values.state ? filter.values.state == 'enabled' : undefined
  const hasFilter = !!(filter.values.q || filter.values.state)
  const { data: allPlugins = [], isLoading } = useAllPluginQuery(companyId, gameId, {
    ...query,
    search_string: filter.values.q as string,
  })
  const { data: pluginInstances = [], isLoading: isFilterLoading } = usePluginInstancesQuery(companyId, gameId, {
    enabled: filterEnabled,
  })

  const renderPluginRow = (plugin: PluginRead) => {
    const instance = pluginInstances.find(it => it.plugin.id === plugin.id)

    return (
      <TableRow key={plugin.id}>
        <TableCell width="100%">
          <Link
            className="flex items-center gap-3"
            to={generatePath(APP_CONNECT_PLUGIN_DETAIL_PATH, {
              companyId: companyId,
              gameId: gameId,
              pluginSlug: plugin.slug,
            })}
          >
            <div
              className="size-[32px] shrink-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${plugin.image_url})`,
              }}
            />
            <div className="mr-auto">
              <div className="mb-0.5 truncate leading-5">{plugin.name}</div>
              <div className="text-xs font-medium leading-none text-text-quarterary-hover">{plugin.subtitle}</div>
            </div>
          </Link>
        </TableCell>
        <TableCell width={140}>{t(`plugin.table.category.${plugin.category}`)}</TableCell>
        <TableCell width={140}>
          <PluginStatus status={instance?.enabled} />
        </TableCell>
      </TableRow>
    )
  }

  const renderZeroState = () => {
    if (filter.values.q) {
      return (
        <TableZeroStateFilter
          page={page}
          items={allPlugins}
          hasFilter={hasFilter}
          zeroStateMessage={t(filterEnabled ? 'plugin.table.zero.active.title' : 'plugin.table.zero.deactivated.title')}
          zeroStateSubMessage={t(
            filterEnabled ? 'plugin.table.zero.active.desc' : 'plugin.table.zero.deactivated.desc',
          )}
          notFoundMessage={t('plugin.search.zero.text')}
          clearFilterClick={() => filter.onChange({ q: '', state: filter.values.state }, { force: true })}
        />
      )
    }

    return (
      <TableZeroState
        className="mt-4"
        title={t(filterEnabled ? 'plugin.table.zero.active.title' : 'plugin.table.zero.deactivated.title')}
        message={t(filterEnabled ? 'plugin.table.zero.active.desc' : 'plugin.table.zero.deactivated.desc')}
        buttons={
          filterEnabled && (
            <Button onClick={() => filter.onChange({ state: '' }, { force: true })}>
              {t('plugin.table.zero.active.button')}
            </Button>
          )
        }
      />
    )
  }

  if (isSettingsLoading) {
    return <CenteredProgress />
  }

  if (!featureFlags.app_connect_enabled) {
    return <ComingSoon />
  }

  const items = filterEnabled == undefined ? allPlugins : pluginInstances.map(instance => instance.plugin)

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{t('plugin.title')}</PageHeader>

      <Tabs className="mb-4">
        <Tab
          isActive={!filter.values.state}
          onClick={() => {
            filter.onChange({ ...filter.values, state: '' }, { force: true })
          }}
        >
          {t('plugin.tab.all')}
        </Tab>
        <Tab
          isActive={filter.values.state == 'enabled'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'enabled' }, { force: true })
          }}
        >
          {t('plugin.tab.enabled')}
        </Tab>
        <Tab
          isActive={filter.values.state == 'disabled'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'disabled' }, { force: true })
          }}
        >
          {t('plugin.tab.disabled')}
        </Tab>
      </Tabs>

      <InputSearch
        {...filter.registerInput('q')}
        data-testid="plugin/toolbar/filter-search"
        placeholder={t('search')}
      />

      {isLoading || isFilterLoading ? (
        <TableRowSkeleton rowCount={10} columnCount={3} />
      ) : items.length === 0 && page == 1 ? (
        renderZeroState()
      ) : (
        <>
          <Table>
            <TableRow variant="header">
              <TableCell width="100%">{t('plugin.table.app')}</TableCell>
              <TableCell width={140}>{t('plugin.table.category')}</TableCell>
              <TableCell width={140}>{t('plugin.table.status')}</TableCell>
            </TableRow>
            {items.map(it => renderPluginRow(it))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}

import i18next from 'i18next'
import { useEffect, useState } from 'react'
import Divider from '../../../../components/Divider'
import { useParams } from 'react-router-dom'

import SVSparkLine, { SingleValueType } from './SVSparkLine'
import { HUB_API, URL_HUB_API } from '../../../../HubAPI'
import { AllCampaignDashboardData } from './types'
import ABTestTable from './ABTestTable'
import { FieldGroup } from '@/ui'
import { createPresets, getGranularity, getUrlParamsFromDateRange } from '../../../dashboard/util'
import { DateRange } from 'react-day-picker'
import { InputDateRange } from '@dashboard/ui'
import { getUtcRange } from '@/libs/dates'

export default function ABTestDashboard() {
  const [presets] = useState(createPresets())
  const [selectedFilter, setSelectedFilter] = useState<DateRange>(presets[4].range)
  const [data, setData] = useState<AllCampaignDashboardData>({} as AllCampaignDashboardData)
  const [currentXhr, setCurrentXhr] = useState<XMLHttpRequest | null>(null)
  const { companyId, gameId, eventId } = useParams() as {
    companyId: string
    gameId: string
    eventId: string
  }
  const [loading, setLoading] = useState(false)

  const loadAllData = () => {
    setLoading(true)
    setData({} as AllCampaignDashboardData)
    if (currentXhr) {
      currentXhr.abort()
    }
    let xhr = new XMLHttpRequest()
    setCurrentXhr(xhr)

    const utcRange = getUtcRange(selectedFilter)

    xhr.open(
      'GET',
      `${URL_HUB_API}/dashboard/v1/companies/${companyId}/games/${gameId}/reports/all_campaign_data/${eventId}?${getUrlParamsFromDateRange(utcRange)}`,
      true,
    )

    xhr.setRequestHeader('Authorization', 'Bearer ' + HUB_API.getToken())
    xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8')

    xhr.onprogress = function () {
      if (xhr.readyState >= 3) {
        let lastMessage = xhr.responseText
        let jsons = lastMessage.split('data: ')
        let data = jsons
          .filter(it => !!it.trim() && it.indexOf('DONE_MESSAGE') == -1)
          .map(it => {
            try {
              return JSON.parse(it)
            } catch (e) {
              console.error(e)
              console.log(it)
            }
          })

        let obj = {}
        data.forEach(d => {
          obj = { ...obj, ...d }
        })

        setData(prev => ({
          ...prev,
          ...obj,
        }))

        if (lastMessage.indexOf('DONE_MESSAGE') != -1) {
          xhr.onprogress = null
          xhr.abort()
          setCurrentXhr(null)
          setLoading(false)
        }
      }
    }

    xhr.send()
  }

  useEffect(() => {
    loadAllData()
  }, [companyId, gameId, eventId, selectedFilter])

  const granularity = getGranularity(selectedFilter)

  return (
    <div className="w-full px-6">
      <div style={{ width: '200px' }}>
        <FieldGroup label={i18next.t('campaign.date-filter')}>
          <InputDateRange value={selectedFilter} onChange={v => setSelectedFilter(v!)} presets={presets} />
        </FieldGroup>
      </div>

      <Divider />

      <div className="flex flex-row gap-3">
        <SVSparkLine
          granularity={granularity}
          value={data.campaign_players_count}
          label={i18next.t('campaign.header.total-players')}
          sparklineData={data.campaign_players_count_by_date || []}
        />
        <SVSparkLine
          granularity={granularity}
          value={data.campaign_paying_users_count}
          label={i18next.t('campaign.header.paying-users')}
          sparklineData={data.campaign_paying_users_count_by_date || []}
        />
        <SVSparkLine
          granularity={granularity}
          type={SingleValueType.money}
          value={data.campaign_total_revenue}
          label={i18next.t('campaign.header.total-revenue')}
          sparklineData={data.campaign_total_revenue_by_date || []}
        />
        <SVSparkLine
          granularity={granularity}
          type={SingleValueType.money}
          value={data.campaign_avg_check}
          label={i18next.t('campaign.header.average-check')}
          sparklineData={data.campaign_avg_check_by_date || []}
        />
      </div>

      <div>
        <ABTestTable data={data.ab_test_data || []} loading={loading} />
      </div>
    </div>
  )
}

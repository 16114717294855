import { SegmentGroupRead, SegmentGroupType, SegmentRead } from '@/api/dashboard'
import { Controller, useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ColorSelector, FieldGroup } from '@/ui'
import { useSegmentGroupsQuery } from '../api/useSegmentGroupsQuery'
import { useSegmentCreateMutate } from '../api/useSegmentCreateMutate'
import { useSegmentUpdateMutate } from '../api/useSegmentUpdateMutate'
import { GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH } from '@/libs'
import EditSegmentGroupModal from './EditSegmentGroupModal'
import slugify from '../../../libs/slugify'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  Textarea,
  UseFloatingMenuState,
  formatHookFormErrors,
  useModal,
} from '@dashboard/ui'
import { getErrorText } from '@/api'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '@/Settings'

interface EditSegmentDialogProps extends ModalProps {
  disableSelectGroup?: boolean
  item: SegmentRead
}

export default function EditSegmentDialog(props: EditSegmentDialogProps) {
  const { ...rest } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: items = [] } = useSegmentGroupsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useSegmentCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useSegmentUpdateMutate(companyId, gameId)
  const [errorText, setErrorText] = useState<string | null>(null)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<SegmentRead>({
    mode: 'onChange',
    values: { ...props.item },
  })
  const color = watch('color')

  useEffect(() => {
    if (!props.item.id) {
      setValue('slug', slugify(watch('name')))
    }
  }, [watch('name')])

  const onSaveClick = async (data: SegmentRead) => {
    try {
      if (data.id) {
        await updateMutateAsync({
          id: data.id,
          update: data,
        })
      } else {
        await createMutateAsync({
          create: data,
        })

        if (data.segment_group_id) {
          navigate(
            generatePath(GAME_SEGMENT_EDIT_GROUP_GRAPH_PATH, { companyId, gameId, groupId: data.segment_group_id }),
          )
        }
      }
      props.onClose?.()
    } catch (e) {
      setErrorText(getErrorText(e))
    }
  }

  const openGroupModal = useModal<{ item: SegmentGroupRead; onClose?: () => void }>(props => (
    <EditSegmentGroupModal
      {...props}
      onClose={props.onClose!}
      onAdded={item => {
        setValue('segment_group_id', item.id)
      }}
    />
  ))

  return (
    <Modal {...rest} onClose={props.onClose}>
      <ModalContent>
        <ModalTitle>{t(props.item.id ? 'segment.edit' : 'segment.add')}</ModalTitle>
        <FieldGroup label={t('segment.name')}>
          <Input
            autoFocus
            {...register('name', {
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('segment.name'),
                  value: MAX_NAME_LENGTH,
                }),
                value: MAX_NAME_LENGTH,
              },
              required: t('validation.required'),
              setValueAs: value => value?.trim(),
            })}
            {...formatHookFormErrors(errors, 'name')}
          />
        </FieldGroup>

        <FieldGroup label={t('segment.slug')}>
          <Input
            {...register('slug', {
              required: t('validation.required'),
              setValueAs: value => slugify(value.trim()),
            })}
            {...formatHookFormErrors(errors, 'slug')}
          />
        </FieldGroup>

        <FieldGroup label={t('segments.group')}>
          <Controller
            control={control}
            name="segment_group_id"
            render={({ field: { value, onChange } }) => (
              <Select
                disabled={props.disableSelectGroup}
                onChange={v => {
                  onChange(v as string)
                }}
                placeholder={t('segments.select-group')}
                value={value}
                items={items.map(it => ({ value: it.id, children: it.name }))}
                renderMenuFooter={(state: UseFloatingMenuState) => (
                  <Button
                    onClick={() => {
                      state.setIsOpen(false)
                      openGroupModal({
                        item: {
                          name: '',
                          type: SegmentGroupType.Static,
                          enabled: true,
                          is_template: false,
                        } as SegmentGroupRead,
                      })
                    }}
                    variant="outline"
                    size="md"
                    className="mt-2 w-full"
                  >
                    {t('segments.group.add')}
                  </Button>
                )}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={t('segment.description')}>
          <Textarea
            {...register('description', {
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('segment.description'),
                  value: MAX_DESCRIPTION_LENGTH,
                }),
                value: MAX_DESCRIPTION_LENGTH,
              },
            })}
            {...formatHookFormErrors(errors, 'description')}
            placeholder={t('sku.item.description.placeholder')}
            rows={6}
          />
        </FieldGroup>

        <FieldGroup label={t('segment.color')}>
          <ColorSelector {...register('color', { required: false })} value={color} />
        </FieldGroup>

        {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
      </ModalContent>

      <ModalFooter>
        <Button size="sm" onClick={props.onClose} variant="outline" color="secondary">
          {t('Cancel')}
        </Button>
        <Button size="sm" color="primary" onClick={handleSubmit(onSaveClick)}>
          {t(props.item.id ? 'Save2' : 'Add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

import { ButtonIcon, FieldGroup, Table, TableCell, TableRow } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Plus, Trash } from '@/icons'
import { useState } from 'react'
import { Button, Input, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'

// @ts-ignore
export interface EditListModalProps extends ModalProps {
  onChange: (value: string[]) => void
  value: string[] | undefined | null
  modalTitle?: string
}

export const EditListModal = ({ onChange, value: initValue, modalTitle, ...rest }: EditListModalProps) => {
  const { t } = useTranslation()
  const [items, setItems] = useState<string[]>(initValue || [])
  const [value, setValue] = useState<string>('')

  const saveClick = () => {
    let data = items
    let v = value.trim()
    if (v && !items.includes(v)) {
      data = [...items, value.trim()]
    }
    onChange(data)
    rest.onClose?.()
  }

  const onClearList = () => {
    setItems([])
  }

  const onAddItem = () => {
    let v = value.trim()
    if (v && !items.includes(v)) {
      setItems([...items, v])
      setValue('')
    }
  }

  const onRemoveItem = (item: string) => {
    setItems(items.filter(i => i !== item))
  }

  return (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle>{modalTitle || t('edit-list')}</ModalTitle>

        <FieldGroup label={t('plugin.dict.new-value')}>
          <div className="flex w-full items-center gap-2">
            <div className="grow">
              <Input
                autoFocus
                onChange={e => setValue(e.target.value)}
                value={value}
                onKeyPress={e => e.key === 'Enter' && onAddItem()}
              />
            </div>
            <ButtonIcon size="sm" variant="secondary-gray" onClick={onAddItem} className="h-[36px] min-w-[36px]">
              <Plus size={14} />
            </ButtonIcon>
          </div>
        </FieldGroup>

        {items.length > 0 && (
          <Table className="mt-3 rounded-sm border border-border-secondary">
            {items.map(item => (
              <TableRow key={item}>
                <TableCell>{item}</TableCell>
                <TableCell width={48}>
                  <ButtonIcon variant="tertiary-destructive" size="sm" onClick={() => onRemoveItem(item)}>
                    <Trash />
                  </ButtonIcon>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
      </ModalContent>

      <ModalFooter>
        <Button
          variant="outline"
          size="sm"
          color="danger"
          disabled={items.length === 0}
          onClick={onClearList}
          className="mr-auto"
        >
          {t('clear-list')}
        </Button>
        <Button variant="outline" size="sm" color="secondary" onClick={rest.onClose}>
          {t('Cancel')}
        </Button>
        <Button size="sm" onClick={saveClick}>
          {t('Apply')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

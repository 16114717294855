import { ReactElement, cloneElement, forwardRef, useEffect, useState } from 'react'
import {
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import { getFloatingMotionProps } from './getFloatingMotionProps'

interface FloatingProps {
  children: ReactElement
  menu: ReactElement
  initialOpen?: boolean
  referenceWidth?: boolean
  focusManager?: boolean
  placement?: Placement
  distance?: number
  hasArrow?: boolean
  stopPropagation?: boolean
  onOpenCallback?: (isOpen: boolean) => void
}

export interface FloatingMenuProps {
  onClose?: () => void
}

const StyledFloating = styled.div`
  z-index: 3000;
`

export const Floating = forwardRef<HTMLElement, FloatingProps>(function Floating(
  {
    children,
    menu,
    stopPropagation = false,
    initialOpen = false,
    referenceWidth = false,
    focusManager = true,
    placement,
    distance = 6,
    onOpenCallback,
  },
  ref,
) {
  const [isOpen, setIsOpen] = useState(initialOpen)

  useEffect(() => {
    onOpenCallback?.(isOpen)
  }, [isOpen])

  const {
    refs,
    floatingStyles,
    placement: floatingPlacement,
    context,
  } = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
    middleware: [
      shift({ crossAxis: false, padding: 6 }),
      offset(distance),
      flip({ fallbackAxisSideDirection: 'end' }),
      referenceWidth &&
        size(() => ({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              minWidth: `${rects.reference.width}px`,
            })
          },
        })),
    ],
    onOpenChange: setIsOpen,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useDismiss(context)])
  const referenceMergedRef = useMergeRefs([refs.setReference, ref])

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      {cloneElement(children, {
        ...getReferenceProps({
          onClick: e => {
            if (stopPropagation) {
              e.stopPropagation()
              e.preventDefault()
            }
            setIsOpen(!isOpen)
          },
        }),
        ref: referenceMergedRef,
        'data-open': isOpen,
      })}

      <AnimatePresence>
        {isOpen && (
          <FloatingFocusManager context={context} disabled={!focusManager}>
            <FloatingPortal>
              <StyledFloating ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                <motion.div {...getFloatingMotionProps(floatingPlacement)}>
                  {cloneElement(menu, { onClose })}
                </motion.div>
              </StyledFloating>
            </FloatingPortal>
          </FloatingFocusManager>
        )}
      </AnimatePresence>
    </>
  )
})

import { DataGranularity } from '@/api/dashboard'
import i18next from 'i18next'
import { IAnalyticsDataContext } from './Context'
import { DateRange } from 'react-day-picker'
import {
  lastDaysRangePreset,
  lastHoursRangePreset,
  todayRangePreset,
  yesterdayRangePreset,
} from '@/layouts/dashboard/components/filterPresets'

export const getSerName = (context: IAnalyticsDataContext) => {
  const dateRange = context.selectedFilter as DateRange

  switch (context.granularity) {
    case DataGranularity.Hour:
      return (
        i18next.t('intl.DateTime', {
          formatParams: { val: { dateStyle: 'short', timeStyle: 'medium' } },
          val: dateRange.from,
        }) +
        ' - ' +
        i18next.t('intl.DateTime', {
          formatParams: { val: { dateStyle: 'short', timeStyle: 'medium' } },
          val: dateRange.to,
        })
      )

    case DataGranularity.Month:
    case DataGranularity.Day:
      return (
        i18next.t('intl.DateTime', {
          formatParams: { val: { dateStyle: 'medium' } },
          val: dateRange.from,
        }) +
        ' - ' +
        i18next.t('intl.DateTime', {
          formatParams: { val: { dateStyle: 'medium' } },
          val: dateRange.to,
        })
      )
  }

  return ''
}

export const getGranularity = (range: DateRange) => {
  const start_at = range.from!.getTime() / 1000
  const end_at = range.to!.getTime() / 1000

  const diff = end_at - start_at

  if (diff <= 60 * 60 * 49) {
    return DataGranularity.Hour
  }

  if (diff <= 60 * 60 * 24 * 61) {
    return DataGranularity.Day
  }

  return DataGranularity.Month
}

export const getUrlParamsFromDateRange = (range: DateRange) => {
  const from = range.from!.getTime().toString().slice(0, -3)
  const to = range.to!.getTime().toString().slice(0, -3)

  return `granularity=${getGranularity(range)}&type=period&start_at=${from}&end_at=${to}`
}

export const getObjectParamsFromDateRange = (range: DateRange) => {
  return {
    granularity: getGranularity(range),
    start_at: Math.round(range.from!.getTime() / 1000),
    end_at: Math.round(range.to!.getTime() / 1000),
  }
}

export const getPeriodParamsFromUrlRange = (
  start_at: string | number | null | undefined,
  end_at: string | number | null | undefined,
) => {
  return {
    start_at: start_at ? Math.round(parseInt(start_at as string) / 1000) : undefined,
    end_at: end_at ? Math.round(parseInt(end_at as string) / 1000) : undefined,
  }
}

export const createPresets = () => {
  return [
    lastHoursRangePreset(12, 'filter.12hours'),
    lastHoursRangePreset(24, 'filter.24hours'),
    todayRangePreset(),
    yesterdayRangePreset(),
    lastDaysRangePreset(6, 'filter.7days'),
    lastDaysRangePreset(13, 'filter.14days'),
    lastDaysRangePreset(29, 'filter.30days'),
  ]
}

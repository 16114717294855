import { useTranslation } from 'react-i18next'

import {
  Button,
  DotsHorizontal,
  Edit03,
  InputSearch,
  Menu,
  MenuItem,
  Popper,
  SelectFilter,
  Trash,
  useModal,
  useSearchFilter,
} from '@dashboard/ui'
import { CreatorRead, CreatorSocialPlatform } from '@/api/dashboard'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableRow,
  TableRowSkeleton,
  usePagination,
} from '@/ui'
import { useCreators, useCreatorsPlatforms } from '@/layouts/engagement/hooks'
import { createElement, useEffect } from 'react'
import { useBulkSelect } from '@/libs'
import { SOCIAL_ICONS } from '@/layouts/engagement/social-icons'
import { ElementsDeck } from '@/layouts/engagement/component'
import { CreatorsQueryParams } from '@/layouts/engagement/api'
import { formatMoney } from '@/util'
import { useCurrentUser } from '@/api/useCurrentUser'
import { CreatorModal, CreatorModalProps } from '@/layouts/engagement/widgets/CreatorModal'
import { CreatorDeleteModal } from '@/layouts/engagement/widgets/CreatorDeleteModal'
import { TableZeroStateFilter, hasZeroState } from '@/layouts/components/TableZeroStateFilter'

export const CreatorsPage = () => {
  const { t } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { platforms = [] } = useCreatorsPlatforms()

  const filter = useSearchFilter<'q' | 'platform'>()
  const { page, query: pagination, onPageChange, needShowPagination } = usePagination(10)
  const {
    data: { creators = [], hasNext },
    isLoading,
  } = useCreators({
    ...pagination,
    ...(filter.values as Partial<CreatorsQueryParams>),
  })

  const { selected, onSelect, onReset } = useBulkSelect()
  useEffect(() => onReset([]), [page])

  const hasFilter = !!(filter.values.platform || filter.values.q)

  const openEditModal = useModal<CreatorModalProps>(props => <CreatorModal {...props} />)
  const openDeleteModal = useModal<{ creators: CreatorRead[] }>(props => (
    <CreatorDeleteModal {...props} onDeleted={() => onReset([])} />
  ))

  const renderHeader = (loading?: boolean) => (
    <TableRow variant="header">
      {canEdit && (
        <TableCellBulkCheckbox
          selected={selected.size !== 0 && selected.size === creators.length}
          disabled={loading || creators.length === 0}
          onChange={() => onReset(selected.size == creators.length ? [] : creators.map(creator => creator.id))}
        />
      )}
      <TableCell>{t('creators.table.name')}</TableCell>
      <TableCell width={104}>{t('creators.table.platform')}</TableCell>
      <TableCell width={146}>{t('creators.table.codes')}</TableCell>
      <TableCell width={146}>{t('creators.table.revenue')}</TableCell>
      <TableCell width={146}>{t('creators.table.balance')}</TableCell>
      <TableCell width={146}>{t('creators.table.terms')}</TableCell>
      <TableCell width={64} />
    </TableRow>
  )

  const renderBulkActions = (loading?: boolean) => (
    <TableRow variant="header">
      <TableCellBulkCheckbox
        selected={selected.size !== 0 && selected.size === creators.length}
        disabled={loading}
        onChange={() => onReset(selected.size == creators.length ? [] : creators.map(creator => creator.id))}
      />
      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        <Button
          variant="tertiary"
          color="danger"
          size="sm"
          onClick={() =>
            openDeleteModal({
              creators: creators.filter(creator => selected.has(creator.id)),
            })
          }
        >
          <Trash />
          <span>{t('delete')}</span>
        </Button>
      </TableCellBulkToolbar>
    </TableRow>
  )

  const renderZeroState = () => {
    if (isLoading || !hasZeroState(creators, page, hasFilter)) {
      return null
    }

    return (
      <TableZeroStateFilter
        page={page}
        items={creators}
        hasFilter={hasFilter}
        createClick={() => openEditModal()}
        zeroStateMessage={t('creators.table.zero.title')}
        zeroStateSubMessage={t('creators.table.zero.description')}
        addButtonText={t('creators.add')}
        notFoundMessage={t('creators.table.zero.found')}
        clearFilterClick={filter.onReset}
      />
    )
  }

  const renderTable = () => {
    return (
      <div className="flex size-full flex-col">
        <div className="inline-flex justify-between gap-4">
          {(creators.length > 0 || hasFilter) && (
            <>
              <InputSearch {...filter.registerInput('q')} placeholder={t('search')} />

              <SelectFilter
                className="ml-auto"
                {...filter.registerSelect('platform')}
                items={platforms.map(p => ({
                  icon: SOCIAL_ICONS[p.platform],
                  children: p.name,
                  value: p.platform,
                }))}
              >
                {t('creators.table.platform')}
              </SelectFilter>
            </>
          )}
        </div>

        {renderZeroState() || (
          <Table className="size-full">
            {selected.size === 0 ? renderHeader(isLoading) : renderBulkActions(isLoading)}

            {isLoading && <TableRowSkeleton rowCount={10} columnCount={3} />}

            {!isLoading &&
              creators.length !== 0 &&
              creators.map(creator => (
                <TableRow key={creator.id}>
                  {canEdit && (
                    <TableCellBulkCheckbox selected={selected.has(creator.id)} onChange={() => onSelect(creator.id)} />
                  )}
                  <TableCell>
                    <div className="inline-flex items-center gap-2">
                      <img
                        src={creator.avatar_url || '/icons/default-avatar.svg'}
                        alt="img"
                        className="size-[34px] rounded-full object-cover"
                      />
                      <div className="flex flex-col gap-0.5">
                        <span className="text-caption-md text-text-secondary">{creator.name}</span>
                        <span className="text-caption-sm text-text-tertiary">{creator.email}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell width={104}>
                    <ElementsDeck
                      elements={creator.social_medias.slice(0, 3).map(({ platform, url }) => (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <div className="rounded border border-white bg-white">
                            {createElement(SOCIAL_ICONS[platform ?? CreatorSocialPlatform.Other], { size: 18 })}
                          </div>
                        </a>
                      ))}
                      extra={creator.social_medias.length - 3}
                    />
                  </TableCell>
                  <TableCell width={146}>{creator.coupon_count}</TableCell>
                  <TableCell width={146}>{formatMoney(creator.revenue)}</TableCell>
                  <TableCell width={146}>{formatMoney(creator.balance)}</TableCell>
                  <TableCell width={146}>
                    {creator.terms ? (
                      <Badge variant="lime-secondary">{t('creators.table.terms.accepted')}</Badge>
                    ) : (
                      <Badge variant="gray-primary">{t('creators.table.terms.awaiting')}</Badge>
                    )}
                  </TableCell>
                  <TableCell width={64}>
                    {canEdit && (
                      <Popper
                        popper={props => (
                          <Menu {...props}>
                            <MenuItem
                              icon={Edit03}
                              onClick={() => {
                                openEditModal({ creator })
                                props.onClose()
                              }}
                            >
                              {t('edit')}
                            </MenuItem>
                            <MenuItem
                              icon={Trash}
                              variant="danger"
                              onClick={() => {
                                openDeleteModal({ creators: [creator] })
                                props.onClose()
                              }}
                            >
                              {t('delete')}
                            </MenuItem>
                          </Menu>
                        )}
                      >
                        <Button variant="tertiary" color="secondary">
                          <DotsHorizontal />
                        </Button>
                      </Popper>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        )}

        {needShowPagination(isLoading, creators) && (
          <Pagination hasNext={hasNext} page={page} onPageChange={onPageChange} pageItems={creators.length} />
        )}
      </div>
    )
  }

  return (
    <div className="flex size-full flex-col">
      <PageHeader
        extra={
          canEdit &&
          creators?.length > 0 && (
            <Button color="primary" size="sm" onClick={() => openEditModal()}>
              {t('creators.add')}
            </Button>
          )
        }
      >
        {t('sidebar.creators')}
      </PageHeader>

      <div className="size-full">{renderTable()}</div>
    </div>
  )
}

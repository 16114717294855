import { useEffect } from 'react'
import i18next from 'i18next'
import { dashboardClient } from '../../api'
import { useParams } from 'react-router-dom'

import {
  Callout,
  FieldGroup,
  FieldValidationMessage,
  ImageList,
  ImageUploader,
  Input,
  ModalConfirm,
  PageHeader,
  Select,
  SelectOption,
  SpinnerAghanim,
  Toggle,
  useModal,
} from '@/ui'
import { useToast } from '../../components/ui/Toast/useToast'
import { Controller, useForm } from 'react-hook-form'
import {
  Mode,
  NewsCategory,
  StoreCardVersion,
  StoreImageSize,
  VerifyState,
  WebsiteFont,
  WebsiteRead,
} from '../../api/dashboard'
import { ToastSeverity } from '../../components/ui/Toast/types'
import DescBlock from '../game-settings/components/DescBlock'
import Block from '../game-settings/components/Block'
import { ErrorMessage } from '@hookform/error-message'
import { Textarea } from '../../components/ui/Textarea'
import DangerousButton from '../../components/DangerousButton'
import { getNow } from '../../util'
import { KeyValue } from '../../types'
import { invalidateWebsitesQuery } from '../../api/useWebsitesQuery'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { accountDetailsQuery } from '@/layouts/company'
import { AlertHexagon } from '@/icons'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { Button } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'

const imageRatioOptions: SelectOption[] = [
  {
    value: 1,
    children: '1:1',
  },
  // Horizontal
  {
    value: 3 / 2,
    children: '3:2',
  },
  {
    value: 5 / 2,
    children: '5:2',
  },
  {
    value: 7 / 5,
    children: '7:5',
  },
  {
    value: 10 / 8,
    children: '10:8',
  },
  {
    value: 16 / 9,
    children: '16:9',
  },
  // Vertical
  {
    value: 2 / 3,
    children: '2:3',
  },
  {
    value: 2 / 5,
    children: '2:5',
  },
  {
    value: 5 / 7,
    children: '5:7',
  },
  {
    value: 8 / 10,
    children: '8:10',
  },
  {
    value: 9 / 16,
    children: '9:16',
  },
]

const FONT_NAMES = {
  'concert-one': 'Concert One',
  inter: 'Inter',
  lato: 'Lato',
  merriweather: 'Merriweather',
  montserrat: 'Montserrat',
  'noto-sans': 'Noto Sans',
  'open-sans': 'Open Sans',
  oswald: 'Oswald',
  prompt: 'Prompt',
  raleway: 'Raleway',
  roboto: 'Roboto',
  rubik: 'Rubik',
  'russo-one': 'Russo One',
  'work-sans': 'Work Sans',
  outfit: 'Outfit',
}

export const HubSettingsPage = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const queryClient = useQueryClient()
  const { canEdit, isSuperAdmin, isAdmin } = useCurrentUser()
  const showToast = useToast()
  const { game } = useCurrentGame()
  const { data: accountDetails, isLoading } = useQuery(accountDetailsQuery(companyId, isAdmin))

  const {
    setValue: setValueWebsite,
    watch: watchWebsite,
    control: controlWebsite,
    reset: resetWebSite,
    formState: { errors: errorsWebSite, isDirty: isDirtyWebSite, dirtyFields: dirtyGameFields },
    register: registerWebSite,
    handleSubmit: handleSubmitWebSite,
  } = useForm<WebsiteRead>({})

  const loadSite = async () => {
    let { data } = await dashboardClient.v1.getWebsites(companyId, gameId)
    if (data.length) {
      let { data: ws } = await dashboardClient.v1.getWebsite(companyId, gameId, data[0].id)
      if (ws) {
        resetWebSite(ws)
      }
    }
  }

  useEffect(() => {
    loadSite()
  }, [gameId])

  const published_at = watchWebsite('published_at')
  const leaderboard_icons = watchWebsite('config.leaderboard_icons') || []
  const avatars = watchWebsite('config.avatars') || []

  const handleSaveClick = async () => {
    if (isDirtyWebSite) {
      await handleSubmitWebSite(saveWebSite)()
    }

    if (Object.keys(errorsWebSite).length === 0) {
      showToast({ message: i18next.t('saved'), severity: ToastSeverity.success })

      invalidateWebsitesQuery(queryClient, companyId, gameId)
    }
  }

  const isDirty = () => {
    if (Object.keys(dirtyGameFields).length) {
      return true
    }

    return false
  }

  const onDiscardClick = () => {
    resetWebSite()
  }

  const saveWebSite = async (data: WebsiteRead) => {
    if (data.config?.news_settings) {
      let keys = [NewsCategory.Event, NewsCategory.Update, NewsCategory.CompanyNews]
      let default_image_urls = data.config?.news_settings.default_image_urls as KeyValue
      for (let key of keys) {
        if (!default_image_urls[key]) {
          delete default_image_urls[key]
        }
      }
    }

    if (data.config?.store_settings) {
      if (!data.config.store_settings.card_version) {
        delete data.config.store_settings.card_version
      }
    }

    await dashboardClient.v1.updateWebsite(companyId, gameId, data.id, data)

    resetWebSite(data)
  }

  const renderGeneral = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.title')} description={i18next.t('hub-settings.general.desc')}>
        <Block>
          <FieldGroup label={i18next.t('website.title')}>
            <Input {...registerWebSite('title', { required: i18next.t('validation.required') })} disabled={!canEdit} />
            <ErrorMessage
              name="title"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('website.description')}>
            <Textarea
              {...registerWebSite('description', { required: i18next.t('validation.required') })}
              disabled={!canEdit}
            />
            <ErrorMessage
              name="description"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.image_url')}>
            <Controller
              control={controlWebsite}
              name="image_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="image_url"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderMiscSettings = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.misc')} description={i18next.t('hub-settings.misc.desc')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-0">
                <FieldGroup
                  label={i18next.t('hub-settings.cookie-banner')}
                  caption={i18next.t('hub-settings.cookie-banner.desc')}
                >
                  {' '}
                </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.cookie_banner_enabled"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}
        </Block>
      </DescBlock>
    )
  }

  const renderLinksSettings = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.game-hub-links')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <FieldGroup label={i18next.t('hub-settings.support-url')}>
              <Input
                disabled={!canEdit}
                {...registerWebSite('config.website_links.customer_support_link', {
                  pattern: {
                    value: /^(https?:\/\/)/,
                    message: i18next.t('validation.invalid_url'),
                  },
                })}
              />
              <ErrorMessage
                name="url"
                errors={errorsWebSite}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          )}
        </Block>
      </DescBlock>
    )
  }

  const renderDemoSettings = () => {
    if (game?.sandbox || !isAdmin) {
      return <></>
    }

    return (
      <DescBlock title={i18next.t('hub-settings.danger-zone')} description={i18next.t('hub-settings.danger-zone.desc')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <>
              {published_at ? (
                <DangerousButton
                  onClick={() => openConfirmUnPublish()}
                  btnText={i18next.t('game-edit-dialog.website.unpublish.button')}
                  title={i18next.t('game-edit-dialog.website.unpublish.title')}
                  subTitle={i18next.t('game-edit-dialog.website.unpublish.subtitle')}
                  color={'error'}
                />
              ) : accountDetails?.verify_state === VerifyState.Verified ? (
                <DangerousButton
                  color={'primary'}
                  onClick={() => openConfirmPublish()}
                  btnText={i18next.t('game-edit-dialog.website.publish.button')}
                  title={i18next.t('game-edit-dialog.website.publish.title')}
                  subTitle={i18next.t('game-edit-dialog.website.publish.subtitle')}
                />
              ) : (
                <Callout icon={AlertHexagon} variant="error">
                  {i18next.t('game-edit-dialog.website.publish.unverified')}
                </Callout>
              )}
            </>
          )}
        </Block>
      </DescBlock>
    )
  }

  const openConfirmPublish = useModal(props => (
    <ModalConfirm
      color={'primary'}
      cancelButtonText={i18next.t('game-edit-dialog.website.publish-dialog.cancel')}
      confirmButtonText={i18next.t('game-edit-dialog.website.publish-dialog.confirm')}
      subMessage={i18next.t('game-edit-dialog.website.publish-dialog.subtitle')}
      message={i18next.t('game-edit-dialog.website.publish-dialog.title')}
      onConfirm={() => {
        setValueWebsite('published_at', getNow(), { shouldDirty: true })
      }}
      showClose={true}
      {...props}
    />
  ))

  const openConfirmUnPublish = useModal(props => (
    <ModalConfirm
      color="danger"
      cancelButtonText={i18next.t('game-edit-dialog.website.unpublish-dialog.cancel')}
      confirmButtonText={i18next.t('game-edit-dialog.website.unpublish-dialog.confirm')}
      subMessage={i18next.t('game-edit-dialog.website.unpublish-dialog.subtitle')}
      message={i18next.t('game-edit-dialog.website.unpublish-dialog.title')}
      onConfirm={() => {
        setValueWebsite('published_at', null as unknown as undefined, { shouldDirty: true })
      }}
      showClose={true}
      {...props}
    />
  ))

  const renderStyle = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.style')} description={i18next.t('hub-settings.style.desc')}>
        <Block>
          <FieldGroup label={i18next.t('hub-settings.theme')} tooltip={i18next.t('hub-settings.theme.tooltip')}>
            <Controller
              control={controlWebsite}
              name="config.mode"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(Mode)
                    .filter(it => it !== Mode.Auto)
                    .map(it => ({
                      children: i18next.t(`hub-settings.theme.${it}`),
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.favicon_url')}>
            <Controller
              control={controlWebsite}
              name="config.favicon_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.favicon_url"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
          <FieldGroup label={i18next.t('hub-settings.base_bg_image')}>
            <Controller
              control={controlWebsite}
              name="config.base_bg_image"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.base_bg_image"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.font')}>
            <Controller
              control={controlWebsite}
              name="config.font"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(WebsiteFont)
                    .sort()
                    .map(it => ({
                      children: FONT_NAMES[it],
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>
          <FieldGroup label={i18next.t('hub-settings.events-widget-category')}>
            <Controller
              control={controlWebsite}
              name="config.events_widget_category"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(NewsCategory)
                    .sort()
                    .map(it => ({
                      children: i18next.t(`edit-news-dialog.category.${it}`),
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderAgeSettings = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.age')} description={i18next.t('hub-settings.age-desc')}>
        <Block>
          <FieldGroup label={i18next.t('hub-settings.image_url')}>
            <Controller
              control={controlWebsite}
              name="config.age_settings.icon_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="image_url"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('age-settings.min_age')}>
            <Input
              min={0}
              type="number"
              {...registerWebSite('config.age_settings.min_age', {
                setValueAs: value => {
                  if (value == null) {
                    return null
                  }
                  return parseInt(value)
                },
              })}
              disabled={!canEdit}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderTrademarks = () => {
    return (
      <DescBlock title={i18next.t('hub-settings.trademarks')} description={i18next.t('hub-settings.trademarks.desc')}>
        <Block>
          <Textarea {...registerWebSite('config.trademarks')} disabled={!canEdit} />
          <ErrorMessage
            name="config.trademarks"
            errors={errorsWebSite}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </Block>
      </DescBlock>
    )
  }

  const renderStoreSettings = () => {
    return (
      <DescBlock
        title={i18next.t('hub-settings.store-settings.title')}
        description={i18next.t('hub-settings.store-settings.desc')}
      >
        <Block>
          <FieldGroup label={i18next.t('hub-settings.store-settings.image-ratio')}>
            <Controller
              control={controlWebsite}
              name="config.store_settings.image_ratio"
              render={({ field }) => <Select options={imageRatioOptions} {...field} disabled={!canEdit} />}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.store-settings.image-place-holder')}>
            <Controller
              control={controlWebsite}
              name="config.store_settings.default_image_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.store_settings.default_image_url"
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          {isSuperAdmin && (
            <FieldGroup label={'Store Item View'}>
              <Controller
                control={controlWebsite}
                name="config.store_settings.card_version"
                render={({ field }) => (
                  <Select
                    options={[
                      { value: StoreCardVersion.V1, children: 'V1' },
                      { value: StoreCardVersion.V2, children: 'V2' },
                      { value: StoreCardVersion.V3, children: 'V3' },
                    ]}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
          )}

          {canEdit && (
            <FieldGroup label={i18next.t('hub-settings.store-settings.image-size')}>
              <Controller
                control={controlWebsite}
                name="config.store_settings.image_size"
                render={({ field }) => (
                  <Select
                    options={[
                      { value: StoreImageSize.Cover, children: 'Cover' },
                      { value: StoreImageSize.Contain, children: 'Contain' },
                      { value: StoreImageSize.Fill, children: 'Fill' },
                      { value: StoreImageSize.None, children: 'None' },
                      { value: StoreImageSize.ScaleDown, children: 'Scale Down' },
                    ]}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
          )}

          {isSuperAdmin && (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-2">
                <FieldGroup caption={i18next.t('hub-settings.store-settings.show-description')}> </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.store_settings.show_item_description"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}

          {isSuperAdmin && (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-2">
                <FieldGroup caption={i18next.t('hub-settings.store-settings.show-reward-points')}> </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.store_settings.show_reward_points_label"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}

          {isSuperAdmin && (
            <>
              <FieldGroup label={i18next.t('hub-settings.store-settings.mobile-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.mobile_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={i18next.t('hub-settings.store-settings.tablet-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.tablet_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={i18next.t('hub-settings.store-settings.desktop-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.desktop_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={i18next.t('hub-settings.store-settings.large-desktop-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.large_desktop_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>
            </>
          )}
          {isSuperAdmin && (
            <FieldGroup label={i18next.t('hub-settings.store-settings.store-version')}>
              <Input placeholder="v1" {...registerWebSite('config.store_settings.store_version')} disabled={!canEdit} />
            </FieldGroup>
          )}

          {/*<FieldGroup label={i18next.t('hub-settings.store-settings.categories.item')}>*/}
          {/*  <Input {...registerWebSite('config.store_settings.custom_texts.item_text')} />*/}
          {/*  <ErrorMessage*/}
          {/*    name="config.store_settings.custom_texts.item_text"*/}
          {/*    errors={errorsWebSite}*/}
          {/*    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}*/}
          {/*  />*/}
          {/*</FieldGroup>*/}

          {/*<FieldGroup label={i18next.t('hub-settings.store-settings.categories.currency')}>*/}
          {/*  <Input {...registerWebSite('config.store_settings.custom_texts.currency_text')} />*/}
          {/*  <ErrorMessage*/}
          {/*    name="config.store_settings.custom_texts.currency_text"*/}
          {/*    errors={errorsWebSite}*/}
          {/*    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}*/}
          {/*  />*/}
          {/*</FieldGroup>*/}
        </Block>
      </DescBlock>
    )
  }

  const renderGlobalImageRatioSettings = () => {
    return (
      <DescBlock
        title={i18next.t('hub-settings.news-settings.image-ratio.title')}
        description={i18next.t('hub-settings.news-settings.image-ratio.desc')}
      >
        <Block>
          <FieldGroup label={i18next.t('hub-settings.news-settings.image-ratio')}>
            <Controller
              control={controlWebsite}
              name="config.news_settings.image_ratio"
              render={({ field }) => <Select options={imageRatioOptions} {...field} disabled={!canEdit} />}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderNewsSettings = () => {
    return (
      <DescBlock
        title={i18next.t('hub-settings.news-settings.title')}
        description={i18next.t('hub-settings.news-settings.desc')}
      >
        <Block>
          <FieldGroup label={i18next.t('hub-settings.news-settings.news-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.CompanyNews}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.CompanyNews}`}
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.news-settings.update-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.Update}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.Update}`}
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>

          <FieldGroup label={i18next.t('hub-settings.news-settings.event-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.Event}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.Event}`}
              errors={errorsWebSite}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderLeaderboardIcons = () => {
    return (
      <DescBlock
        title={i18next.t('hub-settings.leaderboard-icons')}
        description={i18next.t('hub-settings.leaderboard-icons.desc')}
      >
        <ImageList
          urls={leaderboard_icons}
          onDelete={
            canEdit
              ? delItem => {
                  setValueWebsite(
                    'config.leaderboard_icons',
                    leaderboard_icons.filter(it => it !== delItem),
                    { shouldDirty: true },
                  )
                }
              : undefined
          }
          onAdd={
            canEdit
              ? newItem => {
                  setValueWebsite('config.leaderboard_icons', [...leaderboard_icons, ...newItem], { shouldDirty: true })
                }
              : undefined
          }
        />
      </DescBlock>
    )
  }

  const renderAvatars = () => {
    return (
      <DescBlock
        title={i18next.t('hub-settings.player-avatars')}
        description={i18next.t('hub-settings.player-avatars.desc')}
      >
        <ImageList
          urls={avatars}
          onDelete={
            canEdit
              ? delItem => {
                  setValueWebsite(
                    'config.avatars',
                    avatars.filter(it => it !== delItem),
                    { shouldDirty: true },
                  )
                }
              : undefined
          }
          onAdd={
            canEdit
              ? newItem => {
                  setValueWebsite('config.avatars', [...avatars, ...newItem], { shouldDirty: true })
                }
              : undefined
          }
        />
      </DescBlock>
    )
  }

  return (
    <div>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          canEdit && (
            <>
              {isDirty() && (
                <Button variant="outline" color="secondary" size="sm" onClick={onDiscardClick}>
                  {i18next.t('Discard')}
                </Button>
              )}
              <Button size="sm" disabled={!isDirty()} onClick={handleSaveClick}>
                {i18next.t('Save')}
              </Button>
            </>
          )
        }
      >
        {i18next.t('hub-settings')}
      </PageHeader>

      <div className="flex w-full justify-center ">
        <div style={{ maxWidth: '1164px' }} className="mt-5 flex w-full flex-col gap-12">
          {renderGeneral()}
          {renderStyle()}
          {renderAgeSettings()}
          {renderTrademarks()}
          {renderGlobalImageRatioSettings()}
          {renderStoreSettings()}
          {renderNewsSettings()}
          {renderLeaderboardIcons()}
          {renderAvatars()}
          {renderMiscSettings()}
          {renderLinksSettings()}
          {renderDemoSettings()}
          <div className="w-[200px]" />
        </div>
      </div>
    </div>
  )
}

export const AcceptIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9437_4114)">
        <g filter="url(#filter0_i_9437_4114)">
          <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_9437_4114)" />
        </g>
        <g filter="url(#filter1_dd_9437_4114)">
          <path
            d="M38 24C38 31.732 31.732 38 24 38C16.268 38 10 31.732 10 24C10 16.268 16.268 10 24 10C31.732 10 38 16.268 38 24Z"
            fill="white"
          />
          <path
            d="M37.75 24C37.75 31.5939 31.5939 37.75 24 37.75C16.4061 37.75 10.25 31.5939 10.25 24C10.25 16.4061 16.4061 10.25 24 10.25C31.5939 10.25 37.75 16.4061 37.75 24Z"
            stroke="url(#paint1_linear_9437_4114)"
            stroke-width="0.5"
          />
        </g>
        <g filter="url(#filter2_i_9437_4114)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 16.5C19.8579 16.5 16.5 19.8579 16.5 24C16.5 28.1421 19.8579 31.5 24 31.5C28.1421 31.5 31.5 28.1421 31.5 24C31.5 19.8579 28.1421 16.5 24 16.5ZM27.5678 22.5053C27.8607 22.2124 27.8607 21.7376 27.5678 21.4447C27.2749 21.1518 26.8001 21.1518 26.5072 21.4447L22.9875 24.9643L21.4928 23.4697C21.1999 23.1768 20.7251 23.1768 20.4322 23.4697C20.1393 23.7626 20.1393 24.2374 20.4322 24.5303L22.4572 26.5553C22.7501 26.8482 23.2249 26.8482 23.5178 26.5553L27.5678 22.5053Z"
            fill="url(#paint2_linear_9437_4114)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_9437_4114"
          x="0"
          y="0"
          width="48"
          height="50"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.580392 0 0 0 0 0.639216 0 0 0 0 0.721569 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9437_4114" />
        </filter>
        <filter
          id="filter1_dd_9437_4114"
          x="4"
          y="8"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_9437_4114" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9437_4114" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_9437_4114" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_9437_4114" result="effect2_dropShadow_9437_4114" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_9437_4114" result="shape" />
        </filter>
        <filter
          id="filter2_i_9437_4114"
          x="16.5"
          y="16.5"
          width="15"
          height="15.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9437_4114" />
        </filter>
        <linearGradient id="paint0_linear_9437_4114" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
          <stop stop-color="#4ADE80" stop-opacity="0.2" />
          <stop offset="1" stop-color="#4ADE80" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint1_linear_9437_4114" x1="24" y1="10" x2="24" y2="38" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DCFCE7" />
          <stop offset="1" stop-color="#DCFCE7" stop-opacity="0" />
        </linearGradient>
        <linearGradient id="paint2_linear_9437_4114" x1="24" y1="16.5" x2="24" y2="31.5" gradientUnits="userSpaceOnUse">
          <stop stop-color="#86EFAC" />
          <stop offset="1" stop-color="#BBF7D0" />
        </linearGradient>
        <clipPath id="clip0_9437_4114">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { useParams } from 'react-router-dom'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { Select, SelectProps } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useSegmentsQuery } from '@/layouts/segment/api/useSegmentsQuery'

interface SelectSkuProps extends Omit<SelectProps, 'items'> {
  segmentGroupId?: string
  mode?: 'slug' | 'id'
}

export const SelectSegmentV2 = (props: SelectSkuProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
    segment_group_id: props.segmentGroupId,
  })

  return (
    <Select
      {...props}
      items={items.map(it => ({
        value: (props.mode == 'slug' ? it.slug : it.id) as string,
        children: <SegmentBadge segment={it} />,
      }))}
      placeholder={t('users.segment.select')}
    />
  )
}

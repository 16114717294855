import { WebsiteRead } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { FieldInfo, KeyValue } from '@/types'
import { FieldGroup } from '@/ui'
import { InputColor } from '@dashboard/ui'
import { CollapseView } from '@/hub-editor/widgets/CollapseView'

const colorFields = [
  {
    i18: 'website.accent_color',
    property: 'accent_color',
    type: 'text',
  },
  {
    i18: 'website.text_color',
    property: 'text_color',
    type: 'text',
  },
  {
    i18: 'website.base_bg_color',
    property: 'base_bg_color',
    type: 'text',
  },
] as FieldInfo[]

const advancedColorFields = [
  {
    i18: 'dark_bg_color',
    property: 'dark_bg_color',
    type: 'text',
  },
  {
    i18: 'featured_bg_color',
    property: 'featured_bg_color',
    type: 'text',
  },
  {
    i18: 'card_bg_color',
    property: 'card_bg_color',
    type: 'text',
  },
  {
    i18: 'card_image_bg_color',
    property: 'card_image_bg_color',
    type: 'text',
  },
  {
    i18: 'ui_elements_color',
    property: 'ui_elements_color',
    type: 'text',
  },
  {
    i18: 'text_muted',
    property: 'text_muted',
    type: 'text',
  },
  {
    i18: 'left_menu_item_color',
    property: 'left_menu_item_color',
    type: 'text',
  },
  {
    i18: 'left_menu_bg_color',
    property: 'left_menu_bg_color',
    type: 'text',
  },
  {
    i18: 'right_menu_bg_color',
    property: 'right_menu_bg_color',
    type: 'text',
  },
  {
    i18: 'login_button_bg',
    property: 'login_button_bg',
    type: 'text',
  },
  {
    i18: 'login_button_color',
    property: 'login_button_color',
    type: 'text',
  },
  {
    i18: 'social_link_bg',
    property: 'social_link_bg_color',
    type: 'text',
  },
  {
    i18: 'social_link_color',
    property: 'social_link_color',
    type: 'text',
  },
  {
    i18: 'offer_label_bg',
    property: 'offer_label_bg_color',
    type: 'text',
  },
  {
    i18: 'offer_label_color',
    property: 'offer_label_color',
    type: 'text',
  },
  {
    i18: 'offer_price_label_bg',
    property: 'offer_price_label_bg',
    type: 'text',
  },
  {
    i18: 'offer_price_label_color',
    property: 'offer_price_label_color',
    type: 'text',
  },
  {
    i18: 'discount_label_bg_color',
    property: 'discount_label_bg_color',
    type: 'text',
  },
  {
    i18: 'discount_label_text_color',
    property: 'discount_label_text_color',
    type: 'text',
  },
  {
    i18: 'bonus_label_bg_color',
    property: 'bonus_label_bg_color',
    type: 'text',
  },
  {
    i18: 'bonus_label_text_color',
    property: 'bonus_label_text_color',
    type: 'text',
  },
  {
    i18: 'left_label_bg_color',
    property: 'left_label_bg_color',
    type: 'text',
  },
  {
    i18: 'left_label_text_color',
    property: 'left_label_text_color',
    type: 'text',
  },
  {
    i18: 'buy_button_bg_color',
    property: 'buy_button_bg_color',
    type: 'text',
  },
  {
    i18: 'buy_button_color',
    property: 'buy_button_color',
    type: 'text',
  },
  {
    i18: 'reward_button_bg',
    property: 'reward_button_bg',
    type: 'text',
  },
  {
    i18: 'reward_button_color',
    property: 'reward_button_color',
    type: 'text',
  },
  {
    i18: 'daily_reward_button_bg',
    property: 'daily_reward_button_bg',
    type: 'text',
  },
  {
    i18: 'daily_reward_button_color',
    property: 'daily_reward_button_color',
    type: 'text',
  },
  {
    i18: 'daily_reward_inactive_button_bg',
    property: 'daily_reward_inactive_button_bg',
    type: 'text',
  },
  {
    i18: 'daily_reward_inactive_button_color',
    property: 'daily_reward_inactive_button_color',
    type: 'text',
  },
  {
    i18: 'daily_reward_card_bg_color',
    property: 'daily_reward_card_bg_color',
    type: 'text',
  },
  {
    i18: 'free_item_button_bg',
    property: 'free_item_button_bg',
    type: 'text',
  },
  {
    i18: 'free_item_button_color',
    property: 'free_item_button_color',
    type: 'text',
  },
  {
    i18: 'outline_button_color',
    property: 'outline_button_color',
    type: 'text',
  },
  {
    i18: 'custom_badge_bg_color',
    property: 'custom_badge_bg_color',
    type: 'text',
  },
  {
    i18: 'custom_badge_color',
    property: 'custom_badge_color',
    type: 'text',
  },
  {
    i18: 'free_item_custom_badge_bg_color',
    property: 'free_item_custom_badge_bg_color',
    type: 'text',
  },
  {
    i18: 'free_item_custom_badge_color',
    property: 'free_item_custom_badge_color',
    type: 'text',
  },
  {
    i18: 'item_timer_bg_color',
    property: 'item_timer_bg_color',
    type: 'text',
  },
  {
    i18: 'item_timer_color',
    property: 'item_timer_color',
    type: 'text',
  },
  {
    i18: 'item_name_color',
    property: 'item_name_color',
    type: 'text',
  },
  {
    i18: 'page_title_color',
    property: 'page_title_color',
    type: 'text',
  },
  {
    i18: 'maintenance_panel_bg_color',
    property: 'maintenance_panel_bg_color',
    type: 'text',
  },
  {
    i18: 'maintenance_panel_text_color',
    property: 'maintenance_panel_text_color',
    type: 'text',
  },
  {
    i18: 'bundle_list_bg_color',
    property: 'bundle_list_bg_color',
    type: 'text',
  },
  {
    i18: 'news_title_color',
    property: 'news_title_color',
    type: 'text',
  },
  {
    i18: 'news_date_color',
    property: 'news_date_color',
    type: 'text',
  },
  {
    i18: 'news_active_tab_button_bg_color',
    property: 'news_active_tab_button_bg_color',
    type: 'text',
  },
  {
    i18: 'tab_button_bg',
    property: 'tab_button_bg',
    type: 'text',
  },
  {
    i18: 'tab_button_color',
    property: 'tab_button_color',
    type: 'text',
  },
  {
    i18: 'login_deep_link_button_bg',
    property: 'login_deep_link_button_bg',
    type: 'text',
  },
  {
    i18: 'login_deep_link_button_color',
    property: 'login_deep_link_button_color',
    type: 'text',
  },
] as FieldInfo[]

export const SettingsEditor = ({
  webSite,
  setWebSite,
}: {
  webSite: WebsiteRead
  setWebSite: (webSite: WebsiteRead) => void
}) => {
  const { t } = useTranslation()

  const defaultColorValues = {
    accent_color: '#d1b46b',
    text_color: '#ffffff',
    base_bg_color: '#151514',
    free_item_button_bg: '#8fa249',
    reward_button_bg: '#9e68e3',
    maintenance_panel_bg_color: '#ff0000',
  } as KeyValue

  const getColorValue = (propName: string): string => {
    let v = (webSite.config as unknown as KeyValue)[propName] as string
    if (v) {
      return v
    }
    return (defaultColorValues[propName] as string) || ''
  }

  const renderColorFields = (fields: FieldInfo[]) => {
    return fields.map(it => (
      <FieldGroup label={t(it.i18)} key={it.property}>
        <InputColor
          key={it.property}
          name={it.property}
          value={getColorValue(it.property)}
          onChange={e =>
            setWebSite({
              ...webSite,
              config: {
                ...webSite.config,
                [it.property]: e,
              },
            })
          }
        />
      </FieldGroup>
    ))
  }

  return (
    <div className="grow p-2">
      {renderColorFields(colorFields)}
      <CollapseView label={t('website.advanced')}>{renderColorFields(advancedColorFields)}</CollapseView>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { dashboardClient } from '@/api'
import { useParams } from 'react-router-dom'
import { APIKeyRedacted, APIKeyRevealed, APIKeyType } from '@/api/dashboard'
import DescBlock from '../components/DescBlock'
import Block from '../components/Block'
import { FieldGroup } from '@/ui'
import { Trans, useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { Button, Eye, Input } from '@dashboard/ui'

export default function ApiKeySettings() {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [apiKeys, setApiKeys] = useState<APIKeyRedacted[]>([])
  const [apiKeysRevealed, setApiKeysRevealed] = useState<APIKeyRevealed[]>([])
  const { isDeveloper } = useCurrentUser()

  const loadKeys = async () => {
    let { data } = await dashboardClient.v1.getApiKeys(companyId, gameId)
    setApiKeys(data)
  }

  const reveal = async (apiKey: APIKeyRedacted) => {
    let { data } = await dashboardClient.v1.revealApiKey(companyId, gameId, apiKey.id)
    setApiKeysRevealed([...apiKeysRevealed, data])
  }

  useEffect(() => {
    loadKeys()
  }, [gameId])

  const createKey = async (type: APIKeyType) => {
    await dashboardClient.v1.createApiKey(companyId, gameId, {
      type: type,
    })
    loadKeys()
  }

  const hasPublicKey = apiKeys.find(it => it.type == APIKeyType.Publishable)
  const hasSecretKey = apiKeys.find(it => it.type == APIKeyType.Secret)

  return (
    <DescBlock
      title={t('game-settings.api-keys.title')}
      description={<Trans i18nKey="game-settings.api-keys.description" components={{ a: <a /> }} />}
    >
      <Block>
        {apiKeys.map(apikey => {
          let keyRevealed = apiKeysRevealed.find(it => it.id == apikey.id)
          let label = keyRevealed ? 'api_key.secret' : 'api_key.secret.redacted'
          if (apikey.type == APIKeyType.Publishable) {
            label = keyRevealed ? 'api_key.publishable' : 'api_key.publishable.redacted'
          }

          return (
            <FieldGroup label={t(label)} key={apikey.id}>
              <Input
                disabled={!isDeveloper}
                key={apikey.id}
                value={keyRevealed?.secret || apikey.secret_redacted}
                extraRight={
                  keyRevealed
                    ? undefined
                    : {
                        pointer: true,
                        icon: (
                          <div onClick={() => reveal(apikey)} className="z-[1] cursor-pointer">
                            <Eye />
                          </div>
                        ),
                      }
                }
              />
            </FieldGroup>
          )
        })}

        {(!hasSecretKey || !hasPublicKey) && isDeveloper && (
          <div className="flex flex-col gap-2">
            <div>
              {!hasPublicKey && (
                <Button onClick={() => createKey(APIKeyType.Publishable)} variant="outline">
                  {t('api_key.publishable.generate')}
                </Button>
              )}
            </div>
            <div>
              {!hasSecretKey && (
                <Button onClick={() => createKey(APIKeyType.Secret)} color="primary" variant="outline">
                  {t('api_key.secret.generate')}
                </Button>
              )}
            </div>
          </div>
        )}
      </Block>
    </DescBlock>
  )
}

import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const LighThing: AghanimIcon = forwardRef(function CloseOutline({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9739 0.849266C11.2689 1.01835 11.4133 1.36478 11.3256 1.69334L10.0166 6.59905C10.0029 6.6504 9.99608 6.67608 10.0021 6.69634C10.0074 6.7141 10.019 6.72928 10.0348 6.73895C10.0529 6.74999 10.0794 6.74999 10.1326 6.74999L14.8636 6.74999C15.0585 6.74996 15.2498 6.74993 15.4049 6.76309C15.5493 6.77534 15.8181 6.80675 16.0541 6.98537C16.3311 7.19515 16.5057 7.52701 16.4999 7.89271C16.4948 8.20953 16.3307 8.44602 16.2471 8.55939C16.1561 8.68277 16.0313 8.82308 15.9078 8.96192L15.8894 8.98254C15.8806 8.99245 15.8715 9.00213 15.8622 9.01156L7.93224 17.0275C7.69308 17.2692 7.3212 17.3198 7.02616 17.1507C6.73111 16.9816 6.58675 16.6352 6.67441 16.3066L7.98337 11.4009C7.99707 11.3496 8.00392 11.3239 7.99791 11.3036C7.99263 11.2859 7.98097 11.2707 7.96518 11.261C7.94715 11.25 7.92058 11.25 7.86743 11.25L3.13645 11.25C2.94151 11.25 2.75023 11.25 2.59514 11.2369C2.45074 11.2246 2.18187 11.1932 1.94595 11.0146C1.66888 10.8048 1.49426 10.473 1.50014 10.1073C1.50524 9.79045 1.66935 9.55396 1.75292 9.44059C1.84388 9.3172 1.96872 9.17689 2.09226 9.03804C2.09838 9.03117 2.10449 9.0243 2.11059 9.01744C2.11941 9.00753 2.12849 8.99785 2.13782 8.98841L10.0678 0.972523C10.3069 0.730773 10.6788 0.680185 10.9739 0.849266ZM3.49439 9.74999H9C9.23303 9.74999 9.45282 9.85831 9.59478 10.0431C9.73673 10.2279 9.78473 10.4682 9.72465 10.6933L8.85163 13.9653L14.5056 8.24999H9C8.76697 8.24999 8.54718 8.14167 8.40523 7.95687C8.26327 7.77207 8.21528 7.53178 8.27535 7.30663L9.14839 4.03473L3.49439 9.74999Z"
        fill="#64748B"
      />
    </IconBase>
  );
});

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { AccountDetails, CompanyLegalDetails } from '@/api/dashboard'
import { Alert, Button, DrawerContent, DrawerTopbar, FieldGroup, Input, SelectCountry } from '@/ui'
import { phoneRegexp } from '@/libs'
import { FormErrorMessage } from '@dashboard/ui'
import { ErrorMessage } from '@hookform/error-message'

interface VerifyCompanyLegalForm {
  data?: CompanyLegalDetails
  onSubmit: (data: AccountDetails) => Promise<void>
  onCancel: () => void
}

export const VerifyCompanyLegalForm = ({ data, onSubmit, onCancel }: VerifyCompanyLegalForm) => {
  const { t } = useTranslation()
  const {
    formState: { errors, isSubmitting },
    watch,
    register,
    control,
    handleSubmit,
  } = useForm<CompanyLegalDetails>({
    defaultValues: {
      business_name: '',
      tin: '',
      dba: '',
      country: undefined,
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal: '',
      phone: '',
      ...data,
    },
  })

  const country = watch('country')

  const onHandleSubmit = handleSubmit(data => {
    return onSubmit({ company_legal: data })
  })

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={onHandleSubmit}>
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{t('get-started.verify.steps.company-legal.form.title')}</h2>
        <div className="flex gap-3">
          <Button variant="outline" type="button" onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button variant="primary" type="submit" isLoading={isSubmitting}>
            {t('Save2')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <div className="mb-6">
          <p className="text-paragraph-md text-text-secondary">
            {t('get-started.verify.steps.company-legal.form.desc')}
          </p>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.company-legal.form.name.label')} size="sm" required={true}>
            <Input
              {...register('business_name', {
                required: t('validation.required'),
                minLength: {
                  message: t('validation.minLength', {
                    field: t('get-started.verify.steps.company-legal.form.name.label'),
                    value: 3,
                  }),
                  value: 3,
                },
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.company-legal.form.name.label'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              autoFocus={true}
              placeholder={t('get-started.verify.steps.company-legal.form.name.placeholder')}
              errors={errors}
            />
          </FieldGroup>
          <Alert>{t('get-started.verify.steps.company-legal.form.name.caption')}</Alert>
        </div>

        <div className="mb-4">
          <FieldGroup
            label={
              country === 'US'
                ? t('get-started.verify.steps.company-legal.form.tin.us')
                : t('get-started.verify.steps.company-legal.form.tin.other')
            }
            size="sm"
            required={true}
          >
            <Input
              {...register('tin', {
                required: t('validation.required'),
                minLength: {
                  message: t('validation.minLength', {
                    field:
                      country === 'US'
                        ? t('get-started.verify.steps.company-legal.form.tin.us')
                        : t('get-started.verify.steps.company-legal.form.tin.other'),
                    value: 3,
                  }),
                  value: 3,
                },
              })}
              errors={errors}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.company-legal.form.dba')} size="sm" required={true}>
            <Input
              {...register('dba', {
                required: t('validation.required'),
                minLength: {
                  message: t('validation.minLength', {
                    field: t('get-started.verify.steps.company-legal.form.dba'),
                    value: 3,
                  }),
                  value: 3,
                },
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.company-legal.form.dba'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup
            label={t('get-started.verify.steps.company-legal.form.business-address')}
            size="sm"
            required={true}
          >
            <Controller
              name="country"
              control={control}
              rules={{ required: t('validation.required') }}
              render={({ field }) => <SelectCountry {...field} />}
            />
            <ErrorMessage
              name="country"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.company-legal.form.address.label')} size="sm" required={true}>
            <div className="mb-3">
              <Input
                {...register('address1', {
                  required: t('validation.required'),
                  minLength: {
                    message: t('validation.minLength', {
                      field: t('get-started.verify.steps.company-legal.form.address.address-placeholder'),
                      value: 3,
                    }),
                    value: 3,
                  },
                  maxLength: {
                    message: t('validation.maxLength', {
                      field: t('get-started.verify.steps.company-legal.form.address.address-placeholder'),
                      value: 50,
                    }),
                    value: 50,
                  },
                })}
                errors={errors}
                placeholder={t('get-started.verify.steps.company-legal.form.address.address-placeholder')}
              />
            </div>
            <Input
              {...register('address2', {
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.company-legal.form.address.address-2-placeholder'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
              placeholder={t('get-started.verify.steps.company-legal.form.address.address-2-placeholder')}
            />
          </FieldGroup>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.company-legal.form.city.label')} size="sm" required={true}>
            <Input
              {...register('city', {
                required: t('validation.required'),
                minLength: {
                  message: t('validation.minLength', {
                    field: t('get-started.verify.steps.company-legal.form.city.label'),
                    value: 3,
                  }),
                  value: 3,
                },
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('get-started.verify.steps.company-legal.form.city.label'),
                    value: 50,
                  }),
                  value: 50,
                },
              })}
              errors={errors}
              placeholder={t('get-started.verify.steps.company-legal.form.city.placeholder')}
            />
          </FieldGroup>
        </div>

        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <FieldGroup
              label={
                country === 'US'
                  ? t('get-started.verify.steps.company-legal.form.state.label.us')
                  : t('get-started.verify.steps.company-legal.form.state.label.other')
              }
              size="sm"
              required={true}
            >
              <Input
                {...register('state', {
                  required: t('validation.required'),
                  minLength: {
                    message: t('validation.minLength', {
                      field:
                        country === 'US'
                          ? t('get-started.verify.steps.company-legal.form.state.placeholder.us')
                          : t('get-started.verify.steps.company-legal.form.state.placeholder.other'),
                      value: 2,
                    }),
                    value: 2,
                  },
                  maxLength: {
                    message: t('validation.maxLength', {
                      field:
                        country === 'US'
                          ? t('get-started.verify.steps.company-legal.form.state.placeholder.us')
                          : t('get-started.verify.steps.company-legal.form.state.placeholder.other'),
                      value: 50,
                    }),
                    value: 50,
                  },
                })}
                errors={errors}
                placeholder={
                  country === 'US'
                    ? t('get-started.verify.steps.company-legal.form.state.placeholder.us')
                    : t('get-started.verify.steps.company-legal.form.state.placeholder.other')
                }
              />
            </FieldGroup>
          </div>
          <div>
            <FieldGroup
              label={
                country === 'US'
                  ? t('get-started.verify.steps.company-legal.form.zip.label.us')
                  : t('get-started.verify.steps.company-legal.form.zip.label.other')
              }
              size="sm"
              required={true}
            >
              <Input
                {...register('postal', {
                  required: t('validation.required'),
                  minLength: {
                    message: t('validation.minLength', {
                      field:
                        country === 'US'
                          ? t('get-started.verify.steps.company-legal.form.zip.placeholder.us')
                          : t('get-started.verify.steps.company-legal.form.zip.placeholder.other'),
                      value: 3,
                    }),
                    value: 3,
                  },
                  maxLength: {
                    message: t('validation.maxLength', {
                      field:
                        country === 'US'
                          ? t('get-started.verify.steps.company-legal.form.zip.placeholder.us')
                          : t('get-started.verify.steps.company-legal.form.zip.placeholder.other'),
                      value: 50,
                    }),
                    value: 50,
                  },
                })}
                errors={errors}
                placeholder={
                  country === 'US'
                    ? t('get-started.verify.steps.company-legal.form.zip.placeholder.us')
                    : t('get-started.verify.steps.company-legal.form.zip.placeholder.other')
                }
              />
            </FieldGroup>
          </div>
        </div>

        <div className="mb-4">
          <FieldGroup label={t('get-started.verify.steps.company-legal.form.phone')} size="sm" required={true}>
            <Input
              {...register('phone', {
                required: t('validation.required'),
                pattern: {
                  message: t('validation.invalid_phone'),
                  value: phoneRegexp,
                },
                maxLength: {
                  message: t('validation.maxLength', {
                    field: t('validation.invalid_phone'),
                    value: 25,
                  }),
                  value: 25,
                },
              })}
              errors={errors}
            />
          </FieldGroup>
        </div>
      </DrawerContent>
    </form>
  )
}

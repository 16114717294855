import { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, cn, useModal, useSearchFilter } from '@dashboard/ui'
import { Query, useGameItemsQuery } from '@/api/useGameItems'
import { ItemOrderType, ItemRead, ItemType, ResourceState } from '@/api/dashboard'
import {
  Page,
  PageHeader,
  Pagination,
  Tab,
  Table,
  TableCell,
  TableCellBulkCheckbox,
  TableCellBulkToolbar,
  TableHeaderSkeleton,
  TableRow,
  TableZeroState,
  Tabs,
  usePagination,
} from '@/ui'
import {
  SKU_ITEMS_BUNDLES_NEW_PATH,
  SKU_ITEMS_NEW_PATH,
  SKU_ITEMS_SETTINGS_CATEGORIES_PATH,
  useBanner,
  useGenerateCompanyPath,
} from '@/libs'
import { Archive, CloseOutline, Plus, Unarchive } from '@/icons'
import {
  BulkArchiveModal,
  BulkEditCategoryModal,
  BulkUnarchiveModal,
  SkuItemRow,
  SkuItemRowSkeleton,
  SkuListToolbar,
} from '../components'
import { useSkuCounts } from '../hooks'
import { SkuListFilter } from '../types'
import { useBulkSelectGeneric } from '@/libs/hooks/useBulkSelectGeneric'
import { useCurrentUser } from '@/api/useCurrentUser'

interface SkuListPageProps {
  types: ItemType[]
}

export const SkuListPage = ({ types }: SkuListPageProps) => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()

  const { canEdit } = useCurrentUser()
  const { getStickyTop } = useBanner()
  const { page, query, onPageChange, needShowPagination } = usePagination(100)
  const filter = useSearchFilter<SkuListFilter>()

  const { counts } = useSkuCounts(types)
  const { data: items = [], isLoading } = useGameItemsQuery(companyId, gameId, {
    ...query,
    ...filter.values,
    state: filter.values.state == 'all' ? undefined : (filter.values.state ?? ResourceState.Active),
    types: types,
    order_by: ItemOrderType.CreatedAt,
  } as Query)

  const { selected, onSelect, onReset } = useBulkSelectGeneric(
    [],
    items.map(it => it.id),
  )

  useEffect(() => {
    onReset([])
  }, [filter.values])

  const isBundle = types.includes(ItemType.Bundle)

  const i18prefix = isBundle ? 'bundles' : 'items'
  const hasFilter = filter.values.category_id || filter.values.search_string

  const createItemPath = useMemo(
    () => generatePath(isBundle ? SKU_ITEMS_BUNDLES_NEW_PATH : SKU_ITEMS_NEW_PATH),
    [types],
  )

  const openBulkArchiveModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkArchiveModal {...props} onResetItems={onReset} />
  ))

  const openBulkUnarchiveModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkUnarchiveModal {...props} onResetItems={onReset} />
  ))

  const openBulkEditCategoryModal = useModal<{ items: ItemRead[] }>(props => (
    <BulkEditCategoryModal {...props} onResetItems={onReset} />
  ))

  const onClickBulkArchive = useCallback(() => {
    openBulkArchiveModal({ items: items.filter(it => selected.includes(it.id)) })
  }, [items, selected, openBulkArchiveModal])

  const onClickBulkUnarchive = useCallback(() => {
    openBulkUnarchiveModal({ items: items.filter(it => selected.includes(it.id)) })
  }, [items, selected, openBulkUnarchiveModal])

  const onClickBulkEditCategory = useCallback(() => {
    openBulkEditCategoryModal({ items: items.filter(it => selected.includes(it.id)) })
  }, [items, selected, openBulkEditCategoryModal])

  const renderDefaultHeader = () => (
    <>
      {canEdit && (
        <TableCellBulkCheckbox
          selected={selected.length > 0 && selected.length == items.length}
          disabled={items.length === 0}
          onChange={() => onReset(selected.length == items.length ? [] : items.map(i => i.id))}
        />
      )}
      <TableCell width="100%">{t('sku.item.product-name')}</TableCell>
      {isBundle ? (
        <TableCell width="40%">{t('sku.item.contain-items')}</TableCell>
      ) : (
        <TableCell width="25%">{t('sku.item.type')}</TableCell>
      )}
      <TableCell width="25%">{t('sku.item.category')}</TableCell>
      <TableCell width="25%">{t('sku.item.price')}</TableCell>
      <TableCell width={62} />
    </>
  )

  const renderBulkSelectionHeader = () => (
    <>
      <TableCellBulkCheckbox
        selected={selected.length > 0 && selected.length == items.length}
        disabled={items.length === 0}
        onChange={() => onReset(selected.length == items.length ? [] : items.map(i => i.id))}
      />

      <TableCellBulkToolbar selected={selected} onReset={() => onReset([])}>
        {filter.values.state !== ResourceState.Archived ? (
          <>
            <Button variant="outline" color="secondary" size="sm" onClick={onClickBulkEditCategory}>
              {t('item-category.edit')}
            </Button>

            {!!items.filter(it => selected.includes(it.id)).filter(item => !item.archived_at).length && (
              <Button variant="tertiary" color="secondary" size="sm" onClick={onClickBulkArchive}>
                <Archive size={16} />
                <span>{t('sku.bulk-archive.label')}</span>
              </Button>
            )}

            {!!items.filter(it => selected.includes(it.id)).filter(item => item.archived_at).length && (
              <Button variant="tertiary" color="secondary" size="sm" onClick={onClickBulkUnarchive}>
                <Unarchive size={16} />
                <span>{t('sku.bulk-unarchive.label')}</span>
              </Button>
            )}
          </>
        ) : (
          <>
            <Button variant="tertiary" color="secondary" size="sm" onClick={onClickBulkUnarchive}>
              <Unarchive size={16} />
              <span>{t('sku.bulk-unarchive.label')}</span>
            </Button>
          </>
        )}
      </TableCellBulkToolbar>
    </>
  )

  return (
    <Page>
      <PageHeader
        extra={
          canEdit && (
            <>
              <Link
                to={generatePath(SKU_ITEMS_SETTINGS_CATEGORIES_PATH)}
                state={{ isBundle: types.includes(ItemType.Bundle) }}
              >
                <Button variant="outline" color="secondary" size="sm">
                  {t('sku.settings')}
                </Button>
              </Link>

              <Link to={createItemPath}>
                <Button size="sm">{t(types.includes(ItemType.Bundle) ? 'sku.items.add-bundle' : 'AddItem')}</Button>
              </Link>
            </>
          )
        }
      >
        {t(isBundle ? 'sidebar.bundles' : 'sidebar.items')}
      </PageHeader>

      <Tabs className="mb-4">
        <Tab
          count={counts.all?.total_count}
          isActive={filter.values.state == 'all'}
          onClick={() => {
            filter.onChange({ ...filter.values, state: 'all' }, { force: true })
          }}
        >
          {t('sku.items.tabs.all')}
        </Tab>
        <Tab
          isActive={!filter.values.state}
          count={counts.active?.total_count}
          onClick={() => {
            filter.onChange({ ...filter.values, state: undefined }, { force: true })
          }}
        >
          {t('sku.items.tabs.active')}
        </Tab>
        <Tab
          isActive={filter.values.state == ResourceState.Archived}
          count={counts.archived?.total_count}
          onClick={() => {
            filter.onChange({ ...filter.values, state: ResourceState.Archived }, { force: true })
          }}
        >
          {t('sku.items.tabs.archived')}
        </Tab>
      </Tabs>

      <SkuListToolbar filter={filter} />

      {isLoading ? (
        <>
          <TableHeaderSkeleton />
          {new Array(10).fill('').map((_, index) => (
            <SkuItemRowSkeleton key={`skeleton-${index}`} canEdit={canEdit} />
          ))}
        </>
      ) : items.length === 0 ? (
        <TableZeroState
          title={
            hasFilter ? t('sku.search.zero.title') : t(`sku.${i18prefix}.zero.${filter.values.state || 'all'}.title`)
          }
          message={
            hasFilter ? t('sku.search.zero.text') : t(`sku.${i18prefix}.zero.${filter.values.state || 'all'}.message`)
          }
          buttons={
            canEdit &&
            (hasFilter ? (
              <Button
                variant="outline"
                color="secondary"
                size="sm"
                onClick={() => {
                  filter.onChange({ state: filter.values.state }, { force: true })
                }}
              >
                <CloseOutline size={16} />
                <span>{t('sku.search.clear-filters')}</span>
              </Button>
            ) : (
              t(`sku.items.zero.${filter.values.state || 'all'}.button`, { defaultValue: '' }) && (
                <Link to={createItemPath}>
                  <Button size="sm">
                    <Plus size={16} />
                    <span>{t(`sku.${i18prefix}.zero.${filter.values.state || 'all'}.button`)}</span>
                  </Button>
                </Link>
              )
            ))
          }
        />
      ) : (
        <>
          <Table>
            <TableRow className={cn('sticky h-12', getStickyTop())} variant="header">
              {selected.length ? renderBulkSelectionHeader() : renderDefaultHeader()}
            </TableRow>
            {items.map(item => (
              <SkuItemRow
                key={`sku-item-row-${item.id}`}
                item={item}
                canEdit={canEdit}
                bulk={{ isSelected: selected.includes(item.id), onSelect }}
              />
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </Page>
  )
}

import { useLocation } from 'react-router-dom'
import { ModalRushPage } from '@/ui'
import { SKU_ITEMS_BUNDLES_PATH, SKU_ITEMS_PATH, useNavigateBack } from '@/libs'
import { SkuSettingsModal } from '../widgets/SkuSettingsModal'

export const SkuSettingsPage = () => {
  const location = useLocation()
  const back = useNavigateBack({ fallback: location.state?.isBundle ? SKU_ITEMS_BUNDLES_PATH : SKU_ITEMS_PATH })

  return <ModalRushPage back={back}>{props => <SkuSettingsModal {...props} />}</ModalRushPage>
}

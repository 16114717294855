import { Edit03 } from '@/icons'
import { Input, useModal } from '@dashboard/ui'
import { EditListModal } from '@/layouts/components/EditListModal'

export interface InputListProps {
  value: string[]
  onChange: (value: string[]) => void
  modalTitle?: string
}

export const InputList = (props: InputListProps) => {
  const openEditModal = useModal<{ onChange: (value: string[]) => void; value: string[] | undefined | null }>(p => (
    <EditListModal {...p} modalTitle={props.modalTitle} />
  ))

  return (
    <Input
      value={props.value?.join(',')}
      onChange={() => {
        //do nothing
      }}
      extraRight={{
        pointer: true,
        icon: (
          <div
            className="flex h-full cursor-pointer items-center justify-center"
            style={{ zIndex: 1 }}
            onClick={() => openEditModal({ value: props.value, onChange: props.onChange })}
          >
            <Edit03 />
          </div>
        ),
      }}
    />
  )
}

import { createElement, useContext } from 'react'
import {
  AttrDataType,
  AttrGroup,
  CAMPAIGN_ATTRIBUTES,
  CalcPlayerAttributes,
  DATE_TIME_ATTR,
  DETECTED_COUNTRY_ATTR,
  PLAYER_ATTRIBUTES,
  TokenValue,
} from './types'
import { CampaignContext, EditorMode, ICampaignContext } from '../Context'
import { Campaign, Token, TokenType } from '@/api/dashboard'
import { isCustomEvent } from '../Settings'
import i18next from 'i18next'
import { ChildItem, ParentItem, SelectBaseValueProps, SelectTree, Tooltip } from '@/ui'
import { PlayerBase } from '@/layouts/campaigns/icons/PlayerBase'
import { PlayerPaymentInfo } from '@/layouts/campaigns/icons/PlayerPaymentInfo'
import { PlayerCommunicationInfo } from '@/layouts/campaigns/icons/PlayerCommunicationInfo'
import { Event } from '@/layouts/campaigns/icons/Event'
import { PlayerCustom } from '@/layouts/campaigns/icons/PlayerCustom'
import { ChevronRightOutline, WebhookIcon } from '@/icons'
import { useTranslation } from 'react-i18next'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'

const getCampaignAttributes = (mode?: EditorMode, campaign?: Campaign): ChildItem[] => {
  let campaignItems: ChildItem[] = []

  switch (mode) {
    case EditorMode.Campaign:
      if (isCustomEvent(campaign?.type)) {
        campaignItems = CAMPAIGN_ATTRIBUTES[''].map(it => ({
          children: it.name,
          caption: i18next.t(`campaign.attr-desc.${it.id}`),
          tree: { value: it.id! },
        }))

        campaign?.attributes?.custom_attributes?.forEach(it => {
          campaignItems.push({
            children: it.name,
            caption: it.description,
            tree: { value: it.name },
          })
        })
      } else {
        let campaign_attributes = [...CAMPAIGN_ATTRIBUTES['']]

        if (campaign && CAMPAIGN_ATTRIBUTES[campaign.type]) {
          campaign_attributes.push(...CAMPAIGN_ATTRIBUTES[campaign.type])
        }

        campaignItems = campaign_attributes.map(it => ({
          children: it.name,
          caption: i18next.t(`campaign.attr-desc.${it.id}`),
          tree: { value: it.id! },
        }))
      }
      break
    case EditorMode.Store:
      campaignItems = [DATE_TIME_ATTR, DETECTED_COUNTRY_ATTR].map(it => ({
        children: it.name,
        caption: i18next.t(`campaign.attr-desc.${it.id}`),
        tree: { value: it.id },
      }))
      break
    case EditorMode.StoreItem:
      break
  }

  return campaignItems
}

const WebhookComplement = () => (
  <Tooltip message={i18next.t('campaign.attr.webhook_complement')}>
    <div className="text-fg-brand-primary">
      <WebhookIcon />
    </div>
  </Tooltip>
)

export default function AttrSelector(props: {
  token: Token
  error?: boolean
  onChange: (v: TokenValue, attrType: TokenType) => void
}) {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const context = useContext(CampaignContext) as ICampaignContext
  const customAttributes = context?.playerCustomAttributes || []
  const { t } = useTranslation()

  const buildGroupItems = (group: AttrGroup) => {
    let items = PLAYER_ATTRIBUTES.filter(it => it.group == group)

    if (!gameSettings?.enable_reward_points) {
      items = items.filter(it => it.id != CalcPlayerAttributes.reward_points_balance)
    }
    return items
      .map(it => ({
        children: it.name,
        caption: i18next.t(`campaign.attr-desc.${it.id}`),
        tree: { value: it.id },
        extra: it.dataType == AttrDataType.webhook_complement ? WebhookComplement : null,
      }))
      .sort((a, b) => a.children.localeCompare(b.children)) as ChildItem[]
  }

  const campaignItems = getCampaignAttributes(context.mode, context.campaign)

  const items: ParentItem[] = [
    {
      children: t('campaign.attr-menu.player_base'),
      tree: {
        value: 'player',
        child: buildGroupItems(AttrGroup.base),
      },
      icon: PlayerBase,
      extra: ChevronRightOutline,
    },
    {
      children: t('campaign.attr-menu.player_payment'),
      tree: {
        value: 'player-payment',
        child: buildGroupItems(AttrGroup.payment_info),
      },
      icon: PlayerPaymentInfo,
      extra: ChevronRightOutline,
    },
    {
      children: t('campaign.attr-menu.player_communication'),
      tree: {
        value: 'communication-data',
        child: buildGroupItems(AttrGroup.communication),
      },
      icon: PlayerCommunicationInfo,
      extra: ChevronRightOutline,
    },
  ]

  if (customAttributes.length) {
    items.push({
      children: t('campaign.attr-menu.player_custom_attributes'),
      tree: {
        value: 'player-custom-attributes',
        child: customAttributes.map(it => ({
          children: it.name,
          caption: it.description,
          tree: { value: it.name },
        })),
      },
      icon: PlayerCustom,
      extra: ChevronRightOutline,
    })
  }

  if (campaignItems.length) {
    items.push({
      children: t('campaign.attr-menu.event_attributes'),
      tree: {
        value: 'event_attributes',
        child: campaignItems,
      },
      icon: Event,
      extra: ChevronRightOutline,
    })
  }

  const getAttrType = (v: TokenValue): TokenType => {
    let type = TokenType.Attribute
    if (campaignItems.find(it => it.tree.value == v)) {
      if (context.campaign.attributes?.custom_attributes?.find(it => it.name == v)) {
        type = TokenType.CustomEventAttribute
      } else {
        type = TokenType.EventAttribute
      }
    } else if (customAttributes.find(it => it.name == v)) {
      type = TokenType.CustomAttribute
    }
    return type
  }

  const SelectValue = ({ item }: SelectBaseValueProps) => {
    let type = getAttrType(props.token.value)
    let prefix = type == TokenType.EventAttribute || type == TokenType.CustomEventAttribute ? 'Event' : 'Player'

    return (
      <>
        {item.icon && <div className="shrink-0 text-[--icon-color]">{createElement(item.icon, { size: 20 })}</div>}
        <span className="truncate text-[--label-color]">
          {prefix}.{item.children}
        </span>
        {item.extra && (
          <div className="ml-auto shrink-0 text-[--extra-color] transition-colors">
            {createElement(item.extra, { size: 20 })}
          </div>
        )}
      </>
    )
  }

  return (
    <SelectTree
      renderValueFn={SelectValue}
      dropDownWidth={'280px'}
      value={props.token.value}
      items={items}
      placeholder={t('campaign.condition.select-attribute')}
      search={{ placeholder: t('search'), enabled: true }}
      onChange={v => {
        props.onChange(v as TokenValue, getAttrType(v as TokenValue))
      }}
    />
  )
}

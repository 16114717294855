import { useEffect, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'
import PageBlock from '../game-items/components/PageBlock'
import { uniqueList } from '../../util'
import PaymentStatusChip from './components/PaymentStatusChip'
import UserTransactionsTable from './UserTransactionsTable'
import { getMinPaymentByStatus, getTransactionTotal, isDemo } from './util'
import { dashboardClient, getErrorText } from '../../api'
import { Currency, CustomerCompanyRead, ItemRead, OrderRead, PaymentRead, PaymentStatus } from '@/api/dashboard'
import PlayerCard from '../player/PlayerCard'
import PayCardView from './PayCardView'
import { Textarea } from '../../components/ui/Textarea'
import { ConfirmDialog, MenuButton, ModalConfirm, useModal } from '@/ui'
import RefundingProgress from './components/RefundingProgress'
import { SKU_ITEMS_DETAILS_PATH, TRANSACTIONS_PATH } from '../../libs/routerPaths'
import { WebhookLogTimeline } from './components/WebhookLogTimeline'
import { ChevronLeft, Copy03 } from '@/icons'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { ReceiptEmailView } from './widgets/ReceiptEmailView'
import { GeneralTransactionDetails } from './widgets/GeneralTransactionDetails'
import { GeneralBlock } from './components/GeneralBlock'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '../../Settings'
import { getItemName } from '../../api/getItemName'
import { TransactionTimeline } from './widgets/TransactionTimeline'
import { useNavigateBack } from '../../libs/hooks'
import { useBanner } from '@/libs/hooks/useBanner'
import { usePlayerDetailQuery } from '@/layouts/player/api'
import { Button, DotsHorizontal, Skeleton, cn } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { RewardPointsPrice } from '@/layouts/game-items/components/RewardPointsPrice'

let refundTimeId: ReturnType<typeof setTimeout> | null = null

export default function TransactionDetails() {
  const { companyId, gameId, paymentNumber } = useParams() as {
    companyId: string
    gameId: string
    paymentNumber: string
  }
  const { t } = useTranslation()
  const back = useNavigateBack({ fallback: TRANSACTIONS_PATH })
  const { canEdit, isSuperAdmin } = useCurrentUser()
  const isReadOnly = !canEdit
  const { getStickyTop } = useBanner()
  const [payment, setPayment] = useState<PaymentRead[]>([])
  const [sourcePayments, setSourcePayments] = useState<PaymentRead[]>([])

  const [currentPaymentRefund, setCurrentPaymentRefund] = useState<PaymentRead | null>(null)
  const [item, setItem] = useState<ItemRead | null>(null)
  const [order, setOrder] = useState<OrderRead | null>(null)
  const { data: user } = usePlayerDetailQuery(companyId, gameId, payment[0]?.user_id)

  const [isRefunding, setIsRefunding] = useState(false)
  const [refundReason, setRefundReason] = useState('')
  const [refundCustomer, setRefundCustomer] = useState<CustomerCompanyRead | null>(null)
  const showToast = useToast()

  const loadRefundCustomer = async (p: PaymentRead) => {
    if (p && p.status == PaymentStatus.Refunded && p.refund_customer_id) {
      let { data: customer } = await dashboardClient.v1.getCompanyMember(p.refund_customer_id, companyId)
      setRefundCustomer(customer)
    }
  }

  const loadData = async () => {
    setSourcePayments([])
    setPayment([])
    let { data } = await dashboardClient.v1.getPayment(paymentNumber, companyId, gameId)
    loadRefundCustomer(data[0])
    setPayment(uniqueList(data, 'status'))
    setSourcePayments(data)

    dashboardClient.v1
      .getItems(companyId, gameId, {
        ids: data[0].item_id!,
      })
      .then(r => {
        if (r.data.length && r.data[0].id == data[0].item_id) {
          setItem(r.data[0])
        }
      })

    if (!isDemo(data[0].order_id)) {
      dashboardClient.v1.getOrder(data[0].order_id!, companyId, gameId).then(res => setOrder(res.data as OrderRead))
    }
  }

  useEffect(() => {
    loadData()
    return () => {
      if (refundTimeId) {
        clearTimeout(refundTimeId)
      }
    }
  }, [paymentNumber])

  const updateRefundStatus = async () => {
    try {
      let { data } = await dashboardClient.v1.getPayment(paymentNumber, companyId, gameId)
      if (data.find((it: PaymentRead) => it.status === PaymentStatus.Refunded)) {
        setSourcePayments(data)
        setPayment(uniqueList(data, 'status'))
        loadRefundCustomer(data[0])
        setIsRefunding(false)
      } else {
        refundTimeId = setTimeout(updateRefundStatus, 3000)
      }
    } catch (e) {
      if (refundTimeId) {
        clearInterval(refundTimeId)
      }
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
      console.log(e)
    }
  }

  const onRefundConfirm = async () => {
    if (currentPaymentRefund) {
      if (isRefunding) {
        return
      }
      setCurrentPaymentRefund(null)
      try {
        setIsRefunding(true)
        await dashboardClient.v1.refundOrder(currentPaymentRefund.payment_id!, companyId!, gameId!, {
          reason: refundReason,
          order_id: currentPaymentRefund.order_id!,
        })
        refundTimeId = setTimeout(updateRefundStatus, 3000)
      } catch (e) {
        console.log(e)
        showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        setIsRefunding(false)
      }
      setRefundReason('')
    }
  }

  const openResendEmailModal = useModal<{ payment: PaymentRead }>(props => (
    <ModalConfirm
      color={'primary'}
      confirmButtonText={t('yes')}
      subMessage={t('transactions-table.resend-email.confirm')}
      onConfirm={async () => {
        try {
          await dashboardClient.v1.resendReceiptEmail(companyId, gameId, props.payment.order_id!)
          showToast({ severity: ToastSeverity.success, message: t('transactions-table.resend-email.sent') })
        } catch (e) {
          showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        }
      }}
      {...props}
    />
  ))

  const openRenotifyModal = useModal<{ payment: PaymentRead }>(props => (
    <ModalConfirm
      color={'primary'}
      confirmButtonText={t('yes')}
      subMessage={t('transactions-table.renotify.confirm')}
      onConfirm={async () => {
        try {
          await dashboardClient.v1.renotifyPayment(props.payment.payment_id!, companyId, gameId, {
            reason: refundReason,
            order_id: props.payment.order_id!,
          })

          loadData()
        } catch (e) {
          showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        }
      }}
      {...props}
    />
  ))

  const openIssueModal = useModal(props => (
    <ModalConfirm
      color={'primary'}
      confirmButtonText={t('yes')}
      subMessage={t('transactions-table.issue_reward_points.confirm')}
      onConfirm={async () => {
        try {
          const response = await dashboardClient.v1.issueRewardPoints(order!.id, companyId, gameId)
          if (response.data?.issued_reward_points) {
            showToast({ severity: ToastSeverity.success, message: t('Success') })
          } else {
            showToast({
              severity: ToastSeverity.warning,
              message: t('transactions-table.issue_reward_points.warning'),
            })
          }
        } catch (e) {
          showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
        }
      }}
      {...props}
    />
  ))

  const renderGeneral = () => {
    const p = payment[0] ? getMinPaymentByStatus(sourcePayments, payment[0].status as PaymentStatus) : null

    return (
      <div>
        <div className="flex justify-between gap-5">
          <GeneralBlock title={t('transaction-details.item')}>
            {item ? (
              <Link
                className="flex gap-3"
                to={
                  isReadOnly || item?.is_custom
                    ? ''
                    : generatePath(SKU_ITEMS_DETAILS_PATH, { companyId, gameId, itemId: item?.id || '' })
                }
              >
                <div
                  className="h-[38px] w-[50px] shrink-0 rounded-md bg-fg-primary-alt bg-cover bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url(${item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
                  }}
                />
                <div className="flex flex-col truncate">
                  <div className="mb-0.5 flex	items-center gap-1.5 overflow-hidden whitespace-nowrap font-semibold leading-5 text-text-secondary">
                    {getItemName(item)}
                  </div>
                  <div className="text-xs font-medium	 leading-none text-text-quarterary-hover">{item.sku}</div>
                </div>
              </Link>
            ) : (
              <Skeleton className="h-[38px] " />
            )}
          </GeneralBlock>

          <GeneralBlock title={t('transaction-details.last-update')}>
            {p ? (
              <div className="flex h-[38px] items-center gap-1 font-semibold text-text-secondary">
                <span>
                  {p.modified_at &&
                    t('intl.DateTime', {
                      val: new Date(p.modified_at * 1000),
                      formatParams: { val: { dateStyle: 'medium' } },
                    })}
                </span>
                <span>
                  {p.modified_at &&
                    t('intl.DateTime', {
                      val: new Date(p.modified_at * 1000),
                      formatParams: { val: { timeStyle: 'medium' } },
                    })}
                </span>
              </div>
            ) : (
              <Skeleton className="h-[38px]" />
            )}
          </GeneralBlock>

          <GeneralBlock title={t('transaction-details.payment-method')}>
            {p ? (
              <div className="flex h-[38px] items-center font-semibold">{<PayCardView payment={p} />}</div>
            ) : (
              <Skeleton className="h-[38px] " />
            )}
          </GeneralBlock>
        </div>
      </div>
    )
  }

  const renderDetails = () => {
    let p = payment[0]

    return (
      <PageBlock title={t('transaction-details.details')}>
        <div
          className="gap-5"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <GeneralTransactionDetails
            item={item}
            payments={payment}
            refundCostumer={refundCustomer}
            order={order}
            sourcePayments={sourcePayments}
          />
          <ReceiptEmailView payment={p} />
        </div>
      </PageBlock>
    )
  }

  const renderPrevPurchases = () => {
    if (!payment[0]?.user_id) {
      return
    }
    return (
      <PageBlock style={{ overflowX: 'auto', width: '100%' }} title={t('players.other-transactions')}>
        <UserTransactionsTable userId={payment[0].user_id} />
      </PageBlock>
    )
  }

  const renderRightAction = () => {
    let p = payment[0]

    if (isReadOnly || isRefunding) {
      return
    }

    return (
      <div className="ml-auto flex gap-1">
        <Button
          size="sm"
          onClick={() => navigator.clipboard.writeText(paymentNumber)}
          color="secondary"
          variant="outline"
        >
          {paymentNumber}
          <Copy03 className="ml-1" />
        </Button>

        {p?.status == PaymentStatus.Done && p.currency != Currency.RP && canEdit && (
          <Button size="sm" onClick={() => setCurrentPaymentRefund(p)} variant="outline">
            {t('transactions-table.refund')}
          </Button>
        )}
        {p?.payment_id && isSuperAdmin && (
          <MenuButton
            size="sm"
            variant="outline"
            color="secondary"
            placement={'bottom-end'}
            dropDownWidth="200px"
            onChange={v => {
              switch (v) {
                case 'renotify':
                  openRenotifyModal({ payment: p })
                  break
                case 'resend-email':
                  openResendEmailModal({ payment: p })
                  break
                case 'issue_reward_points':
                  openIssueModal()
                  break
              }
            }}
            options={[
              { value: 'renotify', children: t('transactions-table.renotify') },
              (p.status == PaymentStatus.Done || p.status == PaymentStatus.Refunded) && {
                value: 'resend-email',
                children: t('transactions-table.resend-receipt-email'),
              },
              order?.eligible_for_reward_points &&
                p.status != PaymentStatus.Refunded &&
                p.checkout_profile_id && {
                  value: 'issue_reward_points',
                  children: t('transactions-table.issue_reward_points'),
                },
            ].filter(it => !!it)}
          >
            <DotsHorizontal />
          </MenuButton>
        )}
      </div>
    )
  }

  const renderStatusChip = () => {
    if (!payment.length) {
      return <Skeleton />
    }

    if (isRefunding) {
      return <RefundingProgress />
    }

    return <PaymentStatusChip payment={payment} />
  }

  const renderTransactionTotal = () => {
    if (!payment[0]) {
      return <Skeleton className="mt-1 h-[40px] w-[128px]" />
    }

    if (payment[0].currency == Currency.RP) {
      return <RewardPointsPrice price={payment[0].total || 0} showText={false} iconSize={24} />
    }
    return (
      <>
        {getTransactionTotal(payment[0])} {payment[0].currency}
      </>
    )
  }

  return (
    <div>
      <div className={cn('sticky z-10 flex items-center bg-fg-primary pb-[20px]', getStickyTop())}>
        <Button size="sm" onClick={back.onClick} variant="outline" color="secondary">
          <ChevronLeft />
        </Button>

        <div className="ml-3 flex items-center gap-3">
          <div className="ph-no-capture relative flex items-center text-title-t1 text-text-primary">
            <div className="absolute top-[-12px] text-caption-sm uppercase text-text-tertiary">
              {t('transaction-details.total')}:
            </div>
            {renderTransactionTotal()}
          </div>

          {renderStatusChip()}
        </div>

        {renderRightAction()}
      </div>

      {currentPaymentRefund && (
        <ConfirmDialog
          color={'primary'}
          confirmButtonText={t('transactions-table.refund')}
          confirmEnabled={!!refundReason.trim()}
          message={t('transactions-table.refund-title', { value: getTransactionTotal(currentPaymentRefund) })}
          subMessage={t('transactions-table.refund.confirm')}
          onCancel={() => setCurrentPaymentRefund(null)}
          onConfirm={onRefundConfirm}
        >
          <Textarea
            className="mt-6"
            placeholder={t('transaction-details.write-refund-reason')}
            value={refundReason}
            onChange={e => setRefundReason(e.target.value)}
          />
        </ConfirmDialog>
      )}

      <div className="flex flex-col gap-5">
        {renderGeneral()}
        {renderDetails()}
        <TransactionTimeline payments={payment} sourcePayments={sourcePayments} />
        {payment.length > 0 && !isDemo(payment[0].order_id) && (
          <WebhookLogTimeline orderId={payment[0].order_id!} paymentStatus={payment[0].status as PaymentStatus} />
        )}
        {user && !user.id.startsWith('demo_') && (
          <PlayerCard payLinkUserId={payment[0]?.checkout_profile_id} user={user} />
        )}
        {renderPrevPurchases()}

        <div className="h-[100px]" />
      </div>
    </div>
  )
}

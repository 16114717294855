import { ReactNode } from 'react'

interface SidebarBadgeProps {
  children: ReactNode
}

export const SidebarBadge = ({ children }: SidebarBadgeProps) => {
  return (
    <div className="flex h-5 items-center rounded-md bg-fg-brand-tertiary px-1 text-caption-sm font-medium text-text-brand-solid">
      {children}
    </div>
  )
}

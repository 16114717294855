import { ReactNode } from 'react';

interface ModalFooterProps {
  children: ReactNode;
  extra?: ReactNode;
}

/**
 * Modal footer component.
 */
export const ModalFooter = ({ children, extra }: ModalFooterProps) => (
  <div
    className="flex flex-col items-stretch gap-3 border-t border-border-secondary px-6 pb-6 pt-[18px] md:flex-row md:items-center md:justify-end"
    data-testid="modal-footer"
  >
    {extra && <div className="mr-auto">{extra}</div>}
    {children}
  </div>
);

import { InputHTMLAttributes, MouseEvent, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Search, XCircle } from '../../icons';
import { cn } from '../../libs';

export interface InputSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Placeholder of the input.
   */
  placeholder?: string;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(({ autoFocus, ...rest }, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => innerRef.current, []);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        innerRef.current?.focus();
      }, 10);
    }
  }, [autoFocus]);

  const onMouseDownPrevent = (e: MouseEvent<HTMLButtonElement | HTMLInputElement>) => {
    e.preventDefault();
  };

  const onClickClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!innerRef?.current) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
    nativeInputValueSetter?.call(innerRef.current, '');
    innerRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  };

  return (
    <div className={cn('relative w-full', rest.className)}>
      <input
        {...rest}
        ref={innerRef}
        type="text"
        className={cn(
          'peer/input-search',
          'h-8 w-full text-ellipsis rounded-md border border-transparent px-3 pl-[30px] pr-9 text-text-tertiary outline-none transition-colors',
          'placeholder:text-text-tertiary',
          'hover:bg-fg-primary-hover',
          'hover:placeholder:text-text-tertiary-hover',
          'focus:bg-fg-primary-alt',
          'focus:text-text-secondary',
          'focus:border-border-primary',
          'disabled:bg-fg-disabled',
          'disabled:bg-fg-disabled',
          'disabled:text-text-disabled',
          rest.className,
        )}
      />
      <Search
        className={cn(
          'pointer-events-none absolute left-2 top-1/2 -translate-y-1/2 transition-colors',
          'text-text-tertiary',
          'peer-[:hover]/input-search:text-text-tertiary-hover',
          'peer-[:focus]/input-search:text-text-secondary',
        )}
      />

      <button
        className={cn(
          'absolute right-2 top-1/2 flex size-6 -translate-y-1/2 items-center justify-center rounded bg-transparent transition-all',
          'text-text-quarterary-hover',
          'hover:bg-[rgba(148,163,184,0.10)]',
          'hover:text-text-tertiary',
          'active:bg-[rgba(148,163,184,0.20)]',
          'active:text-text-tertiary-hover',

          'invisible',
          'opacity-0',
          'pointer-events-none',
          'peer-[:not(:placeholder-shown)]/input-search:visible',
          'peer-[:not(:placeholder-shown)]/input-search:opacity-100',
          'peer-[:not(:placeholder-shown)]/input-search:pointer-events-auto',
        )}
        tabIndex={-1}
        onMouseDown={onMouseDownPrevent}
        onClick={onClickClear}
      >
        <XCircle />
      </button>
    </div>
  );
});

export enum PayoutStatus {
  pending = 'pending',
  draft = 'draft',
  approved = 'approved',
  paid = 'paid',
}

export enum PayoutType {
  instant = 'instant',
  regular = 'regular',
}

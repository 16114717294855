import { Link, generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCompaniesQuery, useCurrentGame } from '@/api/useCompaniesQuery'
import { setCompanyGameId, setLastGameId } from '@/Settings'
import { GET_STARTED_VERIFY_ACCOUNT_PATH, HOME_PATH, cn } from '@/libs'
import { Button, ButtonIcon, Floating, TableZeroState } from '@/ui'
import { SidebarGameButton } from './SidebarGameButton'
import { useMemo, useState } from 'react'
import { AghanimGameSchemasGame, CompanyGame } from '@/api/dashboard'
import { Pin } from '@/components/icons/Pin'
import { UnPin } from '@/components/icons/UnPin'
import { getPinnedGamesGlobal, setPinnedGamesGlobal } from '@/api/pinGame'
import { uniqueList } from '@/util'
import { InputSearch, Modal, ModalContent, ModalFooter, ModalTitle, useModal } from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { CloseOutline } from '@/icons'
import { useCurrentUser } from '@/api/useCurrentUser'
import { DashboardAccessLevel } from '@/api/DashboardAccessLevel'

interface GameItemProps {
  game: AghanimGameSchemasGame
  selected?: boolean
  onSelect: (game: AghanimGameSchemasGame) => boolean | undefined
  onPin?: () => void
  isPinned: boolean
}

const GameItem = ({ game, onPin, isPinned, selected = false, onSelect }: GameItemProps) => {
  return (
    <Link
      className="group flex cursor-pointer items-center gap-3 rounded-md py-1 pl-3  pr-1 transition-colors hover:bg-fg-primary-alt"
      to={generatePath(HOME_PATH, { companyId: game.company_id, gameId: game.id })}
      onClick={e => {
        const result = onSelect(game)
        if (result === false) {
          e.stopPropagation()
          e.preventDefault()
        }
      }}
    >
      <div className="shrink-0">
        <div
          className={cn(
            'size-4 rounded-full border border-border-primary bg-fg-primary',
            selected && 'border-4 border-fg-brand-primary shadow-radio',
          )}
        />
      </div>
      <div className="shrink-0">
        <img className="flex size-8 rounded-md object-cover" src={game.logo_url} alt="" />
      </div>
      <div className="font-medium leading-none text-text-secondary">{game.name}</div>
      {onPin && (
        <ButtonIcon
          size="sm"
          variant="tertiary-gray"
          className="ml-auto opacity-0 group-hover:opacity-100"
          onClick={e => {
            onPin()
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          {isPinned ? <UnPin /> : <Pin />}
        </ButtonIcon>
      )}
    </Link>
  )
}

const CompanyName = ({ name, className }: { name: string; className?: string }) => {
  return <div className={cn('mb-2 text-[10px] font-semibold leading-none text-text-tertiary', className)}>{name}</div>
}

interface GameSwitchMenuProps {
  selectedGameId: string | undefined
  onClose?: () => void
  companiesWithGames: CompanyGame[]
  onAddGameClick: (currentCompanyGame: CompanyGame) => void
}

const GameSwitchMenu = ({
  selectedGameId,
  onClose = () => void 0,
  companiesWithGames,
  onAddGameClick,
}: GameSwitchMenuProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const navigate = useNavigate()
  const { getSandboxGameState, game } = useCurrentGame()
  const { customer } = useCurrentUser()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search.trim(), 200)
  const [pinnedGames, setPinnedGames] = useState(getPinnedGamesGlobal())

  const onPin = (game: AghanimGameSchemasGame, value: boolean) => {
    let arr: string[] = []
    const gameId = game.sandbox && game.live_game_id ? game.live_game_id : game.id
    if (value) {
      arr = [...pinnedGames, gameId]
    } else {
      arr = pinnedGames.filter(it => it != gameId)
    }

    setPinnedGames(arr)
    setPinnedGamesGlobal(arr)
  }

  const showAccessLevelModal = useModal(rest => (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle>{t('feature.check')}</ModalTitle>
        {t('feature.check.desc')}
      </ModalContent>
      <ModalFooter>
        <Button variant="outline" color={'secondary'} size="sm" onClick={rest.onClose}>
          {t(`Cancel`)}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            navigate(generatePath(GET_STARTED_VERIFY_ACCOUNT_PATH, { companyId: companyId, gameId: gameId }))
            rest.onClose()
          }}
          className="min-w-40"
        >
          {t(`get-started.steps.verifyAccount.title`)}
        </Button>
      </ModalFooter>
    </Modal>
  ))

  const onSelectGame = (game: AghanimGameSchemasGame) => {
    onClose()
    setLastGameId(game.id)
    setCompanyGameId(game.company_id)
    return true
  }

  const getSandBoxGame = (id: string, company: CompanyGame) => {
    if (game?.id == id) {
      return undefined
    }

    if (getSandboxGameState(id)) {
      return company.games?.find(g => g.live_game_id == id && g.sandbox)
    }
    return undefined
  }

  const renderPinnedGames = () => {
    if (!pinnedGames.length) {
      return null
    }

    const games: AghanimGameSchemasGame[] = companiesWithGames
      .map(it => it.games)
      .flat()
      .filter(it => it && !it.sandbox && pinnedGames.includes(it.id))
      .sort((a, b) => a!.name.toLowerCase().localeCompare(b!.name.toLowerCase())) as AghanimGameSchemasGame[]

    if (!games.length) {
      return null
    }

    return (
      <div className="mb-3 border-b border-border-secondary pb-2 last:border-none last:pb-0">
        <CompanyName className="uppercase" name={t('pinned')} />
        {uniqueList(games, 'id').map(game => {
          return (
            <GameItem
              onPin={() => onPin(game, !pinnedGames.includes(game.id))}
              isPinned={pinnedGames.includes(game.id)}
              key={`game-item-${game.id}`}
              game={
                getSandBoxGame(game.id, companiesWithGames.find(c => c.id == game.company_id)!) ||
                (game as AghanimGameSchemasGame)
              }
              selected={game.sandbox_game_id == selectedGameId || game.id === selectedGameId}
              onSelect={onSelectGame}
            />
          )
        })}
      </div>
    )
  }

  const renderCompany = (company: CompanyGame) => {
    let games = company.games
      ?.filter(g => !!g)
      .filter(g => !g.sandbox) // list only by live games
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    if (!company.name.toLowerCase().includes(debouncedSearch.toLowerCase())) {
      games = games?.filter(g => g.name.toLowerCase().includes(debouncedSearch.toLowerCase()))
    }

    if (!games?.length) {
      return null
    }

    return (
      <div className="mb-3 border-b border-border-secondary pb-2 last:border-none last:pb-0" key={company.id}>
        <CompanyName name={company.name} />
        {games.map(game => (
          <GameItem
            onPin={company.is_demo ? undefined : () => onPin(game, !pinnedGames.includes(game.id))}
            isPinned={pinnedGames.includes(game.id)}
            key={`game-item-${game.id}`}
            game={getSandBoxGame(game.id, company) || (game as AghanimGameSchemasGame)}
            selected={game.sandbox_game_id == selectedGameId || game.id === selectedGameId}
            onSelect={() => {
              if (company.is_demo && customer.mode == DashboardAccessLevel.LIMITED) {
                showAccessLevelModal()
                onClose()
                return false
              }

              onSelectGame(game)
            }}
          />
        ))}
      </div>
    )
  }

  const selectedCompany = companiesWithGames.find(c => c.games?.find(g => g.id == selectedGameId))
  const demoCompany = companiesWithGames.find(c => c.is_demo)

  const filteredCompanies = debouncedSearch
    ? companiesWithGames.filter(c => {
        return (
          c.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
          c.games?.some(g => g.name.toLowerCase().includes(debouncedSearch.toLowerCase()))
        )
      })
    : companiesWithGames

  return (
    <div
      className="max-w-[380px] overflow-auto rounded-xl border border-border-primary bg-fg-primary shadow-lg"
      style={{ maxHeight: '500px' }}
    >
      <div style={{ height: '12px' }} />
      <div className="px-3">
        <Button
          className="w-full"
          variant="outline"
          onClick={() => {
            onClose()

            let currentCompany = companiesWithGames.find(c => c.games?.find(g => g.id == selectedGameId))

            if (currentCompany) {
              onAddGameClick(currentCompany)
            }
          }}
        >
          {t('AddGame')}
        </Button>
      </div>

      <div className="my-3 border-y border-border-secondary p-3">
        <InputSearch
          value={search}
          autoFocus={true}
          onFocus={e => {
            e.stopPropagation()
          }}
          onMouseDown={e => {
            e.stopPropagation()
          }}
          onChange={e => {
            setSearch(e.target.value)
          }}
          placeholder={t('SearchInputPlaceHolder')}
        />
      </div>

      <div className="px-3">
        {debouncedSearch ? (
          filteredCompanies.length ? (
            filteredCompanies.map(company => renderCompany(company))
          ) : (
            <div className="mb-3 h-[200px] w-full">
              <TableZeroState
                contentClassName="px-4"
                title={t('nothing-found')}
                message={t('game-menu.no-items')}
                buttons={
                  <Button
                    variant="outline"
                    onClick={e => {
                      e.stopPropagation()
                      setSearch('')
                    }}
                  >
                    <CloseOutline size={14} />
                    <span>{t('clear-filters')}</span>
                  </Button>
                }
              />
            </div>
          )
        ) : (
          <>
            {demoCompany && renderCompany(demoCompany)}
            {renderPinnedGames()}
            {selectedCompany && selectedCompany.id != demoCompany?.id && renderCompany(selectedCompany)}
            {companiesWithGames
              .filter(c => c != selectedCompany && c != demoCompany)
              .map(company => renderCompany(company))}
          </>
        )}
      </div>
    </div>
  )
}

export const SidebarGameSwitch = (props: { onAddGameClick: (currentCompanyGame: CompanyGame) => void }) => {
  const { gameId } = useParams()
  const { data: dataCompanies } = useCompaniesQuery()
  const { game } = useCurrentGame()
  const companiesWithGames = useMemo(() => {
    let res = dataCompanies?.data?.filter(company => company.games?.length) || []

    let demoCompany = res.find(it => it.is_demo)
    if (demoCompany) {
      res = res.filter(it => it.id != demoCompany.id || it.is_demo)
    }

    res = res.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

    return uniqueList(res, 'id')
  }, [dataCompanies])

  return (
    <Floating
      menu={
        <GameSwitchMenu
          selectedGameId={gameId}
          companiesWithGames={companiesWithGames}
          onAddGameClick={props.onAddGameClick}
        />
      }
      placement="bottom-start"
      referenceWidth={true}
    >
      <SidebarGameButton game={game} />
    </Floating>
  )
}

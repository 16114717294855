import { AttrType, Marketplace } from '@/api/dashboard'
import { InputExtraSection, SelectCountry } from '@/ui'
import { Attribute, CalcPlayerAttributes, LastDateFilter, OperatorType, SelectorValueType } from './types'
import { InputList } from '../../components/InputList'
import { Input, Select } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { SelectSegmentV2 } from '@/layouts/components/SelectSegmentV2'

type VType = string | number | null | undefined | string[] | boolean | number[]

export const AttrValueEditor = ({
  attr,
  onChange,
  value,
  isMoney,
  operator,
}: {
  isMoney: boolean
  attr: Attribute
  value: VType
  onChange: (v: VType) => void
  operator?: OperatorType
}) => {
  const { t } = useTranslation()

  if (!attr) {
    return (
      <Input value={value as string} style={{ width: '100%' }} onChange={e => onChange(e.target.value as string)} />
    )
  }

  const attrType = attr.type

  if (!attrType) {
    return <Input value={''} disabled style={{ width: '100%' }} />
  }

  if (operator == OperatorType.in_list || operator == OperatorType.not_in_list) {
    return <InputList value={value as string[]} onChange={v => onChange(v)} />
  }

  if (attr?.selectorValueType == SelectorValueType.segment) {
    return <SelectSegmentV2 mode="slug" value={value as string} onChange={v => onChange(v as string)} />
  }

  if (attr?.selectorValueType == SelectorValueType.sku || attr?.selectorValueType == SelectorValueType.sku_no_price) {
    return (
      <SelectSkuItemV2
        hidePrice={attr?.selectorValueType != SelectorValueType.sku}
        onChange={v => {
          onChange(v as string)
        }}
        value={value as string}
      />
    )
  }

  // if (attr?.selectorValueType == SelectorValueType.skus) {
  //   return (
  //     <SelectSkuItemV2
  //       multiselect={true}
  //       mode="single"
  //       dropDownWidth="300px"
  //       onChange={v => {
  //         onChange(v as string[])
  //       }}
  //       value={(value || []) as string[]}
  //     />
  //   )
  // }

  if (attr?.id == CalcPlayerAttributes.marketplace) {
    return (
      <Select
        value={value as string}
        items={Object.values(Marketplace).map(it => ({ value: it, children: t(`marketplace.${it}`) }))}
        onChange={v => onChange(v)}
      />
    )
  }

  if (attr?.selectorValueType == SelectorValueType.country) {
    return <SelectCountry onChange={v => onChange(v)} value={value as string} />
  }

  const getMoneyValue = (v: VType) => {
    return v || v == 0 ? (v as number) / 100 : ''
  }

  const renderNumber = () => {
    if (operator == OperatorType.not_in_range || operator == OperatorType.in_range) {
      let fromValue = (value as number[])[0]
      let toValue = (value as number[])[1]
      return (
        <div className="flex items-center gap-1">
          <Input
            type="number"
            value={isMoney ? getMoneyValue(fromValue) : fromValue}
            extraRight={isMoney ? { icon: <InputExtraSection side="right">USD</InputExtraSection> } : undefined}
            onChange={e => {
              if (!e.target.value) {
                return onChange([null as unknown as number, toValue])
              }
              let v = parseFloat(e.target.value)
              if (e.target.value && isNaN(v)) {
                return false
              }
              onChange([isMoney ? v * 100 : v, toValue])
            }}
          />
          -
          <Input
            type="number"
            value={isMoney ? getMoneyValue(toValue) : toValue}
            extraRight={isMoney ? { icon: <InputExtraSection side="right">USD</InputExtraSection> } : undefined}
            onChange={e => {
              if (!e.target.value) {
                return onChange([fromValue, null as unknown as number])
              }
              let v = parseFloat(e.target.value)
              if (e.target.value && isNaN(v)) {
                return false
              }
              onChange([fromValue, isMoney ? v * 100 : v])
            }}
          />
        </div>
      )
    }

    if (isMoney) {
      return (
        <Input
          type="number"
          value={getMoneyValue(value)}
          extraRight={{ icon: <InputExtraSection side="right">USD</InputExtraSection> }}
          onChange={e => {
            if (!e.target.value) {
              return onChange(null)
            }

            let v = parseFloat(e.target.value)
            if (e.target.value && isNaN(v)) {
              return false
            }
            onChange(v * 100)
          }}
        />
      )
    }

    return (
      <Input
        type="number"
        value={value as number}
        onChange={e => {
          let v = parseFloat(e.target.value)
          if (e.target.value && isNaN(v)) {
            return false
          }
          onChange(v)
        }}
      />
    )
  }

  switch (attrType) {
    case AttrType.String:
      return <Input value={value as string} onChange={e => onChange(e.target.value)} />
    case AttrType.Number:
      return renderNumber()
    case AttrType.Boolean:
      return (
        <Select
          placeholder={t('campaign.condition.select')}
          value={value == true ? 'True' : 'False'}
          items={[
            { value: 'True', children: 'True' },
            { value: 'False', children: 'False' },
          ]}
          onChange={v => onChange(v == 'True')}
        />
      )
    case AttrType.Date:
      return (
        <Select
          value={value as string}
          placeholder={t('campaign.condition.select')}
          onChange={v => onChange(v as string)}
          items={Object.values(LastDateFilter).map(it => ({
            value: it,
            children: t(`campaign.condition.date-filter.${it}`),
          }))}
        />
      )
    case AttrType.List:
      return <InputList value={value as string[]} onChange={v => onChange(v)} />
    default:
      return <Input value={''} style={{ width: '100px' }} disabled />
  }
}

import { QueryClient, queryOptions, useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export type Query = {
  /**
   * Ids
   * Comma separated list of ids
   */
  ids: string
}

export const pageQueryOptions = (companyId: string, gameId: string, query: Query) =>
  queryOptions({
    queryKey: ['pages', gameId, query],
    queryFn: () => dashboardClient.v1.getPagesByIds(companyId, gameId, query),
    select: response => response.data,
    enabled: !!query.ids,
  })

/**
 *
 * @param companyId
 * @param gameId
 * @param query
 */
export const usePagesByIdsQuery = (companyId: string, gameId: string, query: Query) => {
  return useQuery(pageQueryOptions(companyId, gameId, query))
}

export const invalidatePages = (queryClient: QueryClient, gameId: string) => {
  queryClient.invalidateQueries({ queryKey: ['pages', gameId] })
}

import { Button, Modal, ModalBaseProps, ModalContent, ModalFooter, ModalTitle } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { dashboardClient, getErrorText } from '@/api'
import { useParams } from 'react-router-dom'
import { ToastSeverity, useToast } from '@/ui'
import { addCompanyToCache, useCurrentGame } from '@/api/useCompaniesQuery'
import { PayoutRequestStatus } from '@/api/dashboard'
import { useQueryClient } from '@tanstack/react-query'

interface RequestInstantPayoutModalProps extends ModalBaseProps {}

export const RequestInstantPayoutModal = ({ ...rest }: RequestInstantPayoutModalProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { company } = useCurrentGame()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const showToast = useToast()

  const onRequestClick = async () => {
    try {
      await dashboardClient.v1.applyInstantPayouts(companyId, gameId)
      showToast({ message: t('payouts.instant.apply.disabled-tooltip'), severity: ToastSeverity.success })
      if (company) {
        company.instant_payout_status = PayoutRequestStatus.Review
        addCompanyToCache(queryClient, company)
      }
      rest.onClose?.()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  return (
    <Modal {...rest} size="sm">
      <ModalContent>
        <ModalTitle subtitle={t('payouts.instant.modal.subtitle')}>{t('payouts.instant.modal.title')}</ModalTitle>
      </ModalContent>
      <ModalFooter>
        <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
          {t('close')}
        </Button>
        <Button size="sm" onClick={onRequestClick}>
          {t('payout.request')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

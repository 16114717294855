import { useContext, useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ReactFlowProvider, Viewport } from 'reactflow'
import 'reactflow/dist/style.css'
import { Campaign, ConditionNode, GraphRoot, Node, StoreGraph } from '../../api/dashboard'
import { AnyNode, BlockError, EventActionNodeType } from '../campaigns/types'
import { ResponseError, dashboardClient, getErrorText } from '../../api'
import { START_BLOCK_WIDTH } from '../campaigns/blocks/StartBlock'
import { Flow, FlowApi, NODE_TYPES } from '../campaigns/Flow'
import { uuid4 } from '../../util'
import { ROOT_NODE_ID, hasMoreOneChildren } from '../campaigns/Settings'

import { calculateWarning } from '../campaigns/util'
import { NODE_VALIDATORS } from '../campaigns/validation'
import { STORE_PATH } from '../../libs/routerPaths'
import { DrawerSidebar } from '../../components/ui/DrawerSidebar'
import { Badge, ButtonIcon, ConfirmDialog, Tooltip } from '@/ui'
import CenteredProgress from '../../components/CenteredProgress'
import { Alert, Snackbar } from '@mui/material'
import { CampaignContext, EditorMode, ICampaignContext } from '../campaigns/Context'
import { Header } from '../../components/ui/Header'
import { AlertTriangleIcon, BackIcon, TrashIcon } from '../../icons/Icons'
import { useGameSettingsQuery } from '../../api/useGameSettingsQuery'
import { canEdit } from '../../security'
import { AuthContext, IAuthContext } from '../../Context'
import { KeyValue } from '../../types'
import AddStoreNode from './nodes/AddStoreNode'
import { StoreNodeEditor } from './editors/StoreNodeEditor'
import AddStoreItemsNode from './nodes/AddStoreItemsNode'
import { DescContext } from '../campaigns/blocks/descriptions/types'
import { useStoresQuery } from './api'
import { useSegmentsQuery } from '../segment/api/useSegmentsQuery'
import RemoveStoreItemsNode from './nodes/RemoveStoreItemsNode'
import { useToast } from '../../components/ui/Toast/useToast'
import { ToastSeverity } from '../../components/ui/Toast/types'
import { Copy03, Trash } from '@/icons'
import SortStoreItemsNode from './nodes/SortStoreItemsNode'
import { useBanner } from '@/libs/hooks/useBanner'
import { ExpressionForm } from '@/layouts/campaigns/expr/ExpressionForm'
import { useTranslation } from 'react-i18next'
import { Button, cn, useModal } from '@dashboard/ui'
import { ConfirmPublishModal } from '@/layouts/store/widgets/ConfirmPublishModal'

const moreType = NODE_TYPES as KeyValue

moreType[EventActionNodeType.AddStoreNode] = AddStoreNode
moreType[EventActionNodeType.AddStoreItemsNode] = AddStoreItemsNode
moreType[EventActionNodeType.RemoveItemsStoreNode] = RemoveStoreItemsNode
moreType[EventActionNodeType.SortStoreItemsNode] = SortStoreItemsNode

export default function StoreGraphPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getStickyTop, getStickyHeight } = useBanner()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const [loading, setLoading] = useState(true)
  const [graph, setGraph] = useState<GraphRoot>({} as GraphRoot)

  const [error, setError] = useState<BlockError | null>(null)
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined)
  const [selectedBlockId, setSelectedBlockId] = useState('')
  const [selectedEdgeId, setSelectedEdgeId] = useState('')
  const [nodeSettingsVisible, setNodeSettingsVisible] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [showConfirmBack, setShowConfirmBack] = useState(false)
  const [confirmSaveWithWarning, setConfirmSaveWithWarning] = useState('')
  const [storeGraph, setStoreGraph] = useState<StoreGraph>({
    enabled: false,
    group_by_categories: true,
  } as StoreGraph)
  const context = useContext(AuthContext) as IAuthContext
  const isReadOnly = !canEdit(context.customer)
  const showToast = useToast()

  const { data: settings } = useGameSettingsQuery(companyId, gameId)

  const flowRef = useRef<FlowApi | null>(null)
  const { data: stores = [] } = useStoresQuery(companyId, gameId)
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })

  useEffect(() => {
    loadGraph()
  }, [gameId])

  useEffect(() => {
    if (!selectedBlockId) {
      setNodeSettingsVisible(false)
      return
    }

    if (!error) {
      setNodeSettingsVisible(true)
      return
    }

    setNodeSettingsVisible(!error.useToast)
  }, [selectedBlockId])

  const setGraphAndDirty = (newGraph: GraphRoot) => {
    setIsDirty(true)
    setGraph({
      ...newGraph,
    })
  }

  const setNode = (newNode: Node) => {
    setGraph({
      ...graph,
      nodes: {
        ...graph.nodes,
        [newNode.id as string]: {
          ...newNode,
        },
      },
    })
    setIsDirty(true)
  }

  const loadGraph = async () => {
    setLoading(true)
    try {
      let { data: base } = await dashboardClient.v1.getBaseStoreGraph(companyId, gameId)
      if (base.graph) {
        setStoreGraph(base.graph)
      }
      let { data: graph } = await dashboardClient.v1.getStoreGraph(companyId, gameId)

      if (!graph) {
        let x = document.body.offsetWidth / 2 - START_BLOCK_WIDTH / 2
        graph = {
          id: ROOT_NODE_ID,
          nodes: {},
          styles: {},
          title: '',
          x: x - (x % 20),
          y: 20,
          model_type: EventActionNodeType.GraphRoot,
        }
      } else if (graph.view_port) {
        setViewPort(graph.view_port)
      }

      setGraph(graph)
    } finally {
      setLoading(false)
    }
  }

  const getContextValue = () => {
    return {
      isReadOnly: isReadOnly,
      playerCustomAttributes: settings?.player_attributes,
      campaign: {
        type: t('store.store-rules'),
        name: '',
      } as Campaign,
      graph: graph,
      mode: EditorMode.Store,
      historyMode: false,
    } as ICampaignContext
  }

  const validate = (): BlockError | null => {
    for (let id in graph.nodes) {
      let node = graph.nodes[id]

      let [warning, , itemName] = calculateWarning(node, graph)
      if (warning) {
        return {
          useToast: true,
          blockId: id,
          errorText: t('campaign.block.exit.warning.toast', { name: itemName }),
          errors: [],
        }
      }

      let validator = NODE_VALIDATORS[node.model_type]
      if (!validator) {
        continue
      }

      let errors = validator(
        node as AnyNode,
        {
          items: [],
          segments: segments,
          stores: stores,
          graph: graph,
        } as DescContext,
      )
      if (errors.length) {
        return {
          blockId: id,
          errorText: errors[0].message,
          errors: errors,
        }
      }
    }
    return null
  }

  const openConfirmPublish = useModal(p => (
    <ConfirmPublishModal
      {...p}
      storeGraph={storeGraph}
      onChange={() => {
        setStoreGraph({
          ...storeGraph,
          enabled: !storeGraph.enabled,
        })
      }}
    />
  ))

  const onConfirmSaveWithWarning = () => {
    setConfirmSaveWithWarning('')
    saveClick(true)
  }

  const saveClick = async (skipWarnings: boolean = false) => {
    let error = validate()
    if (error) {
      if (error.useToast) {
        if (!skipWarnings) {
          setConfirmSaveWithWarning(error.errorText)
          // setError(error)
          // setSelectedBlockId(error.blockId as string)
          return
        }
      } else {
        showError(error)
        return
      }
    }

    graph.view_port = viewPort

    setError(null)

    try {
      await dashboardClient.v1.createOrUpdateStoreGraph(companyId, gameId, {
        graph: graph,
        enabled: storeGraph.enabled,
        group_by_categories: storeGraph.group_by_categories,
      })

      setIsDirty(false)

      showToast({ message: t('saved'), severity: ToastSeverity.success })
    } catch (e) {
      let srvError = e as ResponseError
      if (srvError.error?.detail) {
        let path = srvError.error.detail[0].loc
        let errorBlockId = path.find(it => graph.nodes && graph.nodes[it])
        if (errorBlockId) {
          showError({ blockId: errorBlockId, errorText: srvError.error.detail[0].msg, errors: [] })
        }
      } else {
        showError({ errorText: getErrorText(e), errors: [], blockId: '' })
      }
    }
  }

  const showError = (error: BlockError) => {
    setSelectedBlockId(error.blockId as string)
    setNodeSettingsVisible(true)
    setError(error)
  }

  const duplicateNode = (id: string) => {
    if (!graph.nodes || isReadOnly) {
      return
    }

    let node = JSON.parse(JSON.stringify(graph.nodes[id]))
    node.next_node_id = null
    node.true_node_id = null
    node.false_node_id = null
    node.x += hasMoreOneChildren(node.model_type) ? 300 : 100
    node.y += hasMoreOneChildren(node.model_type) ? 300 : 100

    node.id = `node_${uuid4()}`

    graph.nodes[node.id] = node

    setGraphAndDirty(graph)

    setSelectedBlockId(node.id)
  }

  const onBackClick = () => {
    if (isDirty && !isReadOnly) {
      setShowConfirmBack(true)
    } else {
      onBackClickConfirm()
    }
  }

  const onBackClickConfirm = () => {
    navigate(generatePath(STORE_PATH, { companyId, gameId }))
  }

  const closePanels = () => {
    setNodeSettingsVisible(false)
  }

  let isCondEdit = graph.nodes && graph.nodes[selectedBlockId]?.model_type == EventActionNodeType.ConditionNode

  return (
    <div className={cn('absolute left-0 z-50 w-full', getStickyTop(), getStickyHeight())}>
      <CampaignContext.Provider value={getContextValue()}>
        <div className="flex size-full flex-col justify-start">
          <Header
            className="h-[62px]"
            title={
              <>
                {t('store.store-rules')}
                <Badge variant="orange-primary" className="ml-3">
                  <AlertTriangleIcon />
                  {t('store.store-rules.warning')}
                </Badge>
              </>
            }
            titleAction={
              <Button onClick={onBackClick} color="secondary" variant="outline" className="mr-2">
                <BackIcon />
                {t('Back')}
              </Button>
            }
          >
            <div className="flex items-center gap-2">
              {!isReadOnly && selectedEdgeId && (
                <Tooltip message={t('campaign.header.delete-edge')}>
                  <ButtonIcon
                    variant="secondary-gray"
                    onClick={() => {
                      flowRef.current?.removeEdge(selectedEdgeId)
                    }}
                  >
                    <TrashIcon />
                  </ButtonIcon>
                </Tooltip>
              )}

              <Button variant={'outline'} color="primary" onClick={() => openConfirmPublish()}>
                {t(storeGraph.enabled ? 'unpublish' : 'publish')}
              </Button>
              <Button onClick={() => saveClick(false)} disabled={!isDirty}>
                {t('Save2')}
              </Button>
            </div>
          </Header>

          {showConfirmBack && (
            <ConfirmDialog
              color="error"
              cancelButtonText={t('campaign.confirm.no-stay')}
              confirmButtonText={t('campaign.confirm.yes')}
              subMessage={t('campaign.confirm.lost-changes')}
              onCancel={() => setShowConfirmBack(false)}
              onConfirm={onBackClickConfirm}
            />
          )}

          {confirmSaveWithWarning && (
            <ConfirmDialog
              cancelButtonText={t('campaign.confirm.no-stay')}
              confirmButtonText={t('Save')}
              subMessage={t(confirmSaveWithWarning) + '\n' + t('campaign.confirm.save-with-warnings')}
              onCancel={() => setConfirmSaveWithWarning('')}
              onConfirm={onConfirmSaveWithWarning}
            />
          )}

          <div className="relative flex grow justify-start">
            {graph?.nodes && (
              <DrawerSidebar
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                open={nodeSettingsVisible && !!selectedBlockId && selectedBlockId != ROOT_NODE_ID}
                width="840px"
              >
                <div style={{ pointerEvents: isReadOnly ? 'none' : 'all', opacity: isReadOnly ? 0.6 : 1 }}>
                  {isCondEdit ? (
                    <ExpressionForm
                      error={error}
                      onClose={() => {
                        setSelectedBlockId('')
                        setNodeSettingsVisible(false)
                        closePanels()
                        if (error?.blockId == selectedBlockId) {
                          setError(null)
                        }
                      }}
                      node={graph.nodes[selectedBlockId] as ConditionNode}
                      setNode={n => setNode(n as Node)}
                    />
                  ) : (
                    <StoreNodeEditor
                      error={error}
                      onClose={() => {
                        setSelectedBlockId('')
                        setNodeSettingsVisible(false)
                        closePanels()
                      }}
                      node={graph.nodes[selectedBlockId]}
                      setNode={setNode}
                    />
                  )}
                </div>

                {!isReadOnly && (
                  <div className="mt-auto flex gap-2 p-6">
                    <Button onClick={() => duplicateNode(selectedBlockId)} color="secondary">
                      <Copy03 size={16} />
                      {t('campaign.editor.duplicate-node')}
                    </Button>
                    <Button
                      onClick={() => {
                        flowRef.current?.removeBlock(selectedBlockId)
                        setNodeSettingsVisible(false)
                      }}
                      color="danger"
                      variant="outline"
                    >
                      <Trash />
                      {t('campaign.editor.delete-node')}
                    </Button>
                  </div>
                )}
              </DrawerSidebar>
            )}

            <div className="relative h-full grow bg-bg-primary">
              {loading ? (
                <CenteredProgress />
              ) : (
                graph && (
                  <ReactFlowProvider>
                    <Flow
                      actions={[
                        EventActionNodeType.ConditionNode,
                        EventActionNodeType.AddStoreNode,
                        EventActionNodeType.SortStoreItemsNode,
                      ]}
                      ref={flowRef}
                      setGraphAndDirty={setGraphAndDirty}
                      isReadOnly={isReadOnly}
                      graph={graph}
                      selectedBlockId={selectedBlockId}
                      selectedEdgeId={selectedEdgeId}
                      onViewPortChanged={vp => setViewPort(vp)}
                      viewPort={viewPort}
                      onNodeMoved={() => setIsDirty(true)}
                      onBlockSelected={id => {
                        setSelectedEdgeId('')
                        setSelectedBlockId(id)
                      }}
                      onEdgeClick={id => {
                        setSelectedEdgeId(id)
                      }}
                      setNode={setNode}
                      setGraph={setGraph}
                      setSelectedBlockId={setSelectedBlockId}
                      setSelectedEdgeId={setSelectedEdgeId}
                    />
                  </ReactFlowProvider>
                )
              )}
            </div>
          </div>
          {error?.useToast && (
            <Snackbar open={true} autoHideDuration={60000} onClose={() => setError(null)}>
              <Alert onClose={() => setError(null)} severity="error" variant="filled">
                {error.errorText}
              </Alert>
            </Snackbar>
          )}
        </div>
      </CampaignContext.Provider>
    </div>
  )
}

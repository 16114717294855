import { Block } from '@/api/dashboard'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FieldGroup } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormErrorMessage } from '@dashboard/ui'
import { SelectNews } from '@/layouts/components/SelectNews'

const SelectNewsPanel = () => {
  const { t } = useTranslation()

  const {
    setValue,
    register,
    formState: { errors },
    control,
  } = useFormContext<Block>()

  return (
    <FieldGroup label={t('hub-editor.block-news')}>
      <Controller
        name="news_id"
        control={control}
        rules={{ required: t('validation.required') }}
        render={({ field }) => (
          <>
            <SelectNews value={field.value} onChange={v => setValue('news_id', v as string)} />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </>
        )}
      />
      <Checkbox className="mt-2" {...register('hide_date')}>
        {t('hub-editor.block-news.hide_date')}
      </Checkbox>
    </FieldGroup>
  )
}

export default SelectNewsPanel
